import React from 'react';
import { Table } from 'antd';

import type { ColumnsType } from 'antd/es/table';

import { ISessionListElementData } from '../containers/SessionList';

import './SessionList.scss';


interface ISessionListProps extends ICommonProps {
  tableData: ISessionListElementData[]
  tableColumns: ColumnsType<ISessionListElementData>
  loading: boolean
}

const SessionList = (props: ISessionListProps) => {
  const {
    tableData,
    tableColumns,
    loading,
  } = props;

  return (
    <Table
      columns={tableColumns}
      dataSource={tableData}
      loading={loading}
    />
  );
};

export default React.memo(SessionList);
