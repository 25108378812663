import { TherapistAvailabilityDto, TherapistSchoolDto } from 'src/services/api/therapist/therapist.dto';
import { Availability } from 'src/models/Availability';
import { parseTherapistAvailability } from 'src/features/Therapist/Details/utils';


interface TherapistDetailsDto {
  id: string;
  firstName: string;
  lastName: string;
  pauseBetweenSessions: number;
  skills: number[];
  schools: TherapistSchoolDto[];
  availability: TherapistAvailabilityDto[];
}

export class TherapistDetails {
  private constructor(
    readonly id: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly schools: TherapistSchoolDto[],
    readonly skills: number[],
    readonly pauseBetweenSessions: number,
    readonly availability: Availability[],
  ) {}

  static fromJS(data: TherapistDetailsDto) {

    return new TherapistDetails(
      data.id,
      data.firstName,
      data.lastName,
      data.schools,
      data.skills,
      data.pauseBetweenSessions,
      parseTherapistAvailability(data.availability),
    );
  }
}



