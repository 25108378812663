import { useMemo, useState, useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import {
  Menu,
  MenuProps,
  Space,
  Modal,
  Skeleton } from 'antd';

import { menuItems } from './constants';


interface IApplicationMenuProps extends ICommonProps {
  collapsed?: boolean;
}


const ApplicationMenu = (props: IApplicationMenuProps) => {
  const { collapsed } = props;

  const location = useLocation();

  const items = useMemo(() => menuItems.map(item => {
      const { key, url, disabled, icon, label, title } = item;

      return {
        key,
        url,
        disabled,
        icon,
        label: collapsed ? null : label,
        title,
      };
    }),
    [
      collapsed,
    ]);


  const activeItemKeys = useMemo(() => menuItems
      .filter( ({ url }) => !!matchPath(url, location.pathname) )
      .map( ({ key }) => key ),
    [
      location.pathname,
    ]);


  return (
    <>
      <Menu
        theme="light"
        selectedKeys={activeItemKeys}
        items={items}
      />
    </>
  );
};

export default ApplicationMenu;
