import { format } from 'date-fns';


export function toUtcString(date: Date) {
  return `${format(date, 'yyyy-MM-dd')}T${format(date, 'HH:mm:ss')}Z`;
}



export function toUtcTimeString(date: Date) {
  return `${format(date, 'HH:mm:ss')}`;
}
