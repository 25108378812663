import { addHours, format, hoursToMinutes, startOfDay } from 'date-fns';
import { FormFields } from './enums';
import { TherapistFormValues } from './types';
import { TherapistAvailabilityDto, TherapistSchoolDto } from 'src/services/api/therapist/therapist.dto';
import { AvailabilityWeekDay, OrganizationType } from 'src/enums';
import { Availability } from 'src/models/Availability';
import { initialAvailability } from 'src/features/Student/Forms/constants';


function prepareAvailability(values: TherapistFormValues) {
  return values[FormFields.Availability].reduce((acc, item) => {
    if (item.checked && item.intervals.start && item.intervals.end) {
      const [startHours, startMinutes] = format(item.intervals.start, 'HH:mm').split(':');
      const startInMinutes = hoursToMinutes(addHours(startOfDay(new Date()), Number(startHours)).getHours()) + Number(startMinutes);

      const [endHours, endMinutes] = format(item.intervals.end, 'HH:mm').split(':');
      const endInMinutes = hoursToMinutes(addHours(startOfDay(new Date()), Number(endHours)).getHours()) + Number(endMinutes);

      acc.push({ day: item.id, intervals: [{ start: startInMinutes, end: endInMinutes } ] });
    }

    return acc;
  }, [] as TherapistAvailabilityDto[]);
}

export function prepareCreateFormData(values: TherapistFormValues) {
  return {
    firstName: values[FormFields.FirstName],
    lastName: values[FormFields.LastName],
    schools: values[FormFields.Organization].map((item) => item?.id),
    skills: values[FormFields.Skills].map(id => Number(id)),
    pauseBetweenSessions: Number(values[FormFields.PauseBetweenSessions]),
    availability: prepareAvailability(values),
  };
}

export function prepareUpdateFormData(values: TherapistFormValues) {
  return {
    id: values[FormFields.Id] as string,
    firstName: values[FormFields.FirstName],
    lastName: values[FormFields.LastName],
    schools: values[FormFields.Organization].map((item) => item?.id ?? ''),
    skills: values[FormFields.Skills].map(id => Number(id)),
    pauseBetweenSessions: Number(values[FormFields.PauseBetweenSessions]),
    availability: prepareAvailability(values),
  };
}

export function prepareOrganizations(data: TherapistSchoolDto[]) {
  return data?.map(({ id, name, districtId, districtName }) => ({
    id,
    name,
    type: OrganizationType.School,
    parent: {
      id: districtId,
      name: districtName,
      type: OrganizationType.District,
    },
  }));
}

export function createTherapistAvailability(data: Availability[]): Availability[] {
  const dataMap = data.reduce((acc, item) => {
    if (item) {
      acc.set(item.day, item);
    }
    return acc;
  }, new Map<AvailabilityWeekDay, Availability>());

  return initialAvailability.map((item) => {
    const { id, day, intervals: defaultIntervals } = item;

    if (dataMap.has(day)) {

      return Availability.fromJS({
        id,
        day,
        checked: true,
        intervals: dataMap.get(day)?.intervals || defaultIntervals
      });
    }

    return Availability.fromJS(item);
  });
}
