export const enum FormFields {
  Name = 'name',
  Organization = 'organization',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export const enum UploadFormFields {
  Name = 'name',
  File = 'file'
}
