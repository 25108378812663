import { useFilterHandlers } from '../hooks';
import { useDistrictListContext } from './context';

import DistrictListFilters from '../components/Filters';


const DistrictListFiltersContainer = () => {
  const {
    searchByName,
  } = useFilterHandlers();

  const { filters } = useDistrictListContext();

  return (
    <DistrictListFilters
      filterValues={filters}
      onSearchByName={searchByName}
    />
  );
};

export default DistrictListFiltersContainer;
