import { Interval } from 'src/models/Interval';


export class AvailabilityListElement {
  constructor(
    readonly date: string,
    readonly subjectId: string,
    readonly subject: string,
    readonly intervals: Interval[]
  ) {}
}
