import { useFormikContext } from 'formik';

import {
  Form,
  Input,
  Select,
  SubmitButton } from 'formik-antd';
import {
  Form as AntForm,
  Grid,
  Space,
  Alert,
  Button }  from 'antd';
import Toggle from 'src/components/Toggle';

import { FormFields } from '../enums';
import { CreatePrescriptionFormValues } from '../types';


const { useBreakpoint } = Grid;

const PrescriptionForm = (props: IPrescriptionFormProps) => {
  const {
    sessionsOptions,
    studentsOptions,
    status,
    onCancel
  } = props;

  const {
    values,
    resetForm,
    setFieldValue,
    initialValues
  } = useFormikContext<CreatePrescriptionFormValues>();

  const activeScreens = useBreakpoint();

  return (
    <Form name={'prescription-create-form'}>
      <Form.Item name={FormFields.StudentId}>
        <Select options={studentsOptions} name={FormFields.StudentId} placeholder="Student*" />
      </Form.Item>

      <AntForm
        name="nest_prescriptions"
        style={{ maxWidth: 600 }}
        autoComplete="off"
      >
        <AntForm.List name="friends">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8, width: '100%' }} align="baseline">

                    <Form.Item name={`${FormFields.Prescriptions}[${key}].days`} hasFeedback={false}>
                      <Input name={`${FormFields.Prescriptions}[${key}].days`} placeholder="Days*" type={'number'} />
                    </Form.Item>

                    <Form.Item name={`${FormFields.Prescriptions}[${key}].sessions`} style={{ minWidth: '250px', width: '100%', flex: '1 1 auto'}}>
                      <Select mode="multiple" options={sessionsOptions} name={`${FormFields.Prescriptions}[${key}].sessions`} placeholder="Sessions*" />
                    </Form.Item>

                  <div onClick={() => remove(name)}>Remove</div>
                </Space>
              ))}
              <AntForm.Item>
                <Button type="dashed" onClick={() => add()} block>
                  Add Prescription
                </Button>
              </AntForm.Item>
            </>
          )}
        </AntForm.List>
      </AntForm>


      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Toggle show={!!status.failure}>
          <Alert type="error" message={(status as AjaxFailureStatus).failure.message} closable />
        </Toggle>

        <Toggle show={status.success}>
          <Alert
            type="success"
            message={'Prescription created'}
            closable
          />
        </Toggle>

        <Space>
          <SubmitButton loading={status.request} block={!activeScreens['sm']}>
           Create
          </SubmitButton>

          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default PrescriptionForm;


/* HELPERS */

interface IPrescriptionFormProps extends ICommonProps {
  status: IAjaxStatus;
  sessionsOptions: IOption[];
  studentsOptions: IOption[];
  onCancel?(): void;
}
