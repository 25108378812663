import { useNavigate, useParams, Navigate } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Result,
  Row,
  Spin,
  Typography }                    from 'antd';
import DoubleLeftOutlined         from '@ant-design/icons/DoubleLeftOutlined';
import { DistrictDetailsProvider }  from 'src/features/District/containers/DetailsProvider';
import DistrictEditForm             from 'src/features/District/Forms/containers/EditForm';

import Toggle                     from 'src/components/Toggle';

import urls                       from 'src/urls';


const DistrictEditPage = () => {
  const { district_id: districtId } = useParams();
  const navigate = useNavigate();

  if (!districtId) {
    return <Navigate to={urls.index()} />;
  }

  return (
    <DistrictDetailsProvider id={districtId}>
      {
        ({ status }) => {
          const cardButtons = [
            <Toggle key={'back-to-list-btn'} show={status.success}>
              <Button
                type="link"
                icon={<DoubleLeftOutlined />}
                onClick={() => navigate(urls.district())}
              >
                Back to Districts
              </Button>
            </Toggle>
          ];

          return (
            <Card
              title={<Typography.Title level={2}>Edit District</Typography.Title>}
              extra={cardButtons}
            >
              <Toggle show={status.request} tag="div" style={{ textAlign: 'center' }}>
                <Spin tip="Loading District data..." />
              </Toggle>

              <Toggle show={!!status.failure}>
                <Result
                  status="error"
                  title={(status as AjaxFailureStatus).failure.message}
                />
              </Toggle>

              <Toggle show={status.success}>
                <Row>
                  <Col xl={12} lg={16} md={24}>
                    <DistrictEditForm onCancel={() => navigate(urls.district())} />
                  </Col>
                </Row>
              </Toggle>
            </Card>
          );
        }
      }
    </DistrictDetailsProvider>
  );
};

export default DistrictEditPage;
