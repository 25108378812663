import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  Typography,
  notification } from 'antd';
import CreateForm from 'src/features/Timetable/Forms/containers/CreateForm';
import urls from 'src/urls';


const ScheduleCreatePage = () => {
  const navigate = useNavigate();

  const redirectToTimetableList = useCallback((timetableEntity: IEntity) => {
    notification.success({
      message: <Typography.Text strong>Timetable claim has been created!</Typography.Text>,
      description: (
        <Typography.Text>
          Timetable <Typography.Text strong>{ timetableEntity.name }</Typography.Text> was created
        </Typography.Text>
      ),
      duration: null,
    });

    navigate(urls.schedule());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card title={<Typography.Title level={2}>Generate a Schedule for an Existing School</Typography.Title>}>
      <Row>
        <Col xl={12} lg={16} md={24}>
          <CreateForm onCreated={redirectToTimetableList} onCancel={() => navigate(urls.schedule())} />
        </Col>
      </Row>
    </Card>
  );
};

export default ScheduleCreatePage;
