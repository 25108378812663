import axios, { AxiosError } from 'axios';
import {
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
  CreateAxiosDefaults } from 'axios';
import { ApiError } from 'src/modules/ErrorContext/models';
import {
  IHttpClient,
  IResponseFormat } from './types';
import { ACTION_CANCELED_STATUS } from 'src/constants';


const username = 'admin';
const password = 'caseload-DEMO';
const auth = { username, password };

export class AxiosHttpClient implements IHttpClient {
  private http: AxiosInstance;

  constructor(config?: CreateAxiosDefaults<any>) {
    this.http = axios.create(config);
  }

  private handleResponse(response: AxiosResponse): IResponseFormat {
    const { data, status, statusText } = response;

    return { data, status, statusText };
  }

  private handleError(error: AxiosError): never {
    const { response: { data, status, statusText } = {}, message } = error;

    if (error.code === 'ERR_CANCELED') {
      throw new ApiError('Action was canceled', ACTION_CANCELED_STATUS);
    }

    throw new ApiError((data as any)?.error?.message || message || statusText || '', status ?? 0);
  }

  head(url: string, params = {}, signal?: AbortSignal, config?: RequestConfig) {
    return this.http.head(url, { ...config, params, signal, auth }).then(this.handleResponse, this.handleError);
  }

  get(url: string, params = {}, signal?: AbortSignal, config?: RequestConfig) {
    return this.http.get(url, { ...config, params, signal, auth }).then(this.handleResponse, this.handleError);
  }

  put(url: string, data: unknown, params = {}, signal?: AbortSignal, config?: RequestConfig) {
    return this.http.put(url, data, { ...config, params, signal, auth }).then(this.handleResponse, this.handleError);
  }

  post(url: string, data: unknown, params = {}, signal?: AbortSignal, config?: RequestConfig) {
    return this.http.post(url, data, { ...config, params, signal, auth }).then(this.handleResponse, this.handleError);
  }

  patch(url: string, data: unknown, params = {}, signal?: AbortSignal, config?: RequestConfig) {
    return this.http.patch(url, data, { ...config, params, signal, auth }).then(this.handleResponse, this.handleError);
  }

  delete(url: string, params = {}, signal?: AbortSignal, config?: RequestConfig) {
    return this.http.delete(url, { ...config, params, signal, auth }).then(this.handleResponse, this.handleError);
  }
}

export type RequestConfig = Omit<AxiosRequestConfig, 'params' | 'signal'>
