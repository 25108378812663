import { useFormikContext }        from 'formik';

import {
  Form,
  Input,
  SubmitButton }                   from 'formik-antd';
import {
  Grid,
  Space,
  Alert,
  Button }                         from 'antd';
import Toggle from 'src/components/Toggle';

import { FormFields }              from '../enums';
import { DistrictFormValues }      from '../types';


const { useBreakpoint } = Grid;

const DistrictForm = (props: IDistrictFormProps) => {
  const {
    status,
    editableFields,
    onCancel
  } = props;

  const {
    values,
    resetForm,
    setFieldValue,
    initialValues
  } = useFormikContext<DistrictFormValues>();
  const existingDistrict = !!values[FormFields.Id];

  const activeScreens = useBreakpoint();


  return (
    <Form name={'district-create-form'}>
      <Form.Item name={FormFields.Name} hasFeedback={false}>
        <Input name={FormFields.Name} placeholder="Name*" disabled={!editableFields.has(FormFields.Name)} />
      </Form.Item>

      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Toggle show={!!status.failure}>
          <Alert type="error" message={(status as AjaxFailureStatus).failure.message} closable />
        </Toggle>

        <Toggle show={status.success}>
          <Alert
            type="success"
            message={existingDistrict ? 'District updated' : 'District created'}
            closable
          />
        </Toggle>

        <Space>
          <SubmitButton loading={status.request} block={!activeScreens['sm']}>
            { existingDistrict ? 'Save' : 'Create' }
          </SubmitButton>

          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default DistrictForm;


/* HELPERS */

interface IDistrictFormProps extends ICommonProps {
  status: IAjaxStatus;
  editableFields: Set<FormFields>;
  onCancel?(): void;
}
