import React, {useMemo} from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import { useTherapistListContext } from './context';
import { useSkillListContext } from 'src/features/Skill/List/containers/context';

import TherapistList from '../components/TherapistList';

import type { ColumnsType } from 'antd/es/table';
import { TherapistSchool } from '../models/TherapistListElement';
import { TableColumns } from '../enums';

import { parseUtilisation } from 'src/features/Therapist/List/utils';

import urls from 'src/urls';


const TherapistListContainer = () => {
  const {
    status,
    collection,
    total,
    pagination: { page, pageSize },
    changePage,
  } = useTherapistListContext();

  const { collection: skillCollection } = useSkillListContext();
  const skillsMap = useMemo(() => skillCollection.reduce((arr, element) => {
    const { id, name } = element;

    arr.set(Number(id), name);

    return arr;
  }, new Map<number, string>()), [
    skillCollection,
  ]);

  const tableData = useMemo(() => collection.reduce((arr, element) => {
    const { id, name, utilization, timetableId, schools, skills } = element;

    arr.push({
      key: id,
      [TableColumns.Name]: name,
      [TableColumns.Districts]: schools || [],
      [TableColumns.Schools]: schools || [],
      [TableColumns.Skills]: skills || [],
      [TableColumns.Utilization]: utilization,
      [TableColumns.Schedule]: timetableId,
      [TableColumns.Actions]: id,
    });

    return arr;
  }, [] as ITherapistListElementData[]), [
    collection,
  ]);

  const tableColumns: ColumnsType<ITherapistListElementData> = useMemo(() => ([
    {
      key: TableColumns.Name,
      title: 'Therapist Name',
      dataIndex: TableColumns.Name,
      render: (value: string, record: ITherapistListElementData) => <Link to={urls.therapistDetails(record.key)}>{ value }</Link>
    },
    {
      key: TableColumns.Districts,
      title: 'District Name',
      dataIndex: TableColumns.Districts,
      render: (value: TherapistSchool[]) => {
        const districtsSet = new Set(value.map((school: TherapistSchool) => school?.districtName));

        return [...districtsSet].map((name: string) => (
          <div><Typography.Text>{name}</Typography.Text></div>
        ));
      }
    },
    {
      key: TableColumns.Schools,
      title: 'School Name',
      dataIndex: TableColumns.Schools,
      render: (value: TherapistSchool[]) => value.map((school: TherapistSchool) => (
        <div><Typography.Text>{school?.name}</Typography.Text></div>
      ))
    },
    {
      key: TableColumns.Skills,
      title: 'Speciality',
      dataIndex: TableColumns.Skills,
      render: (value: number[]) => {
        const skills = value?.map(id => skillsMap?.get(id) || '');

        return skills.map((name: string) => (
          <div><Typography.Text>{name ?? <>&mdash;</>}</Typography.Text></div>
        ));
      }
    },
    {
      key: TableColumns.Utilization,
      title: 'Utilization (%)',
      dataIndex: TableColumns.Utilization,
      render: (value: number, record: ITherapistListElementData) =>  <Typography.Text>{ value !== null ? `${parseUtilisation(value)}%` : <>&mdash;</> }</Typography.Text>
    },
    {
      key: TableColumns.Schedule,
      title: 'Schedule',
      dataIndex: TableColumns.Schedule,
      render: (value: string, record: ITherapistListElementData) => value ? <Link to={urls.scheduleCalendar(value)} title={value}>View</Link> : <>&mdash;</>
    },
    {
      key: TableColumns.Actions,
      title: 'Actions',
      dataIndex: TableColumns.Actions,
      render: (value: string, record: ITherapistListElementData) => value ? <div>
        <Link to={urls.therapistDetails(value)} style={{ marginRight: '14px' }}>View</Link>
        {' '}
        <Link to={urls.therapistEdit(value)} title={value}>Edit</Link>
      </div> : <>&mdash;</>
    }
  ].filter(Boolean) as ColumnsType<ITherapistListElementData>), [skillsMap]);

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
  }), [ page, total, pageSize ]);

  return (
    <TherapistList
      tableColumns={tableColumns}
      tableData={tableData}
      pagination={pagination}
      changePage={changePage}
      loading={status.request}
    />
  );
};

export default TherapistListContainer;


export interface ITherapistListElementData {
  key: React.Key;
  [TableColumns.Name]: string;
  [TableColumns.Districts]: TherapistSchool[];
  [TableColumns.Schools]: TherapistSchool[];
  [TableColumns.Skills]: number[];
  [TableColumns.Utilization]: number;
  [TableColumns.Schedule]: string;
  [TableColumns.Actions]: string;
}
