import React, { useEffect } from 'react';
import { List, RecordOf } from 'immutable';
import { useMemo } from 'react';
import {
  useRequestKey,
  useListState,
  useFilters,
  usePagination,
  useAjaxStatus,
  useDebouncedValue } from 'src/hooks';

import { district as DistrictService } from 'src/services';

import { createCriteria, parseDistrictList } from './utils';
import { AjaxStatus } from 'src/constants';
import { DistrictListData } from './types';
import { DistrictListElement } from '../../models/DistrictListElement';
import { ChildrenFunc } from 'src/utils';
import { DistrictListFiltersValues } from 'src/features/District/List/types';
import { DistrictFilters } from '../../constants';


export const DistrictListContext = React.createContext<DistrictListData>({
  status: AjaxStatus.default(),
  filters: new DistrictFilters(),
  pagination: {
    page: 0,
    pageSize: 0,
  },
  total: 0,
  collection: List<DistrictListElement>(),
  changePage: () => void(0),
  resetFilters: () => void(0),
  changeFilterValues: () => void(0),
});


export const DistrictListProvider = (props: IDistrictsListProviderProps) => {
  const { children, initialFilters = new DistrictFilters() } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const {
    filters,
    resetFilters,
    changeFilterValues
  } = useFilters<DistrictListFiltersValues>(initialFilters);

  const { pagination, changePage } = usePagination(1, 10);

  const {
    total,
    collection,
    setCollectionState
  } = useListState<DistrictListElement>();

  const { engage, isCurrentRequest } = useRequestKey();

  const filtersValue = useDebouncedValue(filters, 300);

  useEffect(() => {
    const [ loadList, abort ] = DistrictService.getDistrictList(createCriteria(filtersValue, pagination));
    const key = engage();

    setRequest();

    loadList()
      .then(({ count, items }) => {

        setCollectionState({
          total: count!,
          collection: parseDistrictList(items!),
        });
        setSuccess();
      })
      .catch(err => {
        isCurrentRequest(key) && setFailure(err);
      });

    return () => abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pagination, filtersValue ]);

  const value = useMemo(() => ({
    status,
    total,
    filters,
    pagination,
    collection,
    changePage,
    resetFilters,
    changeFilterValues,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    status,
    total,
    filters,
    pagination,
    collection,
    changePage,
    resetFilters,
    changeFilterValues,
  ]);

  return (
    <DistrictListContext.Provider value={value}>
      { typeof children === 'function' ? children(value) : children }
    </DistrictListContext.Provider>
  );
};

/* HELPERS */

interface IDistrictsListProviderProps {
  initialFilters?: RecordOf<DistrictListFiltersValues>;
  children?: React.ReactNode | ChildrenFunc<DistrictListData>;
}
