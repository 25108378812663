import { useEffect, useState } from 'react';
import {
  useAjaxStatus,
  useRequestKey,
} from 'src/hooks';

import { timetable as TimetableService } from 'src/services';
import { TimetableDetails } from '../models/TimetableDetails';
import { ApiError } from 'src/modules/ErrorContext/models';


export const useTimetableDetails = (id: string) => {
  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();
  const { engage, isCurrentRequest } = useRequestKey();

  const [ timetableDetails, setTimetableDetails ] = useState<Nullable<TimetableDetails>>(null);

  useEffect(() => {
    const [ loadDetails, abort ] = TimetableService.getTimetableDetails(id);
    const key = engage();

    setRequest();

    loadDetails()
      .then(data => {
        if (isCurrentRequest(key)) {
          setTimetableDetails(TimetableDetails.fromJS(data));
          setSuccess();
        }
      })
      .catch((err: ApiError) => {
        if (isCurrentRequest(key)) {
          setFailure(err);
        }
      });


    return () => abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id ]);

  return {
    timetableDetails,
    status
  };
};
