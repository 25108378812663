export class StudentListElement {
  constructor(
    readonly id: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly schoolDistrictId: string,
    readonly schoolDistrictName: string,
    readonly schoolId: string,
    readonly schoolName: string,
    readonly needs: StudentNeed[],
    readonly timetableId: string,
  ) {}

  get name() {
    return StudentListElement.createNameString({
      firstName: this.firstName,
      lastName: this.lastName,
    });
  }

  static createNameString(name: {
    firstName: Nullable<string>,
    lastName: Nullable<string>,
  }) {
    return [
      name.firstName,
      name.lastName,
    ].filter(Boolean).join(' ');
  }
}

export type StudentNeed = {
  id: number;
  hours: number;
  online: boolean;
  group: boolean;
}
