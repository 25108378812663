import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Space,
  Typography } from 'antd';
import urls from 'src/urls';
import TimetableUploadForm from 'src/features/Timetable/Forms/containers/UploadForm';


const TimetableUploadPage = () => {
  const navigate = useNavigate();

  const createButton = <Button onClick={() => navigate(urls.timetableCreate())} type="primary">Create</Button>;

  return (
    <Card
      title={<Typography.Title level={2}>Upload Timetable Data</Typography.Title>}
    >
      <div>
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <TimetableUploadForm />
          </Space>
        </Space>
      </div>
    </Card>
  );
};

export default TimetableUploadPage;
