import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  Typography,
  notification } from 'antd';
import CreateForm from 'src/features/Prescription/Forms/containers/CreateForm';
import { SessionListProvider } from 'src/features/Session/List/containers/context';
import { StudentListProvider } from 'src/features/Student/List/containers/context';
import urls from 'src/urls';


const PrescriptionCreatePage = () => {
  const navigate = useNavigate();

  const redirectToPrescriptionList = useCallback((prescriptionEntity: IEntity) => {
    notification.success({
      message: <Typography.Text strong>Prescription has been created!</Typography.Text>,
      description: (
        <Typography.Text>
          Prescription <Typography.Text strong>{ prescriptionEntity.name }</Typography.Text> was created
        </Typography.Text>
      ),
      duration: null,
    });

    navigate(urls.student());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card title={<Typography.Title level={2}>Create Prescription</Typography.Title>}>
      <Row>
        <Col xl={12} lg={16} md={24}>
          <SessionListProvider>
            <StudentListProvider>
              <CreateForm onCreated={redirectToPrescriptionList} onCancel={() => navigate(urls.student())} />
            </StudentListProvider>
          </SessionListProvider>
        </Col>
      </Row>
    </Card>
  );
};

export default PrescriptionCreatePage;
