import { Link } from 'react-router-dom';
import {
  DeploymentUnitOutlined,
  BarChartOutlined,
}  from '@ant-design/icons';

import urls from 'src/urls';

import { IApplicationMenuItem } from './types';


export const menuItems: IApplicationMenuItem[] = [
  // {
  //   key: urls.dashboard(),
  //   url: urls.dashboard(),
  //   label: <Link to={urls.dashboard()}>Dashboard</Link>,
  //   icon: <BarChartOutlined />,
  //   title: 'Dashboard',
  //   disabled: true
  // },
  // {
  //   key: urls.timetableCreate(),
  //   url: urls.timetableCreate(),
  //   label: <Link to={urls.timetableCreate()}>Create Timetable</Link>,
  //   icon: <DeploymentUnitOutlined />,
  //   title: 'Create Timetable',
  // },
  // {
  //   key: urls.timetableUpload(),
  //   url: urls.timetableUpload(),
  //   label: <Link to={urls.timetableUpload()}>Upload Timetable</Link>,
  //   icon: <DeploymentUnitOutlined />,
  //   title: 'Upload Data for Timetable',
  // },
  // {
  //   key: urls.timetable(),
  //   url: urls.timetable(),
  //   label: <Link to={urls.timetable()}>Timetables</Link>,
  //   icon: <DeploymentUnitOutlined />,
  //   title: 'Timetables',
  // },
  {
    key: urls.schedule(),
    url: urls.schedule(),
    label: <Link to={urls.schedule()}>Schedule</Link>,
    title: 'Schedule',
  },
  {
    key: urls.school(),
    url: urls.school(),
    label: <Link to={urls.school()}>Schools</Link>,
    // icon: <DeploymentUnitOutlined />,
    title: 'Schools',
  },
  {
    key: urls.district(),
    url: urls.district(),
    label: <Link to={urls.district()}>Districts</Link>,
    // icon: <DeploymentUnitOutlined />,
    title: 'Districts',
  },
  {
    key: urls.therapist(),
    url: urls.therapist(),
    label: <Link to={urls.therapist()}>Therapists</Link>,
    // icon: <DeploymentUnitOutlined />,
    title: 'Therapists',
  },
  {
    key: urls.student(),
    url: urls.student(),
    label: <Link to={urls.student()}>Students</Link>,
    // icon: <DeploymentUnitOutlined />,
    title: 'Students',
  },
];
