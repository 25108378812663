import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Space,
  Typography } from 'antd';
import urls from 'src/urls';
import { TherapistListProvider } from 'src/features/Therapist/List/containers/context';
import { SkillListProvider } from 'src/features/Skill/List/containers/context';
import TherapistList from 'src/features/Therapist/List/containers/TherapistList';
import TherapistListFilters from 'src/features/Therapist/List/containers/TerapistListFilters';


const TherapistPage = () => {
  const navigate = useNavigate();

  const createButton = <Button onClick={() => navigate(urls.therapistCreate())} type="primary">Add a Therapist</Button>;

  return (
    <Card
      title={<Typography.Title level={2}>Therapists</Typography.Title>}
      extra={
        <Space size="middle">
          { createButton }
        </Space>
      }
    >
      <div>
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <h2>List of Therapists</h2>

          <TherapistListProvider>
          <SkillListProvider>
            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
              <TherapistListFilters />
              <TherapistList />
            </Space>
          </SkillListProvider>
          </TherapistListProvider>
        </Space>
      </div>
    </Card>
  );
};

export default TherapistPage;
