import { FormFields, UploadFormFields }    from './enums';
import { CreateTimetableFormValues, UploadTimetableFormValues } from './types';


export function prepareCreateFormData(values: CreateTimetableFormValues) {
  return {
    name: values[FormFields.Name],
    startDate: values[FormFields.StartDate],
    endDate: values[FormFields.EndDate],
    schools: values[FormFields.Organization].map((item) => item?.id),
    autoPrescription: true,
  };
}

export function prepareUploadFormData(values: UploadTimetableFormValues): FormData {
  const formData = new FormData();
  const formFileInfo = values[UploadFormFields.File];

  formData.append('name', values[UploadFormFields.Name]);
  formData.append('file', formFileInfo!.originFileObj as File);

  return formData;
}
