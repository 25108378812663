import React, {useMemo} from 'react'
import { Table, TablePaginationConfig } from 'antd';

import type { ColumnsType } from 'antd/es/table';

import { ITimetableListElementData } from '../containers/TimetableList';
import { createTableChangeHandler } from 'src/utils/antd';

import './TimetableList.scss';


interface ITimetableListProps extends ICommonProps {
  tableData: ITimetableListElementData[]
  tableColumns: ColumnsType<ITimetableListElementData>
  loading: boolean
  pagination?: false | TablePaginationConfig
  changePage?(page: number, pageSize: number): void
}

const TimetableList = (props: ITimetableListProps) => {
  const {
    tableData,
    tableColumns,
    loading,
    pagination,
    changePage
  } = props;

  const onTableStateChange = useMemo(() => createTableChangeHandler<ITimetableListElementData>({
    onPageChange: changePage,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return (
    <Table
      columns={tableColumns}
      dataSource={tableData}
      loading={loading}
      pagination={pagination}
      onChange={onTableStateChange}
    />
  );
};

export default React.memo(TimetableList);
