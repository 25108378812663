import { useMemo }                      from 'react';
import { useOrganizationSearchContext } from '../Provider';

import { CommonOption }                 from 'src/models/CommonOption';


export const useDistrictField = () => {
  const { districtValue, setDistrictValue } = useOrganizationSearchContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => districtValue ? CommonOption.fromEntity(districtValue) : null, [ districtValue?.id ]);

  return {
    value,
    changeFieldValue: setDistrictValue,
  };
};

export const useSchoolField = () => {
  const { schoolValue, setSchoolValue } = useOrganizationSearchContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => schoolValue ? CommonOption.fromEntity(schoolValue) : null, [ schoolValue?.id ]);

  return {
    value,
    changeFieldValue: setSchoolValue,
  };
};
