import React, { useRef }           from 'react';
import { useFormikContext }        from 'formik';
import * as Yup                    from 'yup';
import {
  Form,
  Input,
  Checkbox,
  Select,
  SubmitButton,
  TimePicker }                     from 'formik-antd';
import { FieldArray }              from 'formik';

import {
  Flex,
  Row,
  Col,
  Grid,
  Space,
  Alert,
  Button }                         from 'antd';

import * as OrganizationSearch     from 'src/modules/OrganizationSearch';
import { OrganizationWithParent }  from 'src/modules/OrganizationSearch/types';
import Toggle                      from 'src/components/Toggle';

import { FormFields }              from '../enums';
import { TherapistFormValues }     from '../types';


const { useBreakpoint } = Grid;

const TherapistForm = (props: ITherapistFormProps) => {
  const {
    status,
    editableFields,
    onCancel,
    validationSchema,
    skillsOptions
  } = props;

  const {
    values,
    resetForm,
    setFieldValue,
    initialValues,
    isValid,
    errors
  } = useFormikContext<TherapistFormValues>();

  const existingTherapist = !!values[FormFields.Id];

  const organizationRequired = true;

  const activeScreens = useBreakpoint();

  const { current: getFieldName } = useRef((id: number) => `${FormFields.Organization}.${id}`);
  const { current: changeOrganizationField } = useRef((val: OrganizationWithParent, fieldId: string) => setFieldValue(`${fieldId}`, val));

  return (
    <Form name={'therapist-create-form'}>
      <Form.Item name={FormFields.FirstName} hasFeedback={false}>
        <Input name={FormFields.FirstName} placeholder="First Name*" disabled={!editableFields.has(FormFields.FirstName)} />
      </Form.Item>

      <Form.Item name={FormFields.LastName} hasFeedback={false}>
        <Input name={FormFields.LastName} placeholder="Last Name*" disabled={!editableFields.has(FormFields.LastName)} />
      </Form.Item>

      <Form.Item name={FormFields.Skills}>
        <Select mode="multiple" options={skillsOptions} name={FormFields.Skills} placeholder="Skills*" />
      </Form.Item>

      <Form.Item name={FormFields.PauseBetweenSessions}>
        <Input name={FormFields.PauseBetweenSessions} placeholder="Pause Between Sessions*" />
      </Form.Item>

      <Space direction="vertical" size="large" style={{ width: '100%', marginBottom: '16px' }}>
        <FieldArray name={FormFields.Organization}>
          {
            ({ remove, push }) => (
              <Flex gap="unset" vertical>
                {
                  values[FormFields.Organization]?.map((_, idx) => {
                    return (
                      <section key={idx}>
                        <OrganizationSearch.Provider
                          onChange={changeOrganizationField}
                          initialValues={initialValues[FormFields.Organization][idx]}
                          fieldId={getFieldName(idx)}
                        >
                          <Row gutter={[8, 8]} key={idx}>
                            <Col span={6}>
                              <Form.Item
                                name={`${FormFields.Organization}.${idx}`}
                                hasFeedback={false}
                                preserve={false}
                                help={null}
                              >
                                <OrganizationSearch.District
                                  style={{ width: '100%' }}
                                  placeholder={organizationRequired ? 'District*' : 'District'}
                                  labelInValue
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={`${FormFields.Organization}.${idx}`}
                                hasFeedback={false}
                                preserve={false}
                              >
                                <OrganizationSearch.School
                                  style={{ width: '100%' }}
                                  placeholder={organizationRequired ? 'School*' : 'School'}
                                  labelInValue
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Button type="dashed" onClick={() => remove(idx)}>
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        </OrganizationSearch.Provider>
                      </section>
                    );
                  })
                }

                <Toggle show={values[FormFields.Organization]?.length < 1}>
                  <Space size={'middle'} direction="vertical">
                    <Button
                      type="dashed"
                      onClick={() => push(null)}
                    >
                      Add School
                    </Button>
                  </Space>
                </Toggle>
              </Flex>
            )
          }
        </FieldArray>
      </Space>

      <Form.Item name={FormFields.CustomAvailability}>
        <Checkbox
          name={FormFields.CustomAvailability}
        >
          {'Availability is different than the school hours'}
        </Checkbox>
      </Form.Item>

      <Toggle show={values[FormFields.CustomAvailability]}>
        <h2>Working Hours</h2>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <FieldArray name={FormFields.Availability}>
            {
              ({ remove, push }) => (
                <Flex gap="unset" vertical>
                  {
                    values[FormFields.Availability]?.map((availability, idx) => (
                      <Row gutter={[ 8, 8 ]} key={idx}>
                        <Col span={6} style={{ paddingTop: '6px' }}>
                          <Checkbox
                            name={`${FormFields.Availability}.${idx}.checked`}
                            // onChange={}
                          >
                            {availability.day}
                          </Checkbox>
                        </Col>
                        <Col span={4}>
                          <Form.Item name={`${FormFields.Availability}.${idx}.start`}>
                            <TimePicker minuteStep={15} use12Hours format="h:mm a" disabled={!values[FormFields.Availability][idx].checked} name={`${FormFields.Availability}.${idx}.intervals.start`} placeholder="From HH:MM" />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item name={`${FormFields.Availability}.${idx}.end`}>
                            <TimePicker minuteStep={15} use12Hours format="h:mm a" disabled={!values[FormFields.Availability][idx].checked} name={`${FormFields.Availability}.${idx}.intervals.end`} placeholder="Till HH:MM" />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))
                  }
                </Flex>
              )
            }
          </FieldArray>
        </Space>
      </Toggle>

      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Toggle show={!!status.failure}>
          <Alert type="error" message={(status as AjaxFailureStatus).failure.message} closable />
        </Toggle>

        <Toggle show={status.success}>
          <Alert
            type="success"
            message={existingTherapist ? 'Therapist updated' : 'Therapist added'}
            closable
          />
        </Toggle>

        <Space>
          <SubmitButton loading={status.request} block={!activeScreens['sm']}>
            { existingTherapist ? 'Save' : 'Add' }
          </SubmitButton>

          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default TherapistForm;


/* HELPERS */

interface ITherapistFormProps extends ICommonProps {
  status: IAjaxStatus;
  editableFields: Set<FormFields>;
  onCancel?(): void;
  skillsOptions: IOption[];
  validationSchema: Yup.AnyObjectSchema;
}
