import { useEffect } from 'react';
import { useAjaxStatus, useListState, useRequestKey } from 'src/hooks';
import { SkillListElement } from 'src/features/Skill/List/models/SkillListElement';
import { skill as SkillService } from 'src/services';
import { parseSkillList } from 'src/features/Skill/List/containers/context/utils';


export const useTherapistSkills = () => {
  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const {
    collection,
    setCollectionState
  } = useListState<SkillListElement>();

  const {engage, isCurrentRequest} = useRequestKey();

  useEffect(() => {
    const [loadList, abort] = SkillService.getSkillList();
    const key = engage();

    setRequest();

    loadList()
      .then((data) => {

        setCollectionState({
          total: data ? data.length : 0,
          collection: parseSkillList(data!),
        });
        setSuccess();
      })
      .catch(err => {
        isCurrentRequest(key) && setFailure(err);
      });

    return () => abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    status,
    collection
  };
};
