import { useFilterHandlers } from '../hooks';
import { useTherapistListContext } from './context';

import TherapistListFilters from '../components/Filters';


const TherapistListFiltersContainer = () => {
  const {
    changeOrganizationFilter,
    searchByName,
  } = useFilterHandlers();

  const { filters } = useTherapistListContext();

  return (
    <TherapistListFilters
      filterValues={filters}
      onFilterByOrganization={changeOrganizationFilter}
      onSearchByName={searchByName}
    />
  );
};

export default TherapistListFiltersContainer;
