import { List } from 'immutable';

import { SessionListElementDto } from 'src/services/api/session/session.dto';
import { SessionListElement } from '../../models/SessionListElement';


export function parseSessionList(items: SessionListElementDto[]): List<SessionListElement> {
  return List(items.map(item => {
    const {
      id,
      name,
      description,
      duration,
      preparingTime,
      finishingTime,
      need,
      group,
      online,
      schoolId,
    } = item;

    return new SessionListElement(
      id,
      name,
      description,
      duration,
      preparingTime,
      finishingTime,
      need,
      group,
      online,
      schoolId,
    );
  }));
}
