import {
  useNavigate,
  useParams,
  Navigate }                      from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Result,
  Row,
  Spin,
  Typography }                    from 'antd';
import DoubleLeftOutlined         from '@ant-design/icons/DoubleLeftOutlined';
import { StudentDetailsProvider } from 'src/features/Student/containers/DetailsProvider';
import StudentEditForm            from 'src/features/Student/Forms/containers/EditForm';

import Toggle                     from 'src/components/Toggle';

import urls                       from 'src/urls';


const StudentEditPage = () => {
  const { student_id: studentId } = useParams();
  const navigate = useNavigate();

  if (!studentId) {
    return <Navigate to={urls.index()} />;
  }

  return (
    <StudentDetailsProvider id={studentId}>
      {
        ({ status }) => {
          const cardButtons = [
            <Toggle key={'back-to-list-btn'} show={status.success}>
              <Button
                type="link"
                icon={<DoubleLeftOutlined />}
                onClick={() => navigate(urls.student())}
              >
                Back to Students
              </Button>
            </Toggle>
          ];

          return (
            <Card
              title={<Typography.Title level={2}>Edit Student</Typography.Title>}
              extra={cardButtons}
            >
              <Toggle show={status.request} tag="div" style={{ textAlign: 'center' }}>
                <Spin tip="Loading Student data..." />
              </Toggle>

              <Toggle show={!!status.failure}>
                <Result
                  status="error"
                  title={(status as AjaxFailureStatus).failure.message}
                />
              </Toggle>

              <Toggle show={status.success}>
                <Row>
                  <Col xl={12} lg={16} md={24}>
                    <StudentEditForm onCancel={() => navigate(urls.student())} />
                  </Col>
                </Row>
              </Toggle>
            </Card>
          );
        }
      }
    </StudentDetailsProvider>
  );
};

export default StudentEditPage;
