import {
  Navigate,
  useParams,
  useNavigate } from 'react-router-dom';
import {
  Card,
  Space,
  Spin,
  Result,
  Typography,
  Button } from 'antd';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { DistrictDetailsProvider } from 'src/features/District/containers/DetailsProvider';
import DistrictInfo from 'src/features/District/Details/components/DistrictInfo';
import Toggle from 'src/components/Toggle';


import urls from 'src/urls';


const DistrictDetailsPage = () => {
  const { district_id: districtId } = useParams();
  const navigate = useNavigate();

  if (!districtId) {
    return <Navigate to={urls.index()} />;
  }

  return (
    <DistrictDetailsProvider id={districtId}>
      {
        ({ status, districtDetails }) => {

          const cardButtons = (
            <Space size="middle">
              <Toggle>
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => navigate(urls.districtEdit(districtId))}
                >
                  Edit
                </Button>
              </Toggle>
            </Space>
          );

          return (
            <Card
              title={<Typography.Title level={2}>District Details</Typography.Title>}
              extra={cardButtons}
            >
              <Toggle show={status.request} tag="div" style={{ textAlign: 'center' }}>
                <Spin tip="Loading District data..." />
              </Toggle>

              <Toggle show={!!status.failure}>
                <Result
                  status="error"
                  title={(status as AjaxFailureStatus).failure.message}
                />
              </Toggle>

              <Toggle show={status.success}>
                <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                  <DistrictInfo
                    details={districtDetails!}
                  />
                </Space>
              </Toggle>
            </Card>
          );
        }
      }
    </DistrictDetailsProvider>
  );
};

export default DistrictDetailsPage;
