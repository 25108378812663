export const enum FilterFields {
  Name = 'name',
  Organization = 'organization',
  Signed = 'signed',
}

export const enum SortFields {
  Name = 'name',
  ModificationDate = 'modifiedAt',
}

export const enum TableColumns {
  Name = 'name',
  School = 'school',
  District = 'district',
  Needs = 'Needs',
  Availability = 'availability',
  Schedule = 'schedule',
  Actions = 'actions',
}
