import { useCallback }              from 'react';
import { useAjaxStatus }            from 'src/hooks';
import { useValidationSchema }      from '../hooks';

import { Formik }                   from 'formik';
import Form                         from '../components/Form';

import { district as DistrictService }  from 'src/services';

import { prepareCreateFormData }    from '../utils';
import { FormFields }               from '../enums';
import { DistrictFormValues }         from '../types';
import { ApiError }                 from 'src/modules/ErrorContext/models';


const DistrictCreateFormContainer = (props: IDistrictCreateFormContainerProps) => {
  const { onCancel, onCreated } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const createDistrict = useCallback(async (values: DistrictFormValues) => {
    setRequest();

    try {
      const [ createDistrict ] = DistrictService.createDistrict(prepareCreateFormData(values));

      const id = await createDistrict();

      setSuccess();

      if (typeof onCreated === 'function') {
        onCreated({
          id,
          name: values[FormFields.Name],
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onCreated ]);

  const validationSchema = useValidationSchema();
  const editableFields = new Set([
    FormFields.Name,
  ]);

  return (
    <Formik
      initialValues={{
        [FormFields.Id]: null,
        [FormFields.Name]: '',
      }}
      validationSchema={validationSchema}
      onSubmit={createDistrict}
    >
      <Form
        status={status}
        editableFields={editableFields}
        onCancel={onCancel}
      />
    </Formik>
  );
};

export default DistrictCreateFormContainer;


/* HELPERS */

interface IDistrictCreateFormContainerProps extends ICommonProps {
  onCancel?(): void;
  onCreated?(districtEntity: IEntity): void;
}
