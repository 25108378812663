import { UploadFile }    from 'antd';
import {
  RcFile,
  UploadFileStatus }     from 'antd/es/upload/interface';


export class FileInfo implements IEntity {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly link: string,
    readonly uploadDate: Date,
    readonly uploadedBy: any = null
  ) {}
}

export class FormFileInfo<T = any> implements UploadFile<T> {
  uid: string = '';
  size?: number;
  name: string = '';
  fileName?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  url?: string;
  status?: UploadFileStatus;
  percent?: number;
  thumbUrl?: string;
  crossOrigin?: React.ImgHTMLAttributes<HTMLImageElement>['crossOrigin'];
  originFileObj?: RcFile;
  response?: T;
  error?: any;
  linkProps?: any;
  type?: string;
  xhr?: T;
  preview?: string;

  private constructor() {}

  get hasFileData() {
    return !!this.originFileObj;
  }

  static fromFormFile<T>(formFile: UploadFile) {
    const file = new FormFileInfo<T>();

    file.uid = formFile.uid;
    file.size = formFile.size;
    file.name = formFile.name;
    file.fileName = formFile.fileName;
    file.lastModified = formFile.lastModified;
    file.lastModifiedDate = formFile.lastModifiedDate;
    file.url = formFile.url;
    file.status = formFile.status;
    file.percent = formFile.percent;
    file.thumbUrl = formFile.thumbUrl;
    file.crossOrigin = formFile.crossOrigin;
    file.originFileObj = formFile.originFileObj;
    file.response = formFile.response;
    file.error = formFile.error;
    file.linkProps = formFile.linkProps;
    file.type = formFile.type;
    file.xhr = formFile.xhr;
    file.preview = formFile.preview;

    return file;
  }

  static fromFileInfo<T>(fileInfo: FileInfo) {
    const file = new FormFileInfo<T>();

    file.uid = fileInfo.id;
    file.name = fileInfo.name;
    file.status = 'done';
    file.url = fileInfo.link;
    file.lastModifiedDate = fileInfo.uploadDate;

    return file;
  }

  static fromFile<T, Meta extends Record<string, unknown>>(file: File, uid?: string, meta?: Meta) {
    const formFileInfo = new FormFileInfo<T>();
    const extFile = ExtendedFile.fromFile<Meta>(file, uid, meta);

    formFileInfo.uid = extFile.uid;
    formFileInfo.name = extFile.name;
    formFileInfo.size = extFile.size;
    formFileInfo.status = 'done';
    formFileInfo.lastModified = extFile.lastModified;
    formFileInfo.lastModifiedDate = extFile.lastModifiedDate;
    formFileInfo.originFileObj = extFile;

    return formFileInfo;
  }
}

export class ExtendedFile<Meta extends Record<string, unknown>> extends File {
  uid!: string;
  lastModifiedDate!: Date;
  meta!: Meta;

  private constructor(fileBits: BlobPart[], fileName: string, uid: string, meta: Meta = Object.create(null), lastModifiedDate: Date = new Date(), options?: FilePropertyBag | undefined) {
    super(fileBits, fileName, options);

    Object.defineProperty(this, 'lastModifiedDate', {
      enumerable: true,
      value: lastModifiedDate,
    });
    Object.defineProperty(this, 'uid', {
      enumerable: true,
      value: uid,
    });
    Object.defineProperty(this, 'meta', {
      enumerable: true,
      value: meta,
    });
  }

  static fromFile<Meta extends Record<string, unknown>>(file: File, uid: string = `${file.name}-${Date.now()}`, meta: Meta = Object.create(null)) {
    return new ExtendedFile([ file ], file.name, uid, meta, new Date(file.lastModified));
  }
}
