import { useNavigate, useParams, Navigate } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Result,
  Row,
  Spin,
  Typography }                    from 'antd';
import DoubleLeftOutlined         from '@ant-design/icons/DoubleLeftOutlined';
import { SchoolDetailsProvider }  from 'src/features/School/containers/DetailsProvider';
import { DistrictListProvider } from 'src/features/District/List/containers/context';
import SchoolEditForm             from 'src/features/School/Forms/containers/EditForm';

import Toggle                     from 'src/components/Toggle';

import urls                       from 'src/urls';


const SchoolEditPage = () => {
  const { school_id: schoolId } = useParams();
  const navigate = useNavigate();

  if (!schoolId) {
    return <Navigate to={urls.index()} />;
  }

  return (
    <SchoolDetailsProvider id={schoolId}>
      {
        ({ status }) => {
          const cardButtons = [
            <Toggle key={'back-to-list-btn'} show={status.success}>
              <Button
                type="link"
                icon={<DoubleLeftOutlined />}
                onClick={() => navigate(urls.school())}
              >
                Back to Schools
              </Button>
            </Toggle>
          ];

          return (
            <Card
              title={<Typography.Title level={2}>Edit School</Typography.Title>}
              extra={cardButtons}
            >
              <Toggle show={status.request} tag="div" style={{ textAlign: 'center' }}>
                <Spin tip="Loading School data..." />
              </Toggle>

              <Toggle show={!!status.failure}>
                <Result
                  status="error"
                  title={(status as AjaxFailureStatus).failure.message}
                />
              </Toggle>

              <Toggle show={status.success}>
                <Row>
                  <Col xl={12} lg={16} md={24}>
                    <DistrictListProvider>
                      <SchoolEditForm onCancel={() => navigate(urls.school())} />
                    </DistrictListProvider>
                  </Col>
                </Row>
              </Toggle>
            </Card>
          );
        }
      }
    </SchoolDetailsProvider>
  );
};

export default SchoolEditPage;
