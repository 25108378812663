import { Availability } from 'src/models/Availability';
import { Organization } from 'src/models/Organization';
import { OrganizationType } from 'src/enums';
import { parseSchoolAvailability } from 'src/features/School/Details/utils';


interface SchoolDetailsDto {
   id: string;
   name: string;
   districtId: string;
   districtName: string;
   availability: any[];
   timetableId: string;
}

export class SchoolDetails {
  private constructor(
    readonly id: string,
    readonly name: string,
    readonly organization: Nullable<Organization>,
    readonly availability: Availability[],
    readonly timetableId: string,
  ) {}

  static fromJS(data: SchoolDetailsDto) {

    return new SchoolDetails(
      data.id,
      data.name,
      data.districtId ? Organization.fromJS({ id: data.districtId, name: data.districtName, type: OrganizationType.District }) : null,
      parseSchoolAvailability(data.availability),
      data.timetableId,
    );
  }
}



