import React from 'react';
import { useContext } from 'react';
import { useDistrictDetails } from '../hooks';

import { AjaxStatus } from 'src/constants';
import { DistrictDetails } from '../models/DistrictDetails';
import { ChildrenFunc } from '@k12-frontend/utils';


export const DistrictDetailsContext = React.createContext<DistrictDetailsContextValue>({
  status: AjaxStatus.default(),
  districtDetails: null,
});
DistrictDetailsContext.displayName = 'DistrictDetailsContext';


export const useDistrictDetailsContext = () => useContext(DistrictDetailsContext);

export const DistrictDetailsProvider = (props: IDistrictDetailsProviderProps) => {
  const { id, children } = props;

  const value = useDistrictDetails(id);

  return (
    <DistrictDetailsContext.Provider value={value}>
      { typeof children === 'function' ? children(value) : children }
    </DistrictDetailsContext.Provider>
  );
};

/* HELPERS */

interface IDistrictDetailsProviderProps {
  id: string;
  children?: React.ReactNode | ChildrenFunc<DistrictDetailsContextValue>;
}

export type DistrictDetailsContextValue = {
  districtDetails: Nullable<DistrictDetails>;
  status: IAjaxStatus;
}
