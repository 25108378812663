import { useMemo } from 'react';
import { useTherapistSkills } from 'src/common/hooks/useTherapistSkills';


export const useTherapistSkillsOptions = () => {
  const { collection } = useTherapistSkills()

  const options = useMemo(() => collection.reduce((arr, element) => {
    const { id, name } = element;

    arr.push({ value: id, label: name });

    return arr;
  }, [] as IOption[]), [
    collection,
  ]);

  return {
    options
  }
}
