import {
  SyntheticEvent,
  useCallback } from 'react';

import { FilterFields }            from '../enums';
import { useSchoolListContext }    from '../containers/context';
import { OrganizationWithParent }  from 'src/modules/OrganizationSearch/types';


export const useFilterHandlers = () => {
  const { changeFilterValues } = useSchoolListContext();

  const changeOrganizationFilter = useCallback((value: Nullable<OrganizationWithParent>) => changeFilterValues({
    [FilterFields.Organization]: value,
  }), [ changeFilterValues ]);

  const searchByName = useCallback((e: SyntheticEvent<HTMLInputElement>) => changeFilterValues({ [FilterFields.Name]: e.currentTarget.value }), [ changeFilterValues ]);

  return {
    changeOrganizationFilter,
    searchByName,
  };
};
