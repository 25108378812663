export type UUID = string;

export interface IEntity {
  id: UUID;
  name: string;
}

export class CommonOption<T extends string> implements IOption<T> {
  private constructor(readonly value: T, readonly label: string) {}

  static create<T extends string>(value: T, label: string) {
    return new CommonOption(value, label);
  }

  static fromEntity(entity: IEntity) {
    return new CommonOption(entity.id, entity.name);
  }
}
