import * as Yup from 'yup';
import { OrganizationType } from 'src/enums';

export const PHONE_NUMBER_REGEXP = /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/gmi;

export const USA_ZIP_REGEXP = /^\d{5}((-|–|—)(\d{4}))?$/gmi;

export const OptionSchema = Yup.object({
  label: Yup.string().required(),
  value: Yup.string().required(),
});

export const EntitySchema = Yup.object({
  id: Yup.string().required(),
  name: Yup.string().required(),
});

export const OrganizationEntitySchema = EntitySchema.concat(Yup.object({
  type: Yup.string().oneOf([
    OrganizationType.District,
    OrganizationType.School,
  ])
}));

export const OrganizationSchema = OrganizationEntitySchema;

// concat(Yup.object({
//   parent: OrganizationEntitySchema.nullable(),
// }));
