export class TimetableListElement implements IEntity {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly createdAt: string,
    readonly status: string,
    readonly startDate: string,
    readonly endDate: string,
    readonly schools: string[],
    readonly error: string
  ) {}
}


export type TimetableSchool = {
  id: string,
  name: string,
  districtId: string,
  districtName: string,
}
