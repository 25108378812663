import { useState }     from 'react';
import { useBem }       from '@seaneiros/react-bem';

import {
  Space,
  Layout }              from 'antd';
// import Logo             from '../Logo';
// import ProfileNameplate from 'src/features/ProfileNameplate';
import AppMenu          from '../ApplicationMenu';
import { Outlet }       from 'react-router-dom';

import './ApplicationLayout.scss';


const { Header, Content, Sider } = Layout;

const ApplicationLayout = (props: any) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => setCollapsed(collapsed => !collapsed);

  const bem = useBem({ block: 'application-layout' }, props);

  return (
    <Layout className={bem.block()}>
      <Sider theme="light" onCollapse={toggleCollapsed} collapsible>
        <Space direction="vertical" size="large" style={{width: '100%'}}>
          <AppMenu collapsed={collapsed} />
        </Space>
      </Sider>

      <Layout>
        <Header className={bem.element('header')}>
          {/*Profile*/}
        </Header>

        <Content className={bem.element('content')}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default ApplicationLayout;
