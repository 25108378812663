import { Record }                     from 'immutable';

import { FilterFields }               from './enums';
import { TherapistListFiltersValues } from './types';


export const TherapistsFilters = Record<TherapistListFiltersValues>({
  [FilterFields.Name]: '',
  [FilterFields.Organization]: null,
});

