import { addHours, hoursToMinutes, startOfDay, format } from 'date-fns';
import { FormFields } from './enums';
import { StudentFormValues } from './types';
import { StudentAvailabilityDto } from 'src/services/api/student/student.dto';
import { Availability } from 'src/models/Availability';
import { AvailabilityWeekDay } from 'src/enums';
import { initialAvailability } from './constants';


function prepareAvailability(values: StudentFormValues) {
  return values[FormFields.Availability].reduce((acc, item) => {
    if (item.checked && item.intervals.start && item.intervals.end) {
      const [startHours, startMinutes] = format(item.intervals.start, 'HH:mm').split(':');
      const startInMinutes = hoursToMinutes(addHours(startOfDay(new Date()), Number(startHours)).getHours()) + Number(startMinutes);

      const [endHours, endMinutes] = format(item.intervals.end, 'HH:mm').split(':');
      const endInMinutes = hoursToMinutes(addHours(startOfDay(new Date()), Number(endHours)).getHours()) + Number(endMinutes);

      acc.push({ day: item.id, intervals: [{ start: startInMinutes, end: endInMinutes } ] });
    }

    return acc;
  }, [] as StudentAvailabilityDto[]);
}

export function prepareCreateFormData(values: StudentFormValues) {
  return {
    firstName: values[FormFields.FirstName],
    lastName: values[FormFields.LastName],
    schoolId: values[FormFields.Organization]?.id ?? null,
    needs: values[FormFields.Needs].map( ({ id, hours, online, group }) => ({ id, hours, online, group }) ),
    availability: prepareAvailability(values),
  };
}

export function prepareUpdateFormData(values: StudentFormValues) {
  return {
    id: values[FormFields.Id] as string,
    firstName: values[FormFields.FirstName],
    lastName: values[FormFields.LastName],
    schoolId: values[FormFields.Organization]?.id ?? null,
    needs: values[FormFields.Needs].map( ({ id, hours, online, group }) => ({ id, hours, online, group }) ),
    availability: prepareAvailability(values),
  };
}

export function createStudentAvailability(data: Availability[]): Availability[] {
  const dataMap = data.reduce((acc, item) => {
    if (item) {
      acc.set(item.day, item);
    }
    return acc;
  }, new Map<AvailabilityWeekDay, Availability>());

  return initialAvailability.map((item) => {
    const { id, day, intervals: defaultIntervals } = item;

    if (dataMap.has(day)) {

      return Availability.fromJS({
        id,
        day,
        checked: true,
        intervals: dataMap.get(day)?.intervals || defaultIntervals
      });
    }

    return Availability.fromJS(item);
  });
}
