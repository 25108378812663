import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Typography,
  notification } from 'antd';

import { useSearchVariables } from 'src/hooks/useUrlUtils';
import { SchoolDetailsProvider } from 'src/features/School/containers/DetailsProvider';
import CreateForm from 'src/features/Student/Forms/containers/CreateForm';
import Toggle from 'src/components/Toggle';

import urls from 'src/urls';


const StudentCreatePage = () => {
  const { school_id: schoolId } = useSearchVariables();

  const navigate = useNavigate();

  const redirectToStudentList = useCallback((studentEntity: IEntity) => {
    notification.success({
      message: <Typography.Text strong>Student has been created!</Typography.Text>,
      description: (
        <Typography.Text>
          Student <Typography.Text strong>{ studentEntity.name }</Typography.Text> was created
        </Typography.Text>
      ),
      duration: null,
    });

    navigate(urls.student());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!schoolId) {
    return (
      <Card title={<Typography.Title level={2}>Create Student</Typography.Title>}>
        <Row>
          <Col xl={12} lg={16} md={24}>
            <CreateForm onCreated={redirectToStudentList} onCancel={() => navigate(urls.student())} />
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card title={<Typography.Title level={2}>Create Student</Typography.Title>}>
      <Row>
        <Col xl={12} lg={16} md={24}>
          <SchoolDetailsProvider id={schoolId as string}>
            {
              ({ status, schoolDetails }) => (
                <Toggle show={status.success}>
                  <CreateForm onCreated={redirectToStudentList} onCancel={() => navigate(urls.student())} schoolDetails={schoolDetails} />
                </Toggle>
              )
            }
          </SchoolDetailsProvider>
        </Col>
      </Row>
    </Card>
  );
};

export default StudentCreatePage;
