import { Availability } from 'src/models/Availability';
import { Organization } from 'src/models/Organization';
import { OrganizationType } from 'src/enums';
import { parseStudentAvailability } from 'src/features/Student/Details/utils';

interface StudentDetailsDto {
  id: string,
  firstName: string,
  lastName: string,
  schoolDistrictId: string,
  schoolDistrictName: string,
  schoolId: string,
  schoolName: string,
  needs: StudentNeedDto[];
  availability: StudentAvailabilityDto[];
  timetableId: string;
}

type StudentNeedDto = {
  id: number;
  hours: number;
  online: boolean;
  group: boolean;
}

type StudentAvailabilityDto = {
  day: number;
  intervals: StudentAvailabilityIntervalsDto[];
}

type StudentAvailabilityIntervalsDto = {
  start: number;
  end: number;
}


export class StudentDetails {
  private constructor(
    readonly id: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly organization: Nullable<Organization>,
    readonly parentOrganization: Nullable<Organization>,
    readonly needs: StudentNeedDto[],
    readonly availability: Availability[],
    readonly timetableId: string,
  ) {}

  static fromJS(data: StudentDetailsDto) {

    return new StudentDetails(
      data.id,
      data.firstName,
      data.lastName,
      data.schoolId ? Organization.fromJS({ id: data.schoolId, name: data.schoolName, type: OrganizationType.School }) : null,
      data.schoolDistrictId ? Organization.fromJS({ id: data.schoolDistrictId, name: data.schoolDistrictName, type: OrganizationType.District }) : null,
      data.needs,
      parseStudentAvailability(data.availability),
      data.timetableId,
    );
  }
}



