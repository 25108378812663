export var SortOrder;
(function (SortOrder) {
    SortOrder["Asc"] = "+";
    SortOrder["Desc"] = "-";
})(SortOrder || (SortOrder = {}));
export var UserRoleType;
(function (UserRoleType) {
    UserRoleType["GlobalAdmin"] = "SUPER_ADMIN";
    UserRoleType["OrganizationAdmin"] = "ORGANIZATION_ADMIN";
    UserRoleType["OrganizationObserver"] = "ORGANIZATION_OBSERVER";
    UserRoleType["GlobalObserver"] = "GLOBAL_OBSERVER";
})(UserRoleType || (UserRoleType = {}));
export var OrganizationType;
(function (OrganizationType) {
    OrganizationType["District"] = "DISTRICT";
    OrganizationType["Facility"] = "FACILITY";
})(OrganizationType || (OrganizationType = {}));
export var DocumentType;
(function (DocumentType) {
    DocumentType["Eop"] = "eop";
    DocumentType["FlipChart"] = "flipChart";
    DocumentType["Resource"] = "resource";
})(DocumentType || (DocumentType = {}));
export var Gender;
(function (Gender) {
    Gender["Male"] = "MALE";
    Gender["Female"] = "FEMALE";
    Gender["NonBinary"] = "NON_BINARY";
})(Gender || (Gender = {}));
