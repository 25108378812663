import { ApiIntent, ApiService } from '../types';
import {
  AvailabilityDetailsDto,
  AvailabilityListDto,
  AvailabilityListElementDto,
  AvailabilityUpdateDto,
} from './availability.dto';
import { IAvailabilityListCriteria } from './types';


export class AvailabilityApiService extends ApiService {
  getAvailabilityDetails(id: string): ApiIntent<any> {
    const [ getAvailability, abort ] = this.createGetIntent<AvailabilityDetailsDto>(`availabilities/${id}`);

    return [() => getAvailability().then(({ data: eopInfo }) => eopInfo), abort];
  }

  getAvailabilityList(criteria?: IAvailabilityListCriteria): ApiIntent<AvailabilityListElementDto[]> {
    const [ getAvailabilityList, abort ] = this.createGetIntent<AvailabilityListDto>('availabilities', criteria);

    return [
      () => getAvailabilityList().then(({ data }) => {
        return data;
      }),
      abort,
    ];
  }

  createAvailability(id: string, payload: any): ApiIntent<string> {
    const [ createAvailability, abort ] = this.createPostIntent<{ id: string; }>(`availabilities/${id}`, payload);

    return [
      () => createAvailability().then(({ data: { id } }) => id),
      abort,
    ];
  }

  updateAvailability(id: string, payload: AvailabilityUpdateDto):ApiIntent<boolean> {
    const [ updateAvailability, abort ] = this.createPutIntent<{}>(`availabilities/${id}`, payload);

    return [
      () => updateAvailability().then(() => true),
      abort,
    ];
  }
}
