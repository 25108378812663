import { useCallback, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { FormFileInfo } from 'src/models/FileInfo';

import {
  Form,
  Input,
  SubmitButton } from 'formik-antd';
import {
  Space,
  Upload,
  Alert,
  Button,
  Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Toggle from '@k12-frontend/common/components/display/Toggle';

import { file as FileHttpService } from 'src/services';

import { UploadFormFields } from '../enums';
import { UploadTimetableFormValues } from '../types';
import { RcFile, UploadFile } from 'antd/lib/upload';
import { MB } from 'src/constants';


const TimetableUploadForm = (props: ITimetableUploadFormProps) => {
  const {
    status,
    onCancel,
    editableFields,
    setActiveTab,
  } = props;

  const {
    values,
    setFieldValue,
    setValues,
    resetForm,
    initialValues
  } = useFormikContext<UploadTimetableFormValues>();

  const { current: validateUploadedFile } = useRef((file: RcFile) => file.size <= 50 * MB);

  const { current: onUploadChange } = useRef(({ file }: { file: UploadFile }) => {
    file.status = 'done';
    setFieldValue(UploadFormFields.File, FormFileInfo.fromFormFile(file));
  });


  const { current: downloadFile } = useRef((file: UploadFile) => {
    if (!file.url) {
      return;
    }

    FileHttpService.downloadUrl(file.url, file.name);
  });

  const changeTab = useCallback(() => {
    if (typeof setActiveTab === 'function') {
      setActiveTab('generateSchedule');
    }
  }, [ setActiveTab ]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Form style={{maxWidth: '400px'}}>
      <p>
        Please download the <Typography.Link href={'/input.xlsx'} target='_blank'>template</Typography.Link> fill it out, and upload it to provide more details about schools, therapists, and students, allowing us to create a
        more accurate schedule.
      </p>

      <p>
        Or use the <Typography.Link onClick={changeTab}>form</Typography.Link>.
      </p>

      <Form.Item name={UploadFormFields.Name} hasFeedback={false}>
        <Input name={UploadFormFields.Name} placeholder="Timetable Name*" disabled={!editableFields.has(UploadFormFields.Name)} />
      </Form.Item>

      <Form.Item
        name={UploadFormFields.File}
        hasFeedback={false}
      >
        <Upload
          fileList={undefined}
          maxCount={1}
          listType="text"
          customRequest={() => void(0)}
          beforeUpload={validateUploadedFile}
          onChange={onUploadChange}
          onDownload={downloadFile}
          onPreview={downloadFile}
          accept={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
          showUploadList={{
            showRemoveIcon: false,
            showPreviewIcon: false,
          }}
        >
          <Button>
            <UploadOutlined />
            Upload File
          </Button>
        </Upload>
      </Form.Item>

      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Toggle show={status.success}>
          <Alert
            type="success"
            message={'Data uploaded!'}
            closable
          />
        </Toggle>

        <Toggle show={!!status.failure}>
          <Alert type="error" message={(status as AjaxFailureStatus).failure.message} closable />
        </Toggle>

        <Space style={{ marginTop: '16px' }}>
          <SubmitButton loading={status.request}>
            Generate a Schedule
          </SubmitButton>

          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default TimetableUploadForm;


/* HELPERS */

interface ITimetableUploadFormProps extends ICommonProps {
  status: IAjaxStatus;
  editableFields: Set<UploadFormFields>;
  onCancel?(): void;
  setActiveTab?(key: string): void;
}
