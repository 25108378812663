import { FormFields }    from './enums';
import { DistrictFormValues } from './types';


export function prepareCreateFormData(values: DistrictFormValues) {
  const formData = new FormData();

  formData.set('name', values[FormFields.Name]);

  return {
    name: values[FormFields.Name],
  };
}

export function prepareUpdateFormData(values: DistrictFormValues) {
  const formData = new FormData();

  formData.set('name', values[FormFields.Name]);


  return {
    name: values[FormFields.Name],
  };
}
