import { useMemo }          from 'react';
import { useLocation }      from 'react-router-dom';

import qs, { ParseOptions } from 'query-string';


export const useSearchVariables = (options: ParseOptions = { parseBooleans: true, parseNumbers: true }) => {
  const location = useLocation();

  return useMemo(() => qs.parse(location.search, options), [ location.search, options ]);
};