import React, { useEffect } from 'react';
import { List, RecordOf } from 'immutable';
import { useMemo } from 'react';
import {
  useRequestKey,
  useListState,
  useFilters,
  usePagination,
  useAjaxStatus,
  useDebouncedValue
  } from 'src/hooks';

import { student as StudentService } from 'src/services';

import { createCriteria, parseStudentList } from './utils';
import { AjaxStatus } from 'src/constants';
import { StudentListData } from './types';
import { StudentListElement } from '../../models/StudentListElement';
import { StudentsFilters } from '../../constants';
import { ChildrenFunc } from 'src/utils';
import { StudentListFiltersValues } from 'src/features/Student/List/types';



export const StudentListContext = React.createContext<StudentListData>({
  status: AjaxStatus.default(),
  filters: new StudentsFilters(),
  pagination: {
    page: 0,
    pageSize: 0,
  },
  total: 0,
  collection: List<StudentListElement>(),
  changePage: () => void(0),
  resetFilters: () => void(0),
  changeFilterValues: () => void(0),
});


export const StudentListProvider = (props: IStudentListProviderProps) => {
  const { children, initialFilters = new StudentsFilters() } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const {
    filters,
    resetFilters,
    changeFilterValues
  } = useFilters<StudentListFiltersValues>(initialFilters);

  const { pagination, changePage } = usePagination(1, 10);

  const {
    total,
    collection,
    setCollectionState
  } = useListState<StudentListElement>();

  const { engage, isCurrentRequest } = useRequestKey();

  const filtersValue = useDebouncedValue(filters, 300);

  useEffect(() => {
    const [ loadList, abort ] = StudentService.getStudentList(createCriteria(filtersValue, pagination));
    const key = engage();

    setRequest();

    loadList()
      .then(({ count, items }) => {

        setCollectionState({
          total: count!,
          collection: parseStudentList(items!),
        });
        setSuccess();
      })
      .catch(err => {
        isCurrentRequest(key) && setFailure(err);
      });

    return () => abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ filtersValue, pagination ]);

  const value = useMemo(() => ({
    status,
    total,
    filters,
    pagination,
    collection,
    changePage,
    resetFilters,
    changeFilterValues,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    status,
    total,
    filters,
    pagination,
    collection,
    changePage,
    resetFilters,
    changeFilterValues,
  ]);

  return (
    <StudentListContext.Provider value={value}>
      { typeof children === 'function' ? children(value) : children }
    </StudentListContext.Provider>
  );
};


/* HELPERS */

interface IStudentListProviderProps {
  initialFilters?: RecordOf<StudentListFiltersValues>;
  children?: React.ReactNode | ChildrenFunc<StudentListData>;
}
