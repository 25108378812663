import { RecordOf }        from 'immutable';
import { SortOrder }       from 'src/enums';
import { SortRecord }      from 'src/hooks';

import type { TableProps } from 'antd/es/table';


export function getSortableColumnProps(sortField: string, sort: RecordOf<SortRecord<string>>) {
  return {
    sorter: true,
    showSorterTooltip: false,
    // dirty hack to prevent `unsorted` behavior
    sortDirections: [
      'ascend',
      'descend',
      'ascend',
    ] as ('ascend' | 'descend')[],
    sortOrder: getAntSortOrder(sortField, sort),
  };
}

export function getAntSortOrder(sortField: string, sort: RecordOf<SortRecord<string>>): 'ascend' | 'descend' | null {
  return sort.get('field') === sortField
    ? convertAntSortOrder.fromApp(sort.get('order'))
    : null;
}

export function createTableChangeHandler<TableData>({ onSort, onPageChange }: TableChangeCallbacks): TableProps<TableData>['onChange'] {
  return (pagination, filters, sorter, extra) => {
    const { action } = extra;

    if (action === 'sort' && !Array.isArray(sorter)) {
      const { field, order } = sorter;

      if (typeof onSort === 'function' && field && order) {
        return onSort(field as string, convertAntSortOrder.toApp(order));
      }
    } else if (action === 'paginate') {
      const { current = 1, pageSize = 0} = pagination;

      if (typeof onPageChange === 'function') {
        onPageChange(current, pageSize);
      }
    }

    return;
  };
}

type TableChangeCallbacks = {
  onSort?(field: string, order: SortOrder): void;
  onPageChange?(page: number, pageSize: number): void;
};

export const convertAntSortOrder = {
  fromApp: (appSortOrder: SortOrder) => appSortOrder === SortOrder.Asc ? 'ascend' : 'descend',
  toApp: (order: 'ascend' | 'descend') => order === 'ascend' ? SortOrder.Asc : SortOrder.Desc,
};