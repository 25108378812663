import React from 'react';
import { useContext } from 'react';
import { useSchoolDetails } from '../hooks';

import { AjaxStatus } from 'src/constants';
import { SchoolDetails } from '../models/SchoolDetails';
import { ChildrenFunc } from '@k12-frontend/utils';


export const SchoolDetailsContext = React.createContext<SchoolDetailsContextValue>({
  status: AjaxStatus.default(),
  schoolDetails: null,
});
SchoolDetailsContext.displayName = 'SchoolDetailsContext';


export const useSchoolDetailsContext = () => useContext(SchoolDetailsContext);

export const SchoolDetailsProvider = (props: ISchoolDetailsProviderProps) => {
  const { id, children } = props;

  const value = useSchoolDetails(id);

  return (
    <SchoolDetailsContext.Provider value={value}>
      { typeof children === 'function' ? children(value) : children }
    </SchoolDetailsContext.Provider>
  );
};

/* HELPERS */

interface ISchoolDetailsProviderProps {
  id: string;
  children?: React.ReactNode | ChildrenFunc<SchoolDetailsContextValue>;
}

export type SchoolDetailsContextValue = {
  schoolDetails: Nullable<SchoolDetails>;
  status: IAjaxStatus;
}
