import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
const Toggle = (props) => {
    const { children, tag: Tag, className, style, show, hide } = props;
    const definedProps = Tag === React.Fragment
        ? {}
        : { className, style };
    if (!show || hide) {
        return null;
    }
    return (_jsx(Tag, Object.assign({}, definedProps, { children: children })));
};
Toggle.defaultProps = {
    tag: React.Fragment,
    show: true,
};
export default Toggle;
