import React, { useEffect } from 'react';
import { List } from 'immutable';
import { useMemo } from 'react';
import {
  useRequestKey,
  useListState,
  useAjaxStatus,
  } from 'src/hooks';

import { session as SessionService } from 'src/services';

import { parseSessionList } from './utils';
import { AjaxStatus } from 'src/constants';
import { SessionListData } from './types';
import { SessionListElement } from '../../models/SessionListElement';


export const SessionListContext = React.createContext<SessionListData>({
  status: AjaxStatus.default(),
  total: 0,
  collection: List<SessionListElement>(),
});


export const SessionListProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();


  const {
    total,
    collection,
    setCollectionState
  } = useListState<SessionListElement>();

  const { engage, isCurrentRequest } = useRequestKey();

  useEffect(() => {
    const [ loadList, abort ] = SessionService.getSessionList();
    const key = engage();

    setRequest();

    loadList()
      .then(({ count, items }) => {

        setCollectionState({
          total: count!,
          collection: parseSessionList(items!),
        });
        setSuccess();
      })
      .catch(err => {
        isCurrentRequest(key) && setFailure(err);
      });

    return () => abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => ({
    status,
    total,
    collection,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    status,
    total,
    collection,
  ]);

  return (
    <SessionListContext.Provider value={value}>
      { children }
    </SessionListContext.Provider>
  );
};

