import {
  TimetableDetailsDto, TimetableDetailsSchoolDto, TimetableDetailsStudentDto, TimetableDetailsTherapistDto
} from 'src/services/api/timetable/timetable.dto';


export class TimetableDetails {
  private constructor(
    readonly id: string,
    readonly name: string,
    readonly status: string,
    readonly startDate: string,
    readonly endDate: string,
    readonly schools: TimetableDetailsSchoolDto[],
    readonly students: TimetableDetailsStudentDto[],
    readonly therapists: TimetableDetailsTherapistDto[],
    readonly error: string | null,
  ) {}

  static fromJS(data: TimetableDetailsDto) {

    return new TimetableDetails(
      data.id,
      data.name,
      data.status,
      data.startDate,
      data.endDate,
      data.schools,
      data.students,
      data.therapists,
      data.error,
    );
  }
}



