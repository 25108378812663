import { useFilterHandlers } from '../hooks';
import { useTimetableListContext } from './context';

import TimetableListFilters from '../components/Filters';


const TimetableListFiltersContainer = () => {
  const {
    changeOrganizationFilter,
    searchByName,
  } = useFilterHandlers();

  const { filters } = useTimetableListContext();

  return (
    <TimetableListFilters
      filterValues={filters}
      onFilterByOrganization={changeOrganizationFilter}
      onSearchByName={searchByName}
    />
  );
};

export default TimetableListFiltersContainer;
