import React from 'react';
import { useContext } from 'react';
import { useStudentDetails } from '../hooks';

import { AjaxStatus } from 'src/constants';
import { StudentDetails } from '../models/StudentDetails';
import { ChildrenFunc } from 'src/utils';


export const StudentDetailsContext = React.createContext<StudentDetailsContextValue>({
  status: AjaxStatus.default(),
  studentDetails: null,
});
StudentDetailsContext.displayName = 'StudentDetailsContext';


export const useStudentDetailsContext = () => useContext(StudentDetailsContext);

export const StudentDetailsProvider = (props: IStudentDetailsProviderProps) => {
  const { id, children } = props;

  const value = useStudentDetails(id);

  return (
    <StudentDetailsContext.Provider value={value}>
      { typeof children === 'function' ? children(value) : children }
    </StudentDetailsContext.Provider>
  );
};

/* HELPERS */

interface IStudentDetailsProviderProps {
  id: string;
  children?: React.ReactNode | ChildrenFunc<StudentDetailsContextValue>;
}

export type StudentDetailsContextValue = {
  studentDetails: Nullable<StudentDetails>;
  status: IAjaxStatus;
}
