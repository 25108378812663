import { useMemo }           from 'react';

import * as Yup              from 'yup';

import { FormFields, UploadFormFields }        from '../enums';
import { OrganizationType }                    from 'src/enums';


export const useValidationSchema = () => useMemo(() => Yup.object({
  [FormFields.Name]: Yup.string().required('Name is required'),
  [FormFields.StartDate]: Yup.string().required('Start Date is required'),
  [FormFields.EndDate]: Yup.string().required('End Date is required'),
  [FormFields.Organization]: Yup.array().of(Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
    type: Yup.string(),
  }).nullable().test({
    name: 'SchoolRequired',
    test: (value) => value?.type === OrganizationType.School,
    message: 'School required',
    exclusive: true,
  })),
}), [ ]);


export const useUploadValidationSchema = () => useMemo(() => Yup.object({
  [UploadFormFields.Name]: Yup.string().required('Name is required'),
  [UploadFormFields.File]: Yup.object().required('File is required'),
}), [ ]);
