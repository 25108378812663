import { useCallback, useMemo } from 'react';
import { useAjaxStatus } from 'src/hooks';
import { useValidationSchema } from '../hooks';

import { Formik } from 'formik';
import Form from '../components/Form';

import { student as StudentService } from 'src/services';

import { prepareCreateFormData } from '../utils';
import { FormFields } from '../enums';
import { CreatePrescriptionFormValues } from '../types';
import { ApiError } from 'src/modules/ErrorContext/models';
import { useStudentListContext } from 'src/features/Student/List/containers/context';
import { useSessionListContext } from 'src/features/Session/List/containers/context';


const PrescriptionCreateFormContainer = (props: IPrescriptionCreateFormContainerProps) => {
  const { onCancel, onCreated } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const validationSchema = useValidationSchema();

  const { collection: sessionCollection } = useSessionListContext();
  const sessionsOptions = useMemo(() => sessionCollection.reduce((arr, element) => {
    const { id, name } = element;

    arr.push({ value: id, label: name });

    return arr;
  }, [] as IOption[]), [
    sessionCollection,
  ]);

  const { collection: studentsCollection } = useStudentListContext();
  const studentsOptions = useMemo(() => studentsCollection.reduce((arr, element) => {
    const { id, firstName, lastName } = element;

    arr.push({ value: id, label: `${firstName} ${lastName}` });

    return arr;
  }, [] as IOption[]), [
    studentsCollection,
  ]);

  const createPrescription = useCallback(async (values: CreatePrescriptionFormValues) => {
    setRequest();

    try {
      const [ createPrescription ] = StudentService.createStudentPrescription(
        values[FormFields.StudentId],
        prepareCreateFormData(values)
      );

      const id = await createPrescription();

      setSuccess();

      if (typeof onCreated === 'function') {
        onCreated({
          id,
          name: ``,
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onCreated ]);


  return (
    <Formik
      initialValues={{
        [FormFields.StudentId]: '',
        [FormFields.Prescriptions]: [],
      }}
      validationSchema={validationSchema}
      onSubmit={createPrescription}
    >
      <Form
        status={status}
        sessionsOptions={sessionsOptions}
        studentsOptions={studentsOptions}
        onCancel={onCancel}
      />
    </Formik>
  );
};

export default PrescriptionCreateFormContainer;


/* HELPERS */

interface IPrescriptionCreateFormContainerProps extends ICommonProps {
  onCancel?(): void;
  onCreated?(schoolEntity: IEntity): void;
}
