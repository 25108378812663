export const enum FilterFields {
  Name = 'name',
  Organization = 'organization',
}

export const enum SortFields {
  Name = 'name',
  ModificationDate = 'modifiedAt',
}

export const enum TableColumns {
  Name = 'name',
  District = 'district',
  Actions = 'actions'
}
