import { useNavigate, useParams, Navigate } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Result,
  Row,
  Spin,
  Typography }                    from 'antd';
import DoubleLeftOutlined         from '@ant-design/icons/DoubleLeftOutlined';
import { TherapistDetailsProvider }  from 'src/features/Therapist/containers/DetailsProvider';
import { SkillListProvider }       from 'src/features/Skill/List/containers/context';
import TherapistEditForm           from 'src/features/Therapist/Forms/containers/EditForm';

import Toggle                     from 'src/components/Toggle';

import urls                       from 'src/urls';




const TherapistEditPage = () => {
  const { therapist_id: therapistId } = useParams();
  const navigate = useNavigate();

  if (!therapistId) {
    return <Navigate to={urls.index()} />;
  }

  return (
    <TherapistDetailsProvider id={therapistId}>
      {
        ({ status }) => {
          const cardButtons = [
            <Toggle key={'back-to-list-btn'} show={status.success}>
              <Button
                type="link"
                icon={<DoubleLeftOutlined />}
                onClick={() => navigate(urls.therapist())}
              >
                Back to Therapists
              </Button>
            </Toggle>
          ];

          return (
            <Card
              title={<Typography.Title level={2}>Edit Therapist</Typography.Title>}
              extra={cardButtons}
            >
              <Toggle show={status.request} tag="div" style={{ textAlign: 'center' }}>
                <Spin tip="Loading Therapist data..." />
              </Toggle>

              <Toggle show={!!status.failure}>
                <Result
                  status="error"
                  title={(status as AjaxFailureStatus).failure.message}
                />
              </Toggle>

              <Toggle show={status.success}>
                <Row>
                  <Col xl={12} lg={16} md={24}>
                    <SkillListProvider>
                      <TherapistEditForm onCancel={() => navigate(urls.therapist())} />
                    </SkillListProvider>
                  </Col>
                </Row>
              </Toggle>
            </Card>
          );
        }
      }
    </TherapistDetailsProvider>
  );
};

export default TherapistEditPage;
