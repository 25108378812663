import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Typography,
  notification } from 'antd';

import { useSearchVariables } from 'src/hooks/useUrlUtils';
import { SchoolDetailsProvider } from 'src/features/School/containers/DetailsProvider';
import { SkillListProvider } from 'src/features/Skill/List/containers/context';
import CreateForm from 'src/features/Therapist/Forms/containers/CreateForm';
import Toggle from 'src/components/Toggle';

import urls from 'src/urls';


const TherapistCreatePage = () => {
  const { school_id: schoolId } = useSearchVariables();

  const navigate = useNavigate();

  const redirectToTherapistList = useCallback((therapistEntity: IEntity) => {
    notification.success({
      message: <Typography.Text strong>Therapist has been created!</Typography.Text>,
      description: (
        <Typography.Text>
          Therapist <Typography.Text strong>{ therapistEntity.name }</Typography.Text> was created
        </Typography.Text>
      ),
      duration: null,
    });

    navigate(urls.therapist());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!schoolId) {
    return (
      <Card title={<Typography.Title level={2}>Create Therapist</Typography.Title>}>
        <Row>
          <Col xl={12} lg={16} md={24}>
            <SkillListProvider>
              <CreateForm onCreated={redirectToTherapistList} onCancel={() => navigate(urls.therapist())}/>
            </SkillListProvider>
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card title={<Typography.Title level={2}>Create Therapist</Typography.Title>}>
      <Row>
        <Col xl={12} lg={16} md={24}>
          <SkillListProvider>
          <SchoolDetailsProvider id={schoolId as string}>
            {
              ({ status, schoolDetails }) => (
                <Toggle show={status.success}>
                  <CreateForm onCreated={redirectToTherapistList} onCancel={() => navigate(urls.therapist())} schoolDetails={schoolDetails} />
                </Toggle>
              )
            }
          </SchoolDetailsProvider>
          </SkillListProvider>
        </Col>
      </Row>
    </Card>
  )
};

export default TherapistCreatePage;
