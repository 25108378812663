import { useCallback, useMemo }  from 'react';
import { useAjaxStatus } from 'src/hooks';
import { useValidationSchema } from '../hooks';
import { useDistrictDetailsContext } from '../../containers/DetailsProvider';

import { Formik } from 'formik';
import Form from '../components/Form';

import { district as DistrictService } from 'src/services';

import { prepareUpdateFormData } from '../utils';
import { FormFields } from '../enums';
import { DistrictFormValues } from '../types';
import { ApiError } from 'src/modules/ErrorContext/models';


const DistrictEditFormContainer = (props: IDistrictEditFormContainerProps) => {
  const { onCancel, onUpdated } = props;

  const { districtDetails } = useDistrictDetailsContext();

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const updateDistrict = useCallback(async (values: DistrictFormValues) => {
    setRequest();

    try {
      const id = values[FormFields.Id]!;
      const [ updateDistrict ] = DistrictService.updateDistrict(id, prepareUpdateFormData(values));

      await updateDistrict();

      setSuccess();

      if (typeof onUpdated === 'function') {
        onUpdated({
          id,
          name: values[FormFields.Name],
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onUpdated ]);

  const handleSubmit = useCallback((values: DistrictFormValues) => updateDistrict(values), [ updateDistrict ]);

  const validationSchema = useValidationSchema();
  const editableFields = new Set([
    FormFields.Name,
  ]);

  const initialValues = useMemo<DistrictFormValues>(() => {
    if (!districtDetails) {
      return {
        [FormFields.Id]: null,
        [FormFields.Name]: '',
      };
    }

    const {
      id,
      name,
    } = districtDetails;

    return {
      [FormFields.Id]: id,
      [FormFields.Name]: name,
    };
  }, [ districtDetails ]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {
        () => (
          <>
            <Form
              status={status}
              editableFields={editableFields}
              onCancel={onCancel}
            />
          </>
        )
      }

    </Formik>
  );
};

export default DistrictEditFormContainer;


/* HELPERS */

interface IDistrictEditFormContainerProps extends ICommonProps {
  onCancel?(): void;
  onUpdated?(districtEntity: IEntity): void;
}
