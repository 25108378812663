export const enum FilterFields {
  Name = 'name',
  Organization = 'organization',
  Signed = 'signed',
}

export const enum SortFields {
  Name = 'name',
  ModificationDate = 'modifiedAt',
}

export const enum TableColumns {
  Name = 'name',
  Description = 'description',
  Duration = 'duration',
  Need = 'need',
  Group = 'group',
  Online = 'online',
  PreparingTime = 'preparingTime',
  FinishingTime = 'finishingTime',
  SchoolId = 'schoolId',
}
