import React from 'react';
import { useMemo } from 'react';
import { useDistrictListContext } from './context';

import { Link }              from 'react-router-dom';

import DistrictList          from '../components/DistrictList';

import type { ColumnsType }  from 'antd/es/table';
import {
  TableColumns }             from '../enums';

import urls                  from 'src/urls';


const DistrictListContainer = () => {
  const {
    status,
    collection,
    total,
    pagination: { page, pageSize },
    changePage,
  } = useDistrictListContext();

  const tableData = useMemo(() => collection.reduce((arr, element) => {
    const { id, name } = element;

    arr.push({
      key: id,
      [TableColumns.Name]: name,
      [TableColumns.Actions]: id,
    });

    return arr;
  }, [] as IDistrictListElementData[]), [
    collection,
  ]);

  const tableColumns: ColumnsType<IDistrictListElementData> = useMemo(() => ([
    {
      key: TableColumns.Name,
      title: 'District Name',
      dataIndex: TableColumns.Name,
      render: (value: string, record: IDistrictListElementData) => <Link to={urls.districtDetails(record.key)}>{ value }</Link>
    },
    {
      key: TableColumns.Actions,
      title: 'Actions',
      dataIndex: TableColumns.Actions,
      render: (value: string, record: IDistrictListElementData) => value ? <div>
        <Link to={urls.districtDetails(record.key)} style={{ marginRight: '14px' }}>View</Link>
        {' '}
        <Link to={urls.districtEdit(value)}>Edit</Link>
      </div> : <>&mdash;</>
    }
  ].filter(Boolean) as ColumnsType<IDistrictListElementData>), []);

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
  }), [ page, total, pageSize ]);


  return (
    <DistrictList
      tableColumns={tableColumns}
      tableData={tableData}
      pagination={pagination}
      changePage={changePage}
      loading={status.request}
    />
  );
};

export default DistrictListContainer;


export interface IDistrictListElementData {
  key: React.Key;
  [TableColumns.Name]: string;
  [TableColumns.Actions]: string;
}
