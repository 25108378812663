import { SyntheticEvent, useCallback } from 'react';

import { FilterFields }                from '../enums';
import { useDistrictListContext }      from '../containers/context';


export const useFilterHandlers = () => {
  const { changeFilterValues } = useDistrictListContext();

  const searchByName = useCallback((e: SyntheticEvent<HTMLInputElement>) => changeFilterValues({ [FilterFields.Name]: e.currentTarget.value }), [ changeFilterValues ]);

  return {
    searchByName,
  };
};
