import { Route, Routes, Navigate } from 'react-router-dom';

import ApplicationLayout       from 'src/components/ApplicationLayout';
// import DashboardPage           from 'src/pages/Dashboard';
import DistrictPage            from 'src/pages/District/DistrictList';
import DistrictCreate          from 'src/pages/District/DistrictCreate';
import DistrictDetailsPage     from 'src/pages/District/DistrictDetails';
import DistrictEditPage        from 'src/pages/District/DistrictEdit';
import SchoolPage              from 'src/pages/School/SchoolList';
import SchoolCreate            from 'src/pages/School/SchoolCreate';
import SchoolDetailsPage       from 'src/pages/School/SchoolDetails';
import SchoolEditPage          from 'src/pages/School/SchoolEdit';

import StudentPage              from 'src/pages/Student/StudentList';
import StudentCreatePage        from 'src/pages/Student/StudentCreate';
import StudentDetailsPage       from 'src/pages/Student/StudentDetails';
import StudentEditPage          from 'src/pages/Student/StudentEdit';
import StudentPrescriptionCreate   from 'src/pages/Prescription/PrescriptionCreate';
import StudentPrescriptionDetails   from 'src/pages/Prescription/PrescriptionDetails';

import TherapistPage              from 'src/pages/Therapist/TherapistList';
import TherapistCreate            from 'src/pages/Therapist/TherapistCreate';
import TherapistDetailsPage       from 'src/pages/Therapist/TherapistDetails';
import TherapistEditPage          from 'src/pages/Therapist/TherapistEdit';

import SessionPage                 from 'src/pages/Session/SessionList';
import SessionCreate               from 'src/pages/Session/SessionCreate';
// import SessionDetailsPage       from 'src/pages/Session/SessionDetails';
// import SessionEditPage          from 'src/pages/Session/SessionEdit';

// import AvailabilityCreate      from 'src/pages/Availability/AvailabilityCreate';
// import AvailabilityDetailsPage from 'src/pages/Availability/AvailabilityDetails';
// import AvailabilityEditPage    from 'src/pages/Availability/AvailabilityEdit';

import SchedulePage               from 'src/pages/Schedule/Schedule';
import ScheduleDetails            from 'src/pages/Schedule/ScheduleDetails';
import ScheduleCalendar           from 'src/pages/Schedule/ScheduleCalendar';

import TimetablePage              from 'src/pages/Timetable/TimetableList';
import TimetableCreate            from 'src/pages/Timetable/TimetableCreate';
import TimetableDetails           from 'src/pages/Timetable/TimetableDetails';
import TimetableCalendar          from 'src/pages/Timetable/TimetableCalendar';
import TimetableUpload            from 'src/pages/Timetable/TimetableUpload';

import urls                       from 'src/urls';

// import PrescriptionDetails from 'src/pages/Prescription/PrescriptionDetails';



const AuthorizedRoutes = () => {
  return (
    <Routes>
      <Route path={urls.index()} element={<ApplicationLayout />}>
        <Route element={<Navigate to={urls.school()} />} index />

        {/*<Route path={urls.dashboard()} element={<DashboardPage />} />*/}
        <Route path={urls.district()} element={<DistrictPage />} />
        <Route path={urls.districtCreate()} element={<DistrictCreate />} />
        <Route path={urls.districtDetails(':district_id')} element={<DistrictDetailsPage />} />
        <Route path={urls.districtEdit(':district_id')} element={<DistrictEditPage />} />
        <Route path={urls.school()} element={<SchoolPage />} />
        <Route path={urls.schoolCreate()} element={<SchoolCreate />} />
        <Route path={urls.schoolDetails(':school_id')} element={<SchoolDetailsPage />} />
        <Route path={urls.schoolEdit(':school_id')} element={<SchoolEditPage />} />
        <Route path={urls.therapist()} element={<TherapistPage />} />
        <Route path={urls.therapistCreate()} element={<TherapistCreate />} />
        <Route path={urls.therapistEdit(':therapist_id')} element={<TherapistEditPage />} />
        <Route path={urls.therapistDetails(':therapist_id')} element={<TherapistDetailsPage />} />
        <Route path={urls.session()} element={<SessionPage />} />
        <Route path={urls.sessionCreate()} element={<SessionCreate />} />
        <Route path={urls.student()} element={<StudentPage />} />
        <Route path={urls.studentCreate()} element={<StudentCreatePage />} />
        <Route path={urls.studentEdit(':student_id')} element={<StudentEditPage />} />
        <Route path={urls.studentDetails(':student_id')} element={<StudentDetailsPage />} />
        <Route path={urls.studentPrescriptions(':prescription_id')} element={<StudentPrescriptionDetails />} />
        <Route path={urls.schedule()} element={<SchedulePage />} />
        <Route path={urls.scheduleDetails(':timetable_id')} element={<ScheduleDetails />} />
        <Route path={urls.scheduleCalendar(':timetable_id')} element={<ScheduleCalendar />} />
        <Route path={urls.timetable()} element={<TimetablePage />} />
        <Route path={urls.timetableCreate()} element={<TimetableCreate />} />
        <Route path={urls.timetableDetails(':timetable_id')} element={<TimetableDetails />} />
        <Route path={urls.timetableCalendar(':timetable_id')} element={<TimetableCalendar />} />
        <Route path={urls.timetableUpload()} element={<TimetableUpload />} />
        <Route path={urls.prescriptionCreate()} element={<StudentPrescriptionCreate />} />

        {/*StudentPrescriptionDetails*/}
      </Route>

      <Route path="*" element={<Navigate to={urls.index()} replace />} />
    </Routes>
  );
};

export default AuthorizedRoutes;
