import {
  Navigate,
  useParams,
  useNavigate } from 'react-router-dom';
import {
  Card,
  Space,
  Spin,
  Result,
  Typography,
  Button } from 'antd';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { TherapistDetailsProvider } from 'src/features/Therapist/containers/DetailsProvider';
import { SkillListProvider } from 'src/features/Skill/List/containers/context';
import TherapistInfo from 'src/features/Therapist/Details/components/TherapistInfo';
import Toggle from 'src/components/Toggle';

import urls from 'src/urls';



const TherapistDetailsPage = () => {
  const { therapist_id: therapistId } = useParams();
  const navigate = useNavigate();

  if (!therapistId) {
    return <Navigate to={urls.index()} />;
  }

  return (
    <TherapistDetailsProvider id={therapistId}>
      {
        ({ status, therapistDetails }) => {

          const cardButtons = (
            <Space size="middle">
              <Toggle>
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => navigate(urls.therapistEdit(therapistId))}
                >
                  Edit
                </Button>
              </Toggle>
            </Space>
          );

          return (
            <Card
              title={<Typography.Title level={2}>Therapist Details</Typography.Title>}
              extra={cardButtons}
            >
              <Toggle show={status.request} tag="div" style={{ textAlign: 'center' }}>
                <Spin tip="Loading Therapist data..." />
              </Toggle>

              <Toggle show={!!status.failure}>
                <Result
                  status="error"
                  title={(status as AjaxFailureStatus).failure.message}
                />
              </Toggle>

              <Toggle show={status.success}>
                <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                  <SkillListProvider>
                    <TherapistInfo
                      details={therapistDetails!}
                    />
                  </SkillListProvider>
                </Space>
              </Toggle>
            </Card>
          );
        }
      }
    </TherapistDetailsProvider>
  );
};

export default TherapistDetailsPage;
