import React from 'react';
import { useContext } from 'react';
import { usePrescriptionDetails } from '../hooks';

import { AjaxStatus } from 'src/constants';
import { PrescriptionDetails } from '../models/PrescriptionDetails';
import { ChildrenFunc } from '@k12-frontend/utils';


export const PrescriptionsContext = React.createContext<PrescriptionsContextValue>({
  status: AjaxStatus.default(),
  prescriptions: null,
});
PrescriptionsContext.displayName = 'PrescriptionsContext';


export const usePrescriptionsContext = () => useContext(PrescriptionsContext);

export const PrescriptionsProvider = (props: IPrescriptionDetailsProviderProps) => {
  const { id, children } = props;

  const value = usePrescriptionDetails(id);

  return (
    <PrescriptionsContext.Provider value={value}>
      { typeof children === 'function' ? children(value) : children }
    </PrescriptionsContext.Provider>
  );
};

/* HELPERS */

interface IPrescriptionDetailsProviderProps {
  id: string;
  children?: React.ReactNode | ChildrenFunc<PrescriptionsContextValue>;
}

export type PrescriptionsContextValue = {
  prescriptions: Nullable<PrescriptionDetails[]>;
  status: IAjaxStatus;
}
