import { List, RecordOf } from 'immutable';

import { TherapistListElementDto } from 'src/services/api/therapist/therapist.dto';
import { TherapistListElement } from '../../models/TherapistListElement';
import { ITherapistListCriteria } from 'src/services/api/therapist/types';
import { FilterFields } from 'src/features/Therapist/List/enums';
import { TherapistListFiltersValues } from 'src/features/Therapist/List/types';
import { OrganizationType } from 'src/enums';


export function createCriteria(filters: RecordOf<TherapistListFiltersValues>, pagination: IPagination): ITherapistListCriteria {
  const organization = filters.get(FilterFields.Organization);
  const keyword = filters.get(FilterFields.Name);

  const organizationCriteria = organization?.type === OrganizationType.District ? 'districtId' : 'schoolId';

  return {
    page: pagination.page - 1,
    limit: pagination.pageSize,
    [organizationCriteria]: organization?.id ?? undefined,
    keyword: keyword ?? undefined,
    // sort: [ convertToQueryParam(sort) ],
  };
}


export function parseTherapistList(items: TherapistListElementDto[]): List<TherapistListElement> {
  return List(items.map(item => {
    const {
      id,
      firstName,
      lastName,
      pauseBetweenSessions,
      skills,
      schools,
      utilization,
      timetableId,
    } = item;

    // const rootOrgIsDistrict = organization.type === OrganizationType.District;

    return new TherapistListElement(
      id,
      firstName,
      lastName,
      pauseBetweenSessions,
      skills,
      schools,
      utilization,
      timetableId,
    );
  }));
}
