import { Record }                from 'immutable';
// import { SortRecord }           from 'src/hooks';

import {
  // SortFields,
  FilterFields }                from './enums';
import { SchoolsListFilters }   from './types';
// import { SortOrder }            from 'src/enums';


export const SchoolsFilters = Record<SchoolsListFilters>({
  [FilterFields.Name]: '',
  [FilterFields.Organization]: null,
});

// export const SchoolsSort = Immutable.Record<SortRecord<SortFields>>({
//   field: SortFields.Status,
//   order: SortOrder.Asc,
// });
