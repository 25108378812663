import { IStorage } from './types';


export class LocalStorage implements IStorage {

  setItem(key: string, value: unknown) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem<T = unknown>(key: string): T {
    const value = localStorage.getItem(key) || '';

    try {
      return JSON.parse(value) as T;
    } catch {
      return value as T;
    }
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }
}