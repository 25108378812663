import { useCallback }                  from 'react';
import { useSchoolField }               from '../hooks';
import { useOrganizationSearchContext } from '../Provider';

import OrganizationSearch               from './SearchField';

import { SelectProps }                  from 'antd';
import { IOrganizationOption }          from '../types';
import { OrganizationType }             from 'src/enums';


const SchoolSearch = (props: ISchoolSearchProps) => {
  const { onChange } = props;

  const { districtValue } = useOrganizationSearchContext();
  const { value, changeFieldValue } = useSchoolField();

  const changeSchoolValue = useCallback((value: any, option: IOrganizationOption | IOrganizationOption[]) => {
    if (!Array.isArray(option)) {
      changeFieldValue(option);
    }

    if (typeof onChange === 'function') {
      onChange(value, option);
    }
  }, [ onChange, changeFieldValue ]);

  return (
    <OrganizationSearch
      {...props}
      type={OrganizationType.School}
      value={value}
      parentOrgId={districtValue?.id ?? null}
      onChange={changeSchoolValue}
      placeholder='School'
    />
  );
};

export default SchoolSearch;


/* HELPERS */

interface ISchoolSearchProps extends SelectProps<any, IOrganizationOption>, ICommonProps {
  onChange?: SelectProps['onChange'];
  optionsFilter?(option: IOrganizationOption): boolean;
}
