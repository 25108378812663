import {
  Space,
  Descriptions,
  Row,
  Col } from 'antd';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { SchoolDetails } from 'src/features/School/models/SchoolDetails';
import Toggle from 'src/components/Toggle';

import urls from 'src/urls';


const SchoolInfo = (props: ISchoolInfoProps) => {
  const { details } = props;

  return (
    <section>
      <Row justify={'end'}>
        <Col>
          <Link to={urls.scheduleCalendar(details?.timetableId)}>Latest Generated Schedule</Link>
        </Col>
      </Row>

      <Space direction="vertical" size="large">
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Name">{details?.name}</Descriptions.Item>
          <Descriptions.Item label="District">{details?.organization?.name}</Descriptions.Item>
        </Descriptions>

        <Toggle show={Boolean(details.availability?.length)}>
          <h2>Availability</h2>
          <Descriptions column={1} bordered>
            {details.availability.map((item) => (
              <Descriptions.Item label={item.day}>{`${format(new Date(item.intervals.start ?? ''), 'h:mm a')} - ${format(new Date(item.intervals.end ?? ''), 'h:mm a')}` }</Descriptions.Item>
            ))}
          </Descriptions>
        </Toggle>
      </Space>
    </section>
  );
};

export default SchoolInfo;


/* HELPERS */

interface ISchoolInfoProps extends ICommonProps {
  details: SchoolDetails;
}
