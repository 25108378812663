import AuthorizedRoutes                  from './AuthorizedRoutes';


const ApplicationRoutes = () => {
  return (
        <AuthorizedRoutes />
  );
};

export default ApplicationRoutes;
