import { useRef, useState } from 'react';
export const useModalState = (defaultOpened = false) => {
    const [modalOpened, setModalOpened] = useState(defaultOpened);
    const { current: openModal } = useRef(() => setModalOpened(true));
    const { current: closeModal } = useRef(() => setModalOpened(false));
    return {
        modalOpened,
        openModal,
        closeModal,
    };
};
