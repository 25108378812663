import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Result, Row, Space, Spin, Typography } from 'antd';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { SchoolDetailsProvider } from 'src/features/School/containers/DetailsProvider';
import SchoolInfo from 'src/features/School/Details/components/SchoolInfo';
import Toggle from 'src/components/Toggle';

import { TherapistListProvider } from 'src/features/Therapist/List/containers/context';
import { SkillListProvider } from 'src/features/Skill/List/containers/context';
import { TherapistsFilters } from 'src/features/Therapist/List/constants';
import SchoolTerapistList from 'src/features/Therapist/List/containers/SchoolTerapistList';
import { FilterFields as TherapistFilterFields } from 'src/features/Therapist/List/enums';
import { FilterFields as StudentFilterFields } from 'src/features/Student/List/enums';
import { OrganizationType } from 'src/enums';

import { StudentListProvider } from 'src/features/Student/List/containers/context';
import { StudentsFilters } from 'src/features/Student/List/constants';
import SchoolStudentList from 'src/features/Student/List/containers/SchoolStudentList';

import urls from 'src/urls';


const SchoolDetailsPage = () => {
  const { school_id: schoolId } = useParams();
  const navigate = useNavigate();

  if (!schoolId) {
    return <Navigate to={urls.index()} />;
  }

  return (
    <SchoolDetailsProvider id={schoolId}>
      {
        ({ status, schoolDetails }) => {
          const therapistFilters = new TherapistsFilters();
          const studentFilters = new StudentsFilters();
          const organization = {
            id: schoolDetails?.id || '',
            name: schoolDetails?.name || '',
            type: OrganizationType.School,
            parent: schoolDetails?.organization || null,
          };
          const initialTherapistFilters = therapistFilters.set(TherapistFilterFields.Organization, organization);
          const initialStudentFilters = studentFilters.set(StudentFilterFields.Organization, organization);

          const cardButtons = (
            <Space size="middle">
              <Toggle>
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => navigate(urls.schoolEdit(schoolId))}
                >
                  Edit
                </Button>
              </Toggle>
            </Space>
          );

          return (
            <Card
              title={<Typography.Title level={2}>School Details</Typography.Title>}
              extra={cardButtons}
            >
              <Toggle show={status.request} tag="div" style={{ textAlign: 'center' }}>
                <Spin tip="Loading School data..." />
              </Toggle>

              <Toggle show={!!status.failure}>
                <Result
                  status="error"
                  title={(status as AjaxFailureStatus).failure.message}
                />
              </Toggle>

              <Toggle show={status.success}>
                <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                  <SchoolInfo
                    details={schoolDetails!}
                  />

                  <section style={{ marginBottom: '16px' }}>
                    <Row style={{ width: '100%',  display: 'flex' }}>
                      <Col style={{ flex: '1 1 auto' }} >
                        <h2>List of Therapists</h2>
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          onClick={() => navigate(`${urls.therapistCreate()}?school_id=${schoolDetails?.id}`)}
                        >
                          Add Therapist
                        </Button>
                      </Col>
                    </Row>

                    <TherapistListProvider initialFilters={initialTherapistFilters}>
                      <SkillListProvider>
                        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                          <SchoolTerapistList schoolDetails={schoolDetails} />
                        </Space>
                      </SkillListProvider>
                    </TherapistListProvider>
                  </section>


                  <section style={{ marginBottom: '16px' }}>
                    <Row style={{ width: '100%',  display: 'flex' }}>
                      <Col style={{ flex: '1 1 auto' }} >
                        <h2>List of Students</h2>
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          onClick={() => navigate(`${urls.studentCreate()}?school_id=${schoolDetails?.id}`)}
                        >
                          Add Student
                        </Button>
                      </Col>
                    </Row>

                    <StudentListProvider initialFilters={initialStudentFilters}>
                      <SkillListProvider>
                        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                          <SchoolStudentList />
                        </Space>
                      </SkillListProvider>
                    </StudentListProvider>
                  </section>
                </Space>
              </Toggle>
            </Card>
          );
        }
      }
    </SchoolDetailsProvider>
  );
};

export default SchoolDetailsPage;
