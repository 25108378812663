import {
  Navigate,
  useParams,
  useNavigate } from 'react-router-dom';
import {
  Card,
  Space,
  Spin,
  Result,
  Typography } from 'antd';
import { SessionListProvider } from 'src/features/Session/List/containers/context';
import { TherapistListProvider } from 'src/features/Therapist/List/containers/context';
import { StudentListProvider } from 'src/features/Student/List/containers/context';
import { TimetableDetailsProvider } from 'src/features/Timetable/containers/DetailsProvider';
import TimetableCalendarContainer from 'src/features/Timetable/Details/containers/TimetableCalendar';
import Toggle from 'src/components/Toggle';

import { useSearchVariables } from 'src/hooks/useUrlUtils';

import urls from 'src/urls';



const TimetableCalendarPage = () => {
  const { timetable_id: timetableId } = useParams();

  const { school_id: schoolId, therapist_id: therapistId } = useSearchVariables();

  const searchFilters = {
    schoolId: schoolId,
    therapistId: therapistId,
  };

  const navigate = useNavigate();

  if (!timetableId) {
    return <Navigate to={urls.index()} />;
  }

  return (
    <TimetableDetailsProvider id={timetableId}>
      {
        ({ status, timetableDetails }) => {

          return (
            <Card
              title={<Typography.Title level={2}>Timetable Details</Typography.Title>}
            >
              <Toggle show={status.request} tag="div" style={{ textAlign: 'center' }}>
                <Spin tip="Loading Timetable data..." />
              </Toggle>

              <Toggle show={!!status.failure}>
                <Result
                  status="error"
                  title={(status as AjaxFailureStatus).failure.message}
                />
              </Toggle>

              <Toggle show={status.success}>
                <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                  <SessionListProvider>
                    <TherapistListProvider>
                      <StudentListProvider>
                        <TimetableCalendarContainer
                          status={status}
                          details={timetableDetails!}
                          searchFilters={searchFilters}
                        />
                      </StudentListProvider>
                    </TherapistListProvider>
                  </SessionListProvider>
                </Space>
              </Toggle>
            </Card>
          );
        }
      }
    </TimetableDetailsProvider>
  );
};

export default TimetableCalendarPage;
