import { Record }                     from 'immutable';

import { FilterFields }               from './enums';
import { TimetableListFiltersValues } from './types';


export const TimetableFilters = Record<TimetableListFiltersValues>({
  [FilterFields.Name]: '',
  [FilterFields.Organization]: null,
});

