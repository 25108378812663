import { useMemo } from 'react';
import { isAfter } from 'date-fns';
import * as Yup from 'yup';

import { FormFields } from '../enums';
import { OrganizationSchema } from 'src/utils/form';
import { OrganizationType } from 'src/enums';


const TIME_REGEXP = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/;

export const useValidationSchema = () => useMemo(() => Yup.object({
  [FormFields.Name]: Yup.string().required('Name is required'),
  [FormFields.Organization]: OrganizationSchema.nullable().test({
    name: 'DistrictRequired',
    test: (value) => value?.type === OrganizationType.District,
    message: 'District required',
    exclusive: true,
  }),
  [FormFields.Availability]: Yup.array().when('customAvailability', {
    is: true,
    then: () => {
      return Yup.array().of(
        Yup.object().shape({
          id: Yup.number().required(),
          day: Yup.string().required(),
          checked: Yup.boolean().required(),
          intervals: Yup.object().when('checked', {
            is: (values: any) => {
              return values === true;
            },
            then: () => {
              return Yup.object().shape({
                start: Yup.date().required('Start time required'),
                end: Yup.date().required('End time required')
              });
            },
            otherwise: () => {
              return Yup.object().notRequired();
            }
          })
        })
          .test({
            name: 'IntervalRequired',
            test: (value: any) => {
              if (!value?.checked) {
                return true;
              }

              return value?.checked && value?.intervals?.start && value?.intervals?.end && isAfter(value?.intervals?.end, value?.intervals?.start);
            },
            message: 'End time must be after start time',
            exclusive: true,
          }),
      )
        .test({
          name: 'AvailabilityRequired',
          test: (value: any) => {
            return value.find((interval: any) => interval.checked);
          },
          message: 'Availability required',
          exclusive: true,
        });
    }
  })
}), [ ]);
