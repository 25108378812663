import { List, RecordOf } from 'immutable';

import { StudentListElementDto } from 'src/services/api/student/student.dto';
import { StudentListElement } from '../../models/StudentListElement';
import { FilterFields } from 'src/features/Student/List/enums';
import { StudentListFiltersValues } from 'src/features/Student/List/types';
import { IStudentListCriteria } from 'src/services/api/student/types';
import { OrganizationType } from 'src/enums';


export function createCriteria(filters: RecordOf<StudentListFiltersValues>, pagination: IPagination): IStudentListCriteria {
  const organization = filters.get(FilterFields.Organization);
  const keyword = filters.get(FilterFields.Name);

  const organizationCriteria = organization?.type === OrganizationType.District ? 'districtId' : 'schoolId';

  return {
    page: pagination.page - 1,
    limit: pagination.pageSize,
    [organizationCriteria]: organization?.id ?? undefined,
    keyword: keyword ?? undefined,
    // sort: [ convertToQueryParam(sort) ],
  };
}


export function parseStudentList(items: StudentListElementDto[]): List<StudentListElement> {
  return List(items.map(item => {
    const {
      id,
      firstName,
      lastName,
      schoolDistrictId,
      schoolDistrictName,
      schoolId,
      schoolName,
      needs,
      timetableId,
    } = item;

    // const rootOrgIsDistrict = organization.type === OrganizationType.District;

    return new StudentListElement(
      id,
      firstName,
      lastName,
      schoolDistrictId,
      schoolDistrictName,
      schoolId,
      schoolName,
      needs,
      timetableId,
    );
  }));
}
