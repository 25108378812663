import {useEffect, useState} from 'react';
import {
  useAjaxStatus,
  useRequestKey,
} from 'src/hooks';

import { district as DistrictService } from 'src/services';
import { DistrictDetails } from '../models/DistrictDetails';
import { ApiError } from 'src/modules/ErrorContext/models';


export const useDistrictDetails = (id: string) => {
  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();
  const { engage, isCurrentRequest } = useRequestKey();

  const [ districtDetails, setDistrictDetails ] = useState<Nullable<DistrictDetails>>(null);

  useEffect(() => {
    const [ loadDetails, abort ] = DistrictService.getDistrictDetails(id);
    const key = engage();

    setRequest();

    loadDetails()
      .then(data => {
        if (isCurrentRequest(key)) {
          setDistrictDetails(DistrictDetails.fromJS(data));
          setSuccess();
        }
      })
      .catch((err: ApiError) => {
        if (isCurrentRequest(key)) {
          setFailure(err);
        }
      });


    return () => abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id ]);

  return {
    districtDetails,
    status
  };
};
