import React from 'react';
import { useMemo } from 'react';

import { Typography }        from 'antd';
import { Link }              from 'react-router-dom';

import type { ColumnsType }  from 'antd/es/table';
import { TableColumns } from 'src/features/Therapist/List/enums';
import { useSchoolListContext } from 'src/features/School/List/containers/context';
import { SchoolListElement } from 'src/features/School/List/models/SchoolListElement';
import { useTherapistListContext } from 'src/features/Therapist/List/containers/context';
import { useSkillListContext } from 'src/features/Skill/List/containers/context';
import { TherapistListElement, TherapistSchool } from 'src/features/Therapist/List/models/TherapistListElement';
import { ITherapistListElementData } from 'src/features/Therapist/List/containers/TherapistList';
import TherapistList from 'src/features/Therapist/List/components/TherapistList';

import urls                  from 'src/urls';


interface TimetableTherapistListContainerProps {
  timetableTherapistsIds: string[]
}

const TimetableTherapistListContainer = ({ timetableTherapistsIds }: TimetableTherapistListContainerProps) => {
  const { collection: therapistsCollection, status: therapistStatus } = useTherapistListContext();
  const therapistsMap = useMemo(() => therapistsCollection.reduce((arr, element) => {
    const { id } = element;

    arr.set(id, element);

    return arr;
  }, new Map<string, TherapistListElement>()), [
    therapistsCollection,
  ]);

  const { collection: schoolCollection, status: schoolStatus } = useSchoolListContext();
  const schoolMap = useMemo(() => schoolCollection.reduce((arr, element) => {
    const { id } = element;

    arr.set(id, element);

    return arr;
  }, new Map<string, SchoolListElement>()), [
    schoolCollection,
  ]);

  const { collection: skillCollection } = useSkillListContext();
  const skillsMap = useMemo(() => skillCollection.reduce((arr, element) => {
    const { id, name } = element;

    arr.set(Number(id), name);

    return arr;
  }, new Map<number, string>()), [
    skillCollection,
  ]);


  const tableData = useMemo(() => timetableTherapistsIds.reduce((arr, therapistId) => {
    const { id, name, schools, skills, utilization, timetableId } = therapistsMap.get(`${therapistId}`) || {} as TherapistListElement;

    arr.push({
      key: id,
      [TableColumns.Name]: name,
      [TableColumns.Districts]: schools || [],
      [TableColumns.Schools]: schools || [],
      [TableColumns.Skills]: skills || [],
      [TableColumns.Utilization]: utilization,
      [TableColumns.Schedule]: timetableId,
      [TableColumns.Actions]: id,
    });

    return arr;
  }, [] as ITherapistListElementData[]), [
    therapistsMap, timetableTherapistsIds,
  ]);

  const tableColumns: ColumnsType<ITherapistListElementData> = useMemo(() => ([
    {
      key: TableColumns.Name,
      title: 'FirstName',
      dataIndex: TableColumns.Name,
      render: (value: string, record: ITherapistListElementData) => <Link to={urls.therapistDetails(record.key)}>{ value }</Link>
    },
    {
      key: TableColumns.Districts,
      title: 'District Name',
      dataIndex: TableColumns.Districts,
      render: (value: TherapistSchool[]) => {
        const districtsSet = new Set(value.map((school: TherapistSchool) => school?.districtName));

        return [...districtsSet].map((name: string) => (
          <div><Typography.Text>{name}</Typography.Text></div>
        ));
      }
    },
    {
      key: TableColumns.Schools,
      title: 'Schools',
      dataIndex: TableColumns.Schools,
      render: (value: string[]) => value.map((item) => (<div><Typography.Text>{schoolMap.get(`${item}`)?.name}</Typography.Text></div>))
    },
    {
      key: TableColumns.Skills,
      title: 'Skills',
      dataIndex: TableColumns.Skills,
      render: (value: number[]) => <Typography.Text>{ value?.map(id => skillsMap?.get(id)).join(', \n') ?? <>&mdash;</> }</Typography.Text>,
    },
  ].filter(Boolean) as ColumnsType<ITherapistListElementData>), [schoolMap, skillsMap]);


  return (
    <TherapistList
      tableColumns={tableColumns}
      tableData={tableData}
      loading={therapistStatus.request}
      pagination={false}
    />
  );
};

export default TimetableTherapistListContainer;
