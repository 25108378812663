import {
  Card,
  Space,
  Typography } from 'antd';
import TimetableUploadForm from 'src/features/Timetable/Forms/containers/UploadForm';


const ScheduleUploadPage = (props: ScheduleUploadPageProps) => {
  const { setActiveTab } = props;

  return (
    <Card
      title={<Typography.Title level={2}>Upload a Data File</Typography.Title>}
    >
      <div>
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <TimetableUploadForm setActiveTab={setActiveTab} />
          </Space>
        </Space>
      </div>
    </Card>
  );
};

export default ScheduleUploadPage;


export interface ScheduleUploadPageProps {
  setActiveTab(key: string): void;
}
