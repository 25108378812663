export const enum FilterFields {
  Name = 'name',
  Organization = 'organization',
  Signed = 'signed',
}

export const enum SortFields {
  Name = 'name',
  ModificationDate = 'modifiedAt',
}

export const enum TableColumns {
  Name = 'name',
  CreatedAt = 'createdAt',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Districts = 'districts',
  Schools = 'schools',
  DateRange = 'dateRange',
  Status = 'status',
  Error = 'error',
}
