import { useFilterHandlers } from '../hooks';
import { useSchoolListContext } from './context';

import SchoolListFilters from '../components/Filters';


const SchoolListFiltersContainer = () => {
  const {
    changeOrganizationFilter,
    searchByName,
  } = useFilterHandlers();

  const { filters } = useSchoolListContext();

  return (
    <SchoolListFilters
      filterValues={filters}
      onFilterByOrganization={changeOrganizationFilter}
      onSearchByName={searchByName}
    />
  )
}

export default SchoolListFiltersContainer;
