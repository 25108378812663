import {
  Card,
  Space,
  Typography } from 'antd';
import { TimetableListProvider } from 'src/features/Timetable/List/containers/context';
import TimetableList from 'src/features/Timetable/List/containers/TimetableList';
import TimetableListFilters from 'src/features/Timetable/List/containers/TimetableListFilters';


const ScheduleListPage = () => {
  return (
    <Card
      title={<Typography.Title level={2}>List of Schedules</Typography.Title>}
    >
      <div>
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <TimetableListProvider>
              <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                <TimetableListFilters />
                <TimetableList />
              </Space>
          </TimetableListProvider>
        </Space>
      </div>
    </Card>
  );
};

export default ScheduleListPage;
