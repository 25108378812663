import { List, RecordOf } from 'immutable';

import { DistrictListElementDto } from 'src/services/api/district/district.dto';
import { DistrictListElement } from '../../models/DistrictListElement';
import { IPagination } from '@k12-frontend/core';
import { FilterFields } from 'src/features/District/List/enums';
import { IDistrictListCriteria } from 'src/services/api/district/types';
import { DistrictListFiltersValues } from 'src/features/District/List/types';


export function createCriteria(filters: RecordOf<DistrictListFiltersValues>, pagination: IPagination): IDistrictListCriteria {
  const keyword = filters.get(FilterFields.Name);

  return {
    page: pagination.page - 1,
    limit: pagination.pageSize,
    keyword: keyword ?? undefined,
    // sort: [ convertToQueryParam(sort) ],
  };
}


export function parseDistrictList(items: DistrictListElementDto[]): List<DistrictListElement> {
  return List(items.map(item => {
    const {
      id,
      name,
    } = item;

    return new DistrictListElement(
      id,
      name,
    );
  }));
}
