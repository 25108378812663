export const enum SortOrder {
  Asc = '+',
  Desc = '-',
}

export const enum UserRoleType {
  GlobalAdmin = 'GLOBAL_ADMIN',
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  OrganizationObserver = 'ORGANIZATION_OBSERVER',
}

export const enum OrganizationType {
  District = 'DISTRICT',
  School = 'SCHOOL',
}

export const enum AvailabilityWeekDay {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}
