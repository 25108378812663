import {
  Space,
  Descriptions } from 'antd';
import { format } from 'date-fns';
import { StudentDetails } from 'src/features/Student/models/StudentDetails';
import Toggle from 'src/components/Toggle';


const StudentInfo = (props: IStudentInfoProps) => {
  const { details } = props;

  return (
    <Space direction="vertical" size="large">
      <Descriptions column={1} bordered>
        <Descriptions.Item label="First Name">{details?.firstName}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{details?.lastName}</Descriptions.Item>
        <Descriptions.Item label="School">{details?.organization?.name}</Descriptions.Item>
      </Descriptions>

      <Toggle show={Boolean(details.availability?.length)}>
        <h2>Availability</h2>
        <Descriptions column={1} bordered>
          {details.availability.map((item) => (
            <Descriptions.Item label={item.day}>{`${format(new Date(item.intervals.start ?? ''), 'h:mm a')} - ${format(new Date(item.intervals.end ?? ''), 'h:mm a')}` }</Descriptions.Item>
          ))}
        </Descriptions>
      </Toggle>
    </Space>
  );
};

export default StudentInfo;


/* HELPERS */

interface IStudentInfoProps extends ICommonProps {
  details: StudentDetails;
}
