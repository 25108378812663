import queryString from 'query-string';

const urls = {
  index: createUrlGetter(() => '/'),

  dashboard: createUrlGetter(() => '/dashboard'),

  district: createUrlGetter(() => '/district'),
  districtCreate: createUrlGetter(() => '/district/new'),
  districtDetails: createUrlGetter((id: string) => `/district/${id}`),
  districtEdit: createUrlGetter((id: string) => `/district/${id}/edit`),

  school: createUrlGetter(() => '/school'),
  schoolCreate: createUrlGetter(() => '/school/new'),
  schoolDetails: createUrlGetter((id: string) => `/school/${id}`),
  schoolEdit: createUrlGetter((id: string) => `/school/${id}/edit`),

  therapist: createUrlGetter(() => '/therapist'),
  therapistCreate: createUrlGetter(() => '/therapist/new'),
  therapistDetails: createUrlGetter((id: string) => `/therapist/${id}`),
  therapistEdit: createUrlGetter((id: string) => `/therapist/${id}/edit`),

  student: createUrlGetter(() => '/student'),
  studentCreate: createUrlGetter(() => '/student/new'),
  studentDetails: createUrlGetter((id: string) => `/student/${id}`),
  studentEdit: createUrlGetter((id: string) => `/student/${id}/edit`),
  prescriptionCreate: createUrlGetter(() => '/prescription/new'),
  studentPrescriptions: createUrlGetter((id: string) => `/student/${id}/prescriptions`),

  availability: createUrlGetter(() => '/availability'),
  availabilityCalendar: createUrlGetter(() => '/availability/calendar'),
  availabilityCreate: createUrlGetter(() => '/availability/new'),
  availabilityEdit: createUrlGetter((id: string) => `/availability/${id}/edit`),
  availabilityDetails: createUrlGetter((id: string) => `/availability/${id}`),

  session: createUrlGetter(() => '/session'),
  sessionCreate: createUrlGetter(() => '/session/new'),
  sessionEdit: createUrlGetter((id: string) => `/session/${id}/edit`),
  sessionDetails: createUrlGetter((id: string) => `/session/${id}`),

  skill: createUrlGetter(() => '/skill'),
  skillCreate: createUrlGetter(() => '/skill/new'),
  skillDetails: createUrlGetter((id: string) => `/skill/${id}`),
  skillEdit: createUrlGetter((id: string) => `/skill/${id}/edit`),

  timetable: createUrlGetter(() => '/timetable'),
  timetableCreate: createUrlGetter(() => '/timetable/new'),
  timetableDetails: createUrlGetter((id: string) => `/timetable/${id}`),
  timetableCalendar: createUrlGetter((id: string) => `/timetable/${id}/calendar`),
  timetableUpload: createUrlGetter(() => '/timetable/upload'),

  schedule: createUrlGetter(() => '/schedule'),
  scheduleDetails: createUrlGetter((id: string) => `/schedule/${id}`),
  scheduleCalendar: createUrlGetter((id: string) => `/schedule/${id}/calendar`),

  auth: createUrlGetter(() => '/auth'),
  login: createUrlGetter(() => '/login'),
};

export default urls;

/* HELPERS */

export function createUrlGetter(pathCreator: (...args: any[]) => string) {
  if (typeof pathCreator !== 'function') {
    throw new TypeError(`argument is not a function; ${typeof pathCreator} passed`);
  }

  return function (...args: any[]) {
    const creatorArgsCount = pathCreator.length;
    const queryParams = args[creatorArgsCount] || {};
    const hashValue = args[creatorArgsCount + 1];

    const pathStr = pathCreator.apply(pathCreator, args.slice(0, creatorArgsCount));
    const queryStr = queryString.stringify(queryParams, { arrayFormat: 'comma' });
    const hashStr = hashValue != null && String(hashValue);

    return [pathStr, queryStr && `?${queryStr}`, hashStr && `#${hashStr}`].filter(Boolean).join('');
  };
}
