import {useEffect, useState} from 'react';
import {
  useAjaxStatus,
  useRequestKey,
} from 'src/hooks';

import { school as SchoolService } from 'src/services';
import { SchoolDetails } from '../models/SchoolDetails';
import { ApiError } from 'src/modules/ErrorContext/models';


export const useSchoolDetails = (id: string) => {
  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();
  const { engage, isCurrentRequest } = useRequestKey();

  const [ schoolDetails, setSchoolDetails ] = useState<Nullable<SchoolDetails>>(null);

  useEffect(() => {
    const [ loadDetails, abort ] = SchoolService.getSchoolDetails(id);
    const key = engage();

    setRequest();

    loadDetails()
      .then(data => {
        if (isCurrentRequest(key)) {
          setSchoolDetails(SchoolDetails.fromJS(data));
          setSuccess();
        }
      })
      .catch((err: ApiError) => {
        if (isCurrentRequest(key)) {
          setFailure(err);
        }
      });


    return () => abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id ]);

  return {
    schoolDetails,
    status
  };
};
