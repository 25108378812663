import { addHours, format, hoursToMinutes, startOfDay } from 'date-fns';
import { FormFields } from './enums';
import { SchoolFormValues } from './types';
import { SchoolAvailabilityDto } from 'src/services/api/school/school.dto';
import { AvailabilityWeekDay } from 'src/enums';
import { Availability } from 'src/models/Availability';
import { initialAvailability } from 'src/features/Student/Forms/constants';


function prepareAvailability(values: SchoolFormValues) {
  return values[FormFields.Availability].reduce((acc, item) => {
    if (item.checked && item.intervals.start && item.intervals.end) {
      const [startHours, startMinutes] = format(item.intervals.start, 'HH:mm').split(':');
      const startInMinutes = hoursToMinutes(addHours(startOfDay(new Date()), Number(startHours)).getHours()) + Number(startMinutes);

      const [endHours, endMinutes] = format(item.intervals.end, 'HH:mm').split(':');
      const endInMinutes = hoursToMinutes(addHours(startOfDay(new Date()), Number(endHours)).getHours()) + Number(endMinutes);

      acc.push({ day: item.id, intervals: [{ start: startInMinutes, end: endInMinutes } ] });
    }

    return acc;
  }, [] as SchoolAvailabilityDto[]);
}

export function prepareCreateFormData(values: SchoolFormValues) {
  return {
    name: values[FormFields.Name],
    districtId: values[FormFields.Organization]?.id ?? null,
    availability: prepareAvailability(values),
  };
}

export function prepareUpdateFormData(values: SchoolFormValues) {
  return {
    id: values[FormFields.Id] as string,
    name: values[FormFields.Name],
    districtId: values[FormFields.Organization]?.id ?? '',
    availability: prepareAvailability(values),
  };
}


export function createSchoolAvailability(data: Availability[]): Availability[] {
  const dataMap = data.reduce((acc, item) => {
    if (item) {
      acc.set(item.day, item);
    }
    return acc;
  }, new Map<AvailabilityWeekDay, Availability>());

  return initialAvailability.map((item) => {
    const { id, day, intervals: defaultIntervals } = item;

    if (dataMap.has(day)) {

      return Availability.fromJS({
        id,
        day,
        checked: true,
        intervals: dataMap.get(day)?.intervals || defaultIntervals
      });
    }

    return Availability.fromJS(item);
  });
}
