import React, { useEffect } from 'react';
import { List, RecordOf } from 'immutable';
import { useMemo } from 'react';
import {
  useRequestKey,
  useListState,
  useFilters,
  usePagination,
  useAjaxStatus,
  useDebouncedValue
  } from 'src/hooks';

import { therapist as TherapistService } from 'src/services';

import { createCriteria, parseTherapistList } from './utils';
import { AjaxStatus } from 'src/constants';
import { TherapistListData } from './types';
import { TherapistListElement } from '../../models/TherapistListElement';
import { TherapistsFilters } from '../../constants';
import { ChildrenFunc } from 'src/utils';
import { TherapistListFiltersValues } from 'src/features/Therapist/List/types';


export const TherapistListContext = React.createContext<TherapistListData>({
  status: AjaxStatus.default(),
  filters: new TherapistsFilters(),
  pagination: {
    page: 0,
    pageSize: 0,
  },
  total: 0,
  collection: List<TherapistListElement>(),
  changePage: () => void(0),
  resetFilters: () => void(0),
  changeFilterValues: () => void(0),
});


export const TherapistListProvider = (props: ITherapistListProviderProps) => {
  const { children, initialFilters = new TherapistsFilters() } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const {
    filters,
    resetFilters,
    changeFilterValues
  } = useFilters<TherapistListFiltersValues>(initialFilters);

  const { pagination, changePage } = usePagination(1, 10);

  const {
    total,
    collection,
    setCollectionState
  } = useListState<TherapistListElement>();

  const { engage, isCurrentRequest } = useRequestKey();

  const filtersValue = useDebouncedValue(filters, 300);

  useEffect(() => {
    const [ loadList, abort ] = TherapistService.getTherapistList(createCriteria(filtersValue, pagination));
    const key = engage();

    setRequest();

    loadList()
      .then(({ count, items }) => {

        setCollectionState({
          total: count!,
          collection: parseTherapistList(items!),
        });
        setSuccess();
      })
      .catch(err => {
        isCurrentRequest(key) && setFailure(err);
      });

    return () => abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ filtersValue, pagination ]);

  const value = useMemo(() => ({
    status,
    total,
    filters,
    pagination,
    collection,
    changePage,
    resetFilters,
    changeFilterValues,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    status,
    total,
    filters,
    pagination,
    collection,
    changePage,
    resetFilters,
    changeFilterValues,
  ]);

  return (
    <TherapistListContext.Provider value={value}>
      { typeof children === 'function' ? children(value) : children }
    </TherapistListContext.Provider>
  );
};


/* HELPERS */

interface ITherapistListProviderProps {
  initialFilters?: RecordOf<TherapistListFiltersValues>;
  children?: React.ReactNode | ChildrenFunc<TherapistListData>;
}
