import React, { useEffect } from 'react';
import { List, RecordOf } from 'immutable';
import { useMemo } from 'react';
import {
  useRequestKey,
  useListState,
  useFilters,
  usePagination,
  useAjaxStatus,
  useDebouncedValue
  } from 'src/hooks';

import { school as SchoolService } from 'src/services';

import { createCriteria, parseSchoolList } from './utils';
import { AjaxStatus } from 'src/constants';
import { SchoolListData } from './types';
import { SchoolListElement } from '../../models/SchoolListElement';
import { SchoolsFilters } from '../../constants';
import { ChildrenFunc } from 'src/utils';
import { SchoolsListFilters } from 'src/features/School/List/types';


export const SchoolListContext = React.createContext<SchoolListData>({
  status: AjaxStatus.default(),
  filters: new SchoolsFilters(),
  pagination: {
    page: 0,
    pageSize: 0,
  },
  total: 0,
  collection: List<SchoolListElement>(),
  changePage: () => void(0),
  resetFilters: () => void(0),
  changeFilterValues: () => void(0),
});


export const SchoolListProvider = (props: ISchoolsListProviderProps) => {
  const { children, initialFilters = new SchoolsFilters() } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const {
    filters,
    resetFilters,
    changeFilterValues
  } = useFilters<SchoolsListFilters>(initialFilters);

  const { pagination, changePage } = usePagination(1, 10);

  const {
    total,
    collection,
    setCollectionState
  } = useListState<SchoolListElement>();

  const { engage, isCurrentRequest } = useRequestKey();

  const filtersValue = useDebouncedValue(filters, 300);

  useEffect(() => {
    const [ loadList, abort ] = SchoolService.getSchoolList(createCriteria(filtersValue, pagination));
    const key = engage();

    setRequest();

    loadList()
      .then(({ count, items }) => {

        setCollectionState({
          total: count!,
          collection: parseSchoolList(items!),
        });
        setSuccess();
      })
      .catch(err => {
        isCurrentRequest(key) && setFailure(err);
      });

    return () => abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pagination, filtersValue ]);

  const value = useMemo(() => ({
    status,
    total,
    filters,
    pagination,
    collection,
    changePage,
    resetFilters,
    changeFilterValues,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    status,
    total,
    filters,
    pagination,
    collection,
    changePage,
    resetFilters,
    changeFilterValues,
  ]);

  return (
    <SchoolListContext.Provider value={value}>
      { typeof children === 'function' ? children(value) : children }
    </SchoolListContext.Provider>
  );
};


/* HELPERS */

interface ISchoolsListProviderProps {
  initialFilters?: RecordOf<SchoolsListFilters>;
  children?: React.ReactNode | ChildrenFunc<SchoolListData>;
}
