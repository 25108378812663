import { useMemo }           from 'react';

import * as Yup              from 'yup';

import { FormFields }        from '../enums';


export const useValidationSchema = () => useMemo(() => Yup.object({
  [FormFields.Name]: Yup.string().required('Name is required'),
  [FormFields.SchoolId]: Yup.string().required('School is required'),
}), [ ]);
