import { useFilterHandlers } from '../hooks';
import { useStudentListContext } from './context';

import StudentListFilters from '../components/Filters';


const StudentListFiltersContainer = () => {
  const {
    changeOrganizationFilter,
    searchByName,
  } = useFilterHandlers();

  const { filters } = useStudentListContext();

  return (
    <StudentListFilters
      filterValues={filters}
      onFilterByOrganization={changeOrganizationFilter}
      onSearchByName={searchByName}
    />
  );
};

export default StudentListFiltersContainer;
