import { List, RecordOf } from 'immutable';

import { TimetableListElementDto } from 'src/services/api/timetable/timetable.dto';
import { TimetableListElement } from '../../models/TimetableListElement';
import { FilterFields } from 'src/features/Timetable/List/enums';
import { TimetableListFiltersValues } from 'src/features/Timetable/List/types';
import { ITimetableListCriteria } from 'src/services/api/timetable/types';
import { OrganizationType } from 'src/enums';


export function createCriteria(filters: RecordOf<TimetableListFiltersValues>, pagination: IPagination): ITimetableListCriteria {
  const organization = filters.get(FilterFields.Organization);
  const keyword = filters.get(FilterFields.Name);

  const organizationCriteria = organization?.type === OrganizationType.District ? 'districtId' : 'schoolId';

  return {
    page: pagination.page - 1,
    limit: pagination.pageSize,
    [organizationCriteria]: organization?.id ?? undefined,
    keyword: keyword ?? undefined,
    // sort: [ convertToQueryParam(sort) ],
  };
}


export function parseTimetableList(items: TimetableListElementDto[]): List<TimetableListElement> {
  return List(items.map(item => {
    const {
      id,
      name,
      createdAt,
      status,
      startDate,
      endDate,
      schools,
      error
    } = item;

    // const rootOrgIsDistrict = organization.type === OrganizationType.District;

    return new TimetableListElement(
      id,
      name,
      createdAt,
      status,
      startDate,
      endDate,
      schools,
      error
    );
  }));
}
