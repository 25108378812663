import { ApiIntent, ApiService } from '../types';
import {
  DistrictDetailsDto,
  DistrictListDto,
  DistrictListElementDto,
  DistrictUpdateDto,
} from './district.dto';
import { IDistrictListCriteria } from './types';


export class DistrictApiService extends ApiService {
  getDistrictDetails(id: string): ApiIntent<any> {
    const [ getDistrict, abort ] = this.createGetIntent<DistrictDetailsDto>(`districts/${id}`);

    return [() => getDistrict().then(({ data: eopInfo }) => eopInfo), abort];
  }

  getDistrictList(criteria?: IDistrictListCriteria): ApiIntent<{ count?: number; items?: DistrictListElementDto[]; }> {
    const [ getDistrictList, abort ] = this.createGetIntent<DistrictListDto>('districts', criteria);

    return [
      () => getDistrictList().then(({ data: { count, items } }) => ({ count, items })),
      abort,
    ];
  }

  createDistrict(payload: any): ApiIntent<string> {
    const [ createDistrict, abort ] = this.createPostIntent<{ id: string; }>('districts', payload);

    return [
      () => createDistrict().then(({ data: { id } }) => id),
      abort,
    ];
  }

  updateDistrict(id: string, payload: DistrictUpdateDto):ApiIntent<boolean> {
    const [ updateDistrict, abort ] = this.createPutIntent<{}>(`districts/${id}`, payload);

    return [
      () => updateDistrict().then(() => true),
      abort,
    ];
  }
}
