import { useCallback } from 'react';
import { Formik } from 'formik';
import { useAjaxStatus } from 'src/hooks';
import { useValidationSchema } from '../hooks';

import { school as SchoolService } from 'src/services';

import Form from '../components/Form';
import { prepareCreateFormData } from '../utils';
import { FormFields } from '../enums';
import { SchoolFormValues } from '../types';
import { ApiError } from 'src/modules/ErrorContext/models';
import { initialAvailability } from 'src/features/Student/Forms/constants';


const SchoolCreateFormContainer = (props: ISchoolCreateFormContainerProps) => {
  const { onCancel, onCreated } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();


  const createSchool = useCallback(async (values: SchoolFormValues) => {
    setRequest();

    try {
      const [ createSchool ] = SchoolService.createSchool(prepareCreateFormData(values));

      const id = await createSchool();

      setSuccess();

      if (typeof onCreated === 'function') {
        onCreated({
          id,
          name: values[FormFields.Name],
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onCreated ]);

  const validationSchema = useValidationSchema();
  const editableFields = new Set([
    FormFields.Name,
    FormFields.Organization,
  ]);

  return (
    <Formik
      initialValues={{
        [FormFields.Id]: null,
        [FormFields.Name]: '',
        [FormFields.Organization]: null,
        [FormFields.Availability]: initialAvailability,
      }}
      validationSchema={validationSchema}
      onSubmit={createSchool}
    >
      <Form
        status={status}
        editableFields={editableFields}
        onCancel={onCancel}
        validationSchema={validationSchema}
      />
    </Formik>
  );
};

export default SchoolCreateFormContainer;


/* HELPERS */

interface ISchoolCreateFormContainerProps extends ICommonProps {
  onCancel?(): void;
  onCreated?(schoolEntity: IEntity): void;
}
