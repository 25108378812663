import { AxiosHttpClient } from 'src/services/http/AxiosClient';
import { MB }                  from 'src/constants';
import { AxiosRequestHeaders } from 'axios';


export class FileService {

  constructor(
    private readonly http: AxiosHttpClient
  ) {}

  static MAX_UPLOAD_SIZE = 50 * MB;

  static getObjectURLFromBlob(blob: Blob) {
    const urlCreator: UrlCreator = window && (window.URL || window.webkitURL);
    const objectURL = urlCreator.createObjectURL(blob);

    return {
      urlCreator,
      objectURL,
    };
  }

  static saveAs({ urlCreator, objectURL, fileName = 'attachment' }: { urlCreator: UrlCreator; objectURL: string; fileName?: string; }) {
    const a = document.createElement('a');
    a.href = objectURL;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    urlCreator.revokeObjectURL(objectURL);
  }

  private prepareBlobData = (response: any) => {
    if (!response || !(response.data instanceof Blob)) {
      throw new TypeError('Response is not a Blob');
    }

    return FileService.getObjectURLFromBlob(response.data);
  };

  downloadUrl(fileUrl: string, fileName = 'attachment') {
    return this.loadFileByUrl(fileUrl)
      .then(this.prepareBlobData)
      .then(({ urlCreator, objectURL }) => FileService.saveAs({
        urlCreator,
        objectURL,
        fileName,
      }));
  }

  private loadFileByUrl(url: string) {
    return this.http.get(url, undefined, undefined, { headers: {} as AxiosRequestHeaders, responseType: 'blob', timeout: 60 * 1000 });
  }
}

export default FileService;


/* HELPERS */

type UrlCreator = {
  new (url: string | URL, base?: string | URL | undefined): URL;
  prototype: URL;
  createObjectURL(obj: Blob | MediaSource): string;
  revokeObjectURL(url: string): void;
};
