import {
  Navigate,
  useParams,
  useNavigate } from 'react-router-dom';
import {
  Card,
  Space,
  Spin,
  Result,
  Typography } from 'antd';
import { SchoolListProvider } from 'src/features/School/List/containers/context';
import { AvailabilityListProvider } from 'src/features/Availability/List/containers/context';
import { SessionListProvider } from 'src/features/Session/List/containers/context';
import { TherapistListProvider } from 'src/features/Therapist/List/containers/context';
import { StudentListProvider } from 'src/features/Student/List/containers/context';
import { TimetableDetailsProvider } from 'src/features/Timetable/containers/DetailsProvider';
import { DistrictListProvider } from 'src/features/District/List/containers/context';
import { SkillListProvider } from 'src/features/Skill/List/containers/context';
import TimetableInfo from 'src/features/Timetable/Details/components/TimetableInfo';
import Toggle from 'src/components/Toggle';

import urls from 'src/urls';


const TimetableDetailsPage = () => {
  const { timetable_id: timetableId } = useParams();

  const navigate = useNavigate();

  if (!timetableId) {
    return <Navigate to={urls.index()} />;
  }

  return (
    <TimetableDetailsProvider id={timetableId}>
      {
        ({ status, timetableDetails }) => {

          return (
            <Card
              title={<Typography.Title level={2}>Timetable Details</Typography.Title>}
            >
              <Toggle show={status.request} tag="div" style={{ textAlign: 'center' }}>
                <Spin tip="Loading Timetable data..." />
              </Toggle>

              <Toggle show={!!status.failure}>
                <Result
                  status="error"
                  title={(status as AjaxFailureStatus).failure.message}
                />
              </Toggle>

              <Toggle show={status.success}>
                <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                  <SchoolListProvider>
                  <SessionListProvider>
                  <TherapistListProvider>
                  <StudentListProvider>
                  <AvailabilityListProvider>
                  <DistrictListProvider>
                  <SkillListProvider>
                    <TimetableInfo
                      details={timetableDetails!}
                    />
                  </SkillListProvider>
                  </DistrictListProvider>
                  </AvailabilityListProvider>
                  </StudentListProvider>
                  </TherapistListProvider>
                  </SessionListProvider>
                  </SchoolListProvider>
                </Space>
              </Toggle>
            </Card>
          );
        }
      }
    </TimetableDetailsProvider>
  );
};

export default TimetableDetailsPage;
