import { useMemo }           from 'react';

import * as Yup              from 'yup';

import { FormFields }        from '../enums';


export const useValidationSchema = () => useMemo(() => Yup.object({
  [FormFields.StudentId]: Yup.string().required('Student is required'),
  [FormFields.Prescriptions]: Yup.array().of(
    Yup.object().shape({
      days: Yup.string().required("Days is required"),
      sessions: Yup.array().required("Sessions is required")
    })
  )}), []);
