import { MANUAL_ERROR_STATUS } from './errors';
export class AjaxStatusFactory {
    constructor() {
        this._default = Object.freeze({ request: false, success: false, failure: false });
        this._request = Object.freeze({ request: true, success: false, failure: false });
        this._success = Object.freeze({ request: false, success: true, failure: false });
    }
    default() { return this._default; }
    request() { return this._request; }
    success() { return this._success; }
    failure(error) {
        // for custom errors thrown from the app itself
        if (typeof error === 'string') {
            return Object.assign(Object.assign({}, this._default), { failure: {
                    status: MANUAL_ERROR_STATUS,
                    message: error,
                } });
        }
        // for api errors thrown from services
        const { status, message } = error;
        return Object.assign(Object.assign({}, this._default), { failure: {
                status,
                message,
            } });
    }
    isIdle(status) {
        return status === this._default;
    }
    all(...statuses) {
        if (statuses.some(s => s.request)) {
            return this.request();
        }
        if (statuses.every(s => s.success)) {
            return this.success();
        }
        return statuses.find(s => !!s.failure) || this.default();
    }
}
