import {
  Space,
  Descriptions } from 'antd';
import { DistrictDetails } from 'src/features/District/models/DistrictDetails';


const DistrictInfo = (props: IDistrictInfoProps) => {
  const { details } = props;

  return (
    <Space direction="vertical" size="large">
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Name">{details?.name}</Descriptions.Item>
      </Descriptions>
    </Space>
  );
};

export default DistrictInfo;


/* HELPERS */

interface IDistrictInfoProps extends ICommonProps {
  details: DistrictDetails;
}
