import { OrganizationType } from './enums';
import { AjaxStatusFactory } from './ajaxStatus';
import { Role } from './models/Role';
export const AjaxStatus = new AjaxStatusFactory();
export const UserRoleNames = {
    [Role.SuperAdmin]: 'Super Admin',
    [Role.DistrictAdmin]: 'District Admin',
    [Role.FacilityAdmin]: 'Facility Admin',
    [Role.EmergencyServices]: 'Emergency Services',
    [Role.Member]: 'Member',
    [Role.SspAdmin]: 'SSP Admin',
    [Role.Guardian]: 'Guardian',
};
export const OrganizationTypeNames = {
    [OrganizationType.District]: 'District',
    [OrganizationType.Facility]: 'Facility',
};
export const KB = 1024;
export const MB = 1024 * KB;
export const GB = 1024 * MB;
export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
