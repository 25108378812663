import {
  Navigate,
  useParams,
  useNavigate } from 'react-router-dom';
import {
  Card,
  Space,
  Spin,
  Result,
  Typography,
  Button } from 'antd';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { StudentDetailsProvider } from 'src/features/Student/containers/DetailsProvider';
import StudentInfo from 'src/features/Student/Details/components/StudentInfo';
import Toggle from 'src/components/Toggle';

import urls from 'src/urls';


const StudentDetailsPage = () => {
  const { student_id: studentId } = useParams();
  const navigate = useNavigate();

  if (!studentId) {
    return <Navigate to={urls.index()} />;
  }

  return (
    <StudentDetailsProvider id={studentId}>
      {
        ({ status, studentDetails }) => {

          const cardButtons = (
            <Space size="middle">
              <Toggle>
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => navigate(urls.studentEdit(studentId))}
                >
                  Edit
                </Button>
              </Toggle>
            </Space>
          );

          return (
            <Card
              title={<Typography.Title level={2}>Student Details</Typography.Title>}
              extra={cardButtons}
            >
              <Toggle show={status.request} tag="div" style={{ textAlign: 'center' }}>
                <Spin tip="Loading Student data..." />
              </Toggle>

              <Toggle show={!!status.failure}>
                <Result
                  status="error"
                  title={(status as AjaxFailureStatus).failure.message}
                />
              </Toggle>

              <Toggle show={status.success}>
                <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                  <StudentInfo
                    details={studentDetails!}
                  />
                </Space>
              </Toggle>
            </Card>
          );
        }
      }
    </StudentDetailsProvider>
  );
};

export default StudentDetailsPage;
