import React, { useMemo } from 'react';
import { addMinutes, format } from 'date-fns';
import { Col, Result, Row } from 'antd';

import TimetableCalendar from '../components/TimetableCalendar';

import type { TimetableDetails } from 'src/features/Timetable/models/TimetableDetails';
import { TimetableCalendarEvent } from 'src/features/Timetable/types';
import { NeedList } from 'src/features/Timetable/constants';
import { useCalendarFilters } from 'src/features/Timetable/hooks/useCalendarFilters';
import TimetableCalendarFiltersContainer from 'src/features/Timetable/Details/containers/TimetableCalendarFilters';
import { getRandomColor } from 'src/features/Timetable/Details/utils';


interface TimetableCalendarContainerProps {
  status:  IAjaxStatus;
  details: TimetableDetails;
  searchFilters: Record<string, string | (string | null)[] | null>
}

const TimetableCalendarContainer = ({ status, details, searchFilters }: TimetableCalendarContainerProps) => {
  const { status: timetableStatus, therapists, error } = details;

  const { filters, changeSchoolFilter, changeTherapistFilter, filteredTherapists } = useCalendarFilters(searchFilters, details);

  const eventsData = useMemo(() => {
    if (!filteredTherapists) {
      return [];
    }

    return filteredTherapists.reduce((arr, therapist) => {
      const { timetable, firstName, lastName, id } = therapist;

      let events = timetable.map(item => {

        const start = addMinutes(new Date(item.date), new Date(item.date).getTimezoneOffset() + item.startTime);
        const end = addMinutes(new Date(item.date), new Date(item.date).getTimezoneOffset() + item.endTime);
        const type = `Session ${item.group ? 'Group' : item.online ? 'Online' : 'Offline'}`;
        const need = item.need === 1 ? 'Speech therapist' : item.need === 2 ? NeedList.Behaviour : '';
        const studentName = `${item.studentFirstName} ${item.studentLastName}`;
        const therapistName = `${firstName} ${lastName}`;

        const EventInfo = () => (
          <div>
            {/*<div><b>{`${format(start, 'h:mm aaa')} - ${format(end, 'h:mm aaa')}`}</b></div>*/}
            <div>{need}</div>
            <div>{type}</div>
            <div>{`Therapist ${therapistName}`}</div>
            <div>{`Student ${studentName}`}</div>
          </div>
        );

        const CalendarEvent = EventInfo();

        return ({
          start: addMinutes(new Date(item.date), new Date(item.date).getTimezoneOffset() + item.startTime),
          end: addMinutes(new Date(item.date), new Date(item.date).getTimezoneOffset() + item.endTime),
          title: `Therapist ${therapistName}`,
          color: getRandomColor(id),
          time: `${format(start, 'h:mm aaa')} - ${format(end, 'h:mm aaa')}`,
          info: CalendarEvent,
        });
      });

      arr = [...arr, ...events];

      return arr;
    }, [] as TimetableCalendarEvent[]);
  }, [filteredTherapists]);

  // const sortedEvents =  useMemo(() => eventsData.sort(function (a, b) {
  //   a = a.date.split('-');
  //   b = b.date.split('-');
  //   return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
  // }), [eventsData])

  if (timetableStatus === 'ERROR') {
    return (
      <div>
        <Result
          status="500"
          title="Sorry, something went wrong."
          subTitle={error}
        />
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col span={4}>
          <div>
           <TimetableCalendarFiltersContainer
             filters={filters}
             onChangeSchool={changeSchoolFilter}
             onChangeTherapist={changeTherapistFilter}
           />
          </div>
        </Col>
        <Col span={20}>
          <TimetableCalendar
            initialEvents={eventsData}
            loading={!status.success}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TimetableCalendarContainer;
