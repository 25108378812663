import React, {useMemo} from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import { useTherapistListContext } from './context';
import { useSkillListContext } from 'src/features/Skill/List/containers/context';
import { SchoolDetails } from 'src/features/School/models/SchoolDetails';

import SchoolTherapistList from '../components/SchoolTherapistList';

import type { ColumnsType } from 'antd/es/table';
import { TableColumns } from '../enums';

import { parseUtilisation } from 'src/features/Therapist/List/utils';

import urls from 'src/urls';


interface ISchoolTherapistListContainerProps extends ICommonProps {
  schoolDetails: Nullable<SchoolDetails>;
}

const SchoolTherapistListContainer = (props: ISchoolTherapistListContainerProps) => {
  const { schoolDetails } = props;

  const {
    status,
    collection,
    total,
    pagination: { page, pageSize },
    changePage,
  } = useTherapistListContext();

  const { collection: skillCollection } = useSkillListContext();
  const skillsMap = useMemo(() => skillCollection.reduce((arr, element) => {
    const { id, name } = element;

    arr.set(Number(id), name);

    return arr;
  }, new Map<number, string>()), [
    skillCollection,
  ]);

  const tableData = useMemo(() => collection.reduce((arr, element) => {
    const { id, name, utilization, timetableId, skills } = element;

    arr.push({
      key: id,
      [TableColumns.Name]: name,
      [TableColumns.Skills]: skills || [],
      [TableColumns.Utilization]: utilization,
      [TableColumns.Schedule]: timetableId,
      [TableColumns.Actions]: id,
    });

    return arr;
  }, [] as ISchoolTherapistListElementData[]), [
    collection,
  ]);

  const tableColumns: ColumnsType<ISchoolTherapistListElementData> = useMemo(() => ([
    {
      key: TableColumns.Name,
      title: 'Therapist Name',
      dataIndex: TableColumns.Name,
      render: (value: string, record: ISchoolTherapistListElementData) => <Link to={urls.therapistDetails(record.key)}>{ value }</Link>
    },
    {
      key: TableColumns.Skills,
      title: 'Speciality',
      dataIndex: TableColumns.Skills,
      render: (value: number[]) => {
        const skills = value?.map(id => skillsMap?.get(id) || '');

        return skills.map((name: string) => (
          <div><Typography.Text>{name ?? <>&mdash;</>}</Typography.Text></div>
        ));
      }
    },
    {
      key: TableColumns.Utilization,
      title: 'Utilization (%)',
      dataIndex: TableColumns.Utilization,
      render: (value: number, record: ISchoolTherapistListElementData) =>  <Typography.Text>{ value !== null ? `${parseUtilisation(value)}%` : <>&mdash;</> }</Typography.Text>
    },
    {
      key: TableColumns.Schedule,
      title: 'Latest Generated Schedule',
      dataIndex: TableColumns.Schedule,
      render: (value: string, record: ISchoolTherapistListElementData) => value ? <Link to={`${urls.scheduleCalendar(value)}?school_id=${schoolDetails?.id}&therapist_id=${record.key}`} title={value}>View Schedule</Link> : <>&mdash;</>
    },
    {
      key: TableColumns.Actions,
      title: 'Actions',
      dataIndex: TableColumns.Actions,
      render: (value: string, record: ISchoolTherapistListElementData) => value ? <div>
        <Link to={urls.therapistDetails(value)} style={{ marginRight: '14px' }}>View</Link>
        {' '}
        <Link to={urls.therapistEdit(value)} title={value}>Edit</Link>
      </div> : <>&mdash;</>
    }
  ].filter(Boolean) as ColumnsType<ISchoolTherapistListElementData>), [ skillsMap, schoolDetails ]);

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
  }), [ page, total, pageSize ]);

  return (
    <SchoolTherapistList
      tableColumns={tableColumns}
      tableData={tableData}
      pagination={pagination}
      changePage={changePage}
      loading={status.request}
    />
  );
};

export default SchoolTherapistListContainer;


export interface ISchoolTherapistListElementData {
  key: React.Key;
  [TableColumns.Name]: string;
  [TableColumns.Skills]: number[];
  [TableColumns.Utilization]: number;
  [TableColumns.Schedule]: string;
  [TableColumns.Actions]: string;
}
