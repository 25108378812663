import { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { useAjaxStatus } from 'src/hooks';
import { useValidationSchema } from '../hooks';
import { useTherapistDetailsContext } from '../../containers/DetailsProvider';
import Form from '../components/Form';

import { therapist as TherapistService } from 'src/services';

import { createTherapistAvailability, prepareOrganizations, prepareUpdateFormData } from '../utils';
import { FormFields } from '../enums';
import { TherapistFormValues } from '../types';
import { ApiError } from 'src/modules/ErrorContext/models';
import { useTherapistSkillsOptions } from 'src/common/hooks/useTherapistSkillsOptions';


const TherapistEditFormContainer = (props: ITherapistEditFormContainerProps) => {
  const { onCancel, onUpdated } = props;

  const { therapistDetails } = useTherapistDetailsContext();

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const { options: skillsOptions } = useTherapistSkillsOptions();

  const updateTherapist = useCallback(async (values: TherapistFormValues) => {
    setRequest();

    try {
      const id = values[FormFields.Id]!;
      const [ updateTherapist ] = TherapistService.updateTherapist(prepareUpdateFormData(values));

      await updateTherapist();

      setSuccess();

      if (typeof onUpdated === 'function') {
        onUpdated({
          id,
          name: values[FormFields.FirstName],
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onUpdated ]);

  const handleSubmit = useCallback((values: TherapistFormValues) => updateTherapist(values), [ updateTherapist ]);

  const validationSchema = useValidationSchema();
  const editableFields = new Set([
    FormFields.FirstName,
    FormFields.LastName,
    FormFields.Organization,
    FormFields.Skills,
    FormFields.PauseBetweenSessions
  ]);

  const initialValues = useMemo<TherapistFormValues>(() => {
    if (!therapistDetails) {
      return {
        [FormFields.Id]: null,
        [FormFields.FirstName]: '',
        [FormFields.LastName]: '',
        [FormFields.PauseBetweenSessions]: 0,
        [FormFields.Organization]: [null],
        [FormFields.Skills]: [],
        [FormFields.CustomAvailability]: false,
        [FormFields.Availability]: [],
      };
    }

    const {
      id,
      firstName,
      lastName,
      schools,
      skills,
      pauseBetweenSessions,
      availability
    } = therapistDetails;

    return {
      [FormFields.Id]: id,
      [FormFields.FirstName]: firstName,
      [FormFields.LastName]: lastName,
      [FormFields.Organization]: prepareOrganizations(schools),
      [FormFields.Skills]: skills,
      [FormFields.PauseBetweenSessions]: pauseBetweenSessions,
      [FormFields.CustomAvailability]: Boolean(availability?.length),
      [FormFields.Availability]: createTherapistAvailability(availability),
    };
  }, [ therapistDetails ]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {
        () => (
          <>
            <Form
              status={status}
              editableFields={editableFields}
              onCancel={onCancel}
              skillsOptions={skillsOptions}
              validationSchema={validationSchema}
            />
          </>
        )
      }

    </Formik>
  );
};

export default TherapistEditFormContainer;


/* HELPERS */

interface ITherapistEditFormContainerProps extends ICommonProps {
  onCancel?(): void;
  onUpdated?(therapistEntity: IEntity): void;
}
