import { FormFields } from './enums'
import { CreatePrescriptionFormValues } from './types'

export function prepareCreateFormData(values: CreatePrescriptionFormValues) {
  return values?.[FormFields.Prescriptions]?.map(prescription => ({
    days: Number(prescription?.days),
    sessions: prescription?.sessions
  }))
}

