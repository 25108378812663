import React, { useMemo } from 'react';
import { Checkbox, Space } from 'antd';
import { CalendarFilterFields } from 'src/features/Timetable/Details/enums';


const TimetableCalendarFiltersContainer = (props: ITimetableCalendarFiltersContainerProps) => {

  const { filters, onChangeSchool, onChangeTherapist } = props;

  const schoolsArray = useMemo(() => {
    return [...filters[CalendarFilterFields.School].keys()].map(key => ({
      ...filters[CalendarFilterFields.School].get(key)
    }));
  }, [ filters ]);

  return (
    <div>
      {
        schoolsArray.map((school, idx) => {
          return (
            <div key={idx}>
              <div style={{ verticalAlign: 'middle', marginBottom: '24px' }}>
                <Checkbox
                  name={`${school.id}.checked`}
                  checked={school.checked}
                  onChange={onChangeSchool(school.id)}
                >
                  {school.name}
                </Checkbox>

                <div style={{ marginLeft: '20px' }}>
                  <Space direction='vertical'>
                    {[...school.therapists.keys()].map((therapistId: any, idx: number) => (
                      <div key={idx}>
                        <Checkbox
                          name={`${school.id}.${therapistId}.checked`}
                          checked={school.therapists.get(therapistId).checked}
                          onChange={onChangeTherapist(school.id, therapistId)}
                        >
                          {`${school.therapists.get(therapistId).firstName} ${school.therapists.get(therapistId).lastName}`}
                        </Checkbox>
                      </div>
                    ))}
                  </Space>
                </div>
              </div>
            </div>
        );
        })
      }
    </div>);
};

export default TimetableCalendarFiltersContainer;


/* HELPERS */

interface ITimetableCalendarFiltersContainerProps extends ICommonProps {
  filters: Record<string, any>;
  onChangeSchool(schoolId: string): (value: any) => void;
  onChangeTherapist(schoolId: string, therapistId: string): (value: any) => void;
}
