export const enum FormFields {
  Id = 'id',
  FirstName = 'firstName',
  LastName = 'lastName',
  PauseBetweenSessions = 'pauseBetweenSessions',
  Organization = 'organization',
  Skills = 'skills',
  CustomAvailability = 'customAvailability',
  Availability = 'availability',
}
