import React from 'react';
import { useMemo } from 'react';
import { useSessionListContext } from './context';

import {
  Space,
  Typography }               from 'antd';
import { CheckOutlined }     from '@ant-design/icons';
import { Link }              from 'react-router-dom';

import SessionList               from '../components/SessionList';

import { format }            from 'date-fns';

import type { ColumnsType }  from 'antd/es/table';
import {
  SortFields,
  TableColumns }             from '../enums';

import urls                  from 'src/urls';
import { useSchoolListContext } from 'src/features/School/List/containers/context';
import { SchoolListElement } from 'src/features/School/List/models/SchoolListElement';


const SessionListContainer = () => {
  const {
    status,
    collection,
    total,
  } = useSessionListContext();


  const { collection: schoolCollection, status: schoolStatus } = useSchoolListContext();
  const schoolMap = useMemo(() => schoolCollection.reduce((arr, element) => {
    const { id } = element;

    arr.set(id, element);

    return arr;
  }, new Map<string, SchoolListElement>()), [
    schoolCollection,
  ]);

  const tableData = useMemo(() => collection.reduce((arr, element) => {
    const { id, name, description, duration, schoolId, need, group, online, preparingTime, finishingTime } = element;

    arr.push({
      key: id,
      [TableColumns.Name]: name,
      [TableColumns.Description]: description,
      [TableColumns.Duration]: duration,
      [TableColumns.SchoolId]: schoolId,
      [TableColumns.PreparingTime]: preparingTime,
      [TableColumns.FinishingTime]: finishingTime,
      [TableColumns.Need]: need,
      [TableColumns.Group]: group,
      [TableColumns.Online]: online,
    });

    return arr;
  }, [] as ISessionListElementData[]), [
    collection,
  ]);

  const tableColumns: ColumnsType<ISessionListElementData> = useMemo(() => ([
    {
      key: TableColumns.Name,
      title: 'Name',
      dataIndex: TableColumns.Name,
      render: (value: string, record: ISessionListElementData) => <Link to={urls.sessionDetails(record.key)}>{ value }</Link>
    },
    {
      key: TableColumns.Description,
      title: 'Description',
      dataIndex: TableColumns.Description,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.SchoolId,
      title: 'School',
      dataIndex: TableColumns.SchoolId,
      render: (value: Nullable<string>) => <Typography.Text>{ schoolMap?.get(`${value}`)?.name ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.Duration,
      title: 'Duration',
      dataIndex: TableColumns.Duration,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.PreparingTime,
      title: 'PreparingTime',
      dataIndex: TableColumns.PreparingTime,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.FinishingTime,
      title: 'FinishingTime',
      dataIndex: TableColumns.FinishingTime,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.Need,
      title: 'Need',
      dataIndex: TableColumns.Need,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.Group,
      title: 'Group',
      dataIndex: TableColumns.Group,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?  <CheckOutlined /> : <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.Online,
      title: 'Online',
      dataIndex: TableColumns.Online,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?  <CheckOutlined /> : <>&mdash;</> }</Typography.Text>,
    },
  ].filter(Boolean) as ColumnsType<ISessionListElementData>), [schoolMap]);


  return (
    <SessionList
      tableColumns={tableColumns}
      tableData={tableData}
      loading={status.request}
    />
  );
};

export default SessionListContainer;


export interface ISessionListElementData {
  key: React.Key;
  [TableColumns.Name]: string;
  [TableColumns.Description]:string;
  [TableColumns.Duration]: number;
  [TableColumns.PreparingTime]: number;
  [TableColumns.FinishingTime]: number;
  [TableColumns.SchoolId]: string;
  [TableColumns.Need]: number;
  [TableColumns.Group]: boolean;
  [TableColumns.Online]: boolean;
}
