import { Organization } from 'src/models/Organization';
import { OrganizationWithParent } from './types';
import { OrganizationType } from 'src/enums';


export function getOrganizationParts(organization: Nullable<OrganizationWithParent>): { district: Nullable<Organization>, school: Nullable<Organization> } {
  if (!organization) {
    return {
      district: null,
      school: null,
    };
  }

  const district = organization.type === OrganizationType.District ? Organization.fromJS(organization) : null;
  const school = organization.type === OrganizationType.School ? Organization.fromJS(organization) : null;

  return {
    district,
    school,
  };
}
