import Immutable                  from 'immutable';
import React, { SyntheticEvent }  from 'react';
import { useBem }                 from '@seaneiros/react-bem';

import {
  Row,
  Col,
  Input,
  Select }                        from 'antd';
import * as OrganizationSearch    from 'src/modules/OrganizationSearch';

import { getOrganizationParts }   from 'src/modules/OrganizationSearch/utils';
import { Organization }           from 'src/models/Organization';
import { StudentListFiltersValues }     from '../types';
import { FilterFields }           from '../enums';

import './Filters.scss';


const StudentListFilters = (props: IStudentListFiltersProps) => {
  const {
    filterValues,
    onSearchByName,
    onFilterByOrganization,
  } = props;

  const organizationFilterValue = filterValues.get(FilterFields.Organization);

  const bem = useBem({ block: 'student-list-filters' }, props);

  return (
    <Row gutter={[ 16, 16 ]} align="middle" style={{ marginBottom: '24px' }}>
      <OrganizationSearch.Provider
        onChange={onFilterByOrganization}
        initialValues={organizationFilterValue}
      >
        <Col xs={{ span: 24 }} sm={{ span: 6 }} className={bem.element('field-container')}>
          <OrganizationSearch.District placeholder={'District'} className={bem.element('search')} />
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 6 }} className={bem.element('field-container')}>
          <OrganizationSearch.School placeholder={'School'} className={bem.element('search')} />
        </Col>
      </OrganizationSearch.Provider>

      <Col xs={{ span: 24 }} sm={{ span: 6 }} className={bem.element('field-container')}>
        <Input placeholder={'Name'} value={filterValues.get(FilterFields.Name)} onChange={onSearchByName} allowClear />
      </Col>
    </Row>
  );
};

export default React.memo(StudentListFilters);


/* HELPERS */

interface IStudentListFiltersProps extends ICommonProps {
  filterValues: Immutable.RecordOf<StudentListFiltersValues>;
  onSearchByName(e: SyntheticEvent<HTMLInputElement>): void;
  onFilterByOrganization(value: Nullable<Organization>): void;
}
