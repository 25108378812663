import { useMemo, useState } from 'react';
import { Card, Space, Typography, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ScheduleCreatePage from './ScheduleCreate';
import ScheduleUploadPage from './ScheduleUpload';
import ScheduleListPage from './SheduleList';


const SchedulePage = () => {
  const [ activeTab, setActiveTab ] = useState('generateSchedule');

  const scheduleTabs = useMemo(() => {
    const tabs: TabsProps['items'] = [];

    tabs.push({
      label: 'Generate a Schedule',
      key: 'generateSchedule',
      children: (
        <ScheduleCreatePage />
      )
    });

    tabs.push({
      label: 'Upload a Data File',
      key: 'uploadSchedule',
      children: (
        <ScheduleUploadPage setActiveTab={setActiveTab} />
      )
    });

    tabs.push({
      label: 'Schedules',
      key: 'listSchedule',
      children: (
        <ScheduleListPage />
      )
    });

    return tabs;
  }, [ ]);

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <Card title={<Typography.Title level={2}>Schedule</Typography.Title>}>
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <Tabs
          activeKey={activeTab}
          onChange={onChange}
          items={scheduleTabs}
        />
      </Space>
    </Card>
  );
};

export default SchedulePage;
