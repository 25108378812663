import { useCallback } from 'react';
import { useAjaxStatus } from 'src/hooks';
import { useValidationSchema } from '../hooks';

import { Formik } from 'formik';
import Form from '../components/Form';

import { timetable as TimetableService } from 'src/services';

import { prepareCreateFormData } from '../utils';
import { FormFields } from '../enums';
import { CreateTimetableFormValues } from '../types';
import { ApiError } from 'src/modules/ErrorContext/models';


const TimetableCreateFormContainer = (props: ITimetableCreateFormContainerProps) => {
  const { onCancel, onCreated } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const validationSchema = useValidationSchema();

  const createTimetable = useCallback(async (values: CreateTimetableFormValues) => {
    setRequest();

    try {
      const [ createTimetable ] = TimetableService.createTimetable(prepareCreateFormData(values));

      const id = await createTimetable();

      setSuccess();

      if (typeof onCreated === 'function') {
        onCreated({
          id,
          name: values[FormFields.Name],
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onCreated ]);


  return (
    <Formik
      initialValues={{
        [FormFields.Name]: '',
        [FormFields.Organization]: [null],
        [FormFields.StartDate]: '',
        [FormFields.EndDate]: '',
      }}
      validationSchema={validationSchema}
      onSubmit={createTimetable}
    >
      <Form
        status={status}
        onCancel={onCancel}
      />
    </Formik>
  );
};

export default TimetableCreateFormContainer;


/* HELPERS */

interface ITimetableCreateFormContainerProps extends ICommonProps {
  onCancel?(): void;
  onCreated?(schoolEntity: IEntity): void;
}
