import React, { useMemo, useRef }  from 'react';
import { useFormikContext }        from 'formik';
import * as Yup                    from 'yup';

import { useBem }                  from '@seaneiros/react-bem';

import {
  Form,
  Input,
  Checkbox,
  Select,
  SubmitButton,
  TimePicker }                     from 'formik-antd';
import { FieldArray }              from 'formik';
import {
  Flex,
  Row,
  Col,
  Grid,
  Space,
  Alert,
  Button }                         from 'antd';
import * as OrganizationSearch     from 'src/modules/OrganizationSearch';
import { OrganizationWithParent }  from 'src/modules/OrganizationSearch/types';
import Toggle                      from 'src/components/Toggle';

import { FormFields }              from '../enums';
import { StudentFormValues }       from '../types';

import './Form.scss';


const { useBreakpoint } = Grid;

const StudentForm = (props: IStudentFormProps) => {
  const {
    status,
    editableFields,
    onCancel,
    validationSchema,
    skillsOptions,
  } = props;

  const {
    values,
    initialValues,
    touched,
    errors,
    resetForm,
    isValid,
    setFieldValue,
  } = useFormikContext<StudentFormValues>();

  const organizationRequired = useMemo(() => {
    try {
      validationSchema.validateSyncAt(FormFields.Organization, { [FormFields.Organization]: initialValues[FormFields.Organization] });
      return false;
    } catch (e) {
      return true;
    }
  }, [ validationSchema, initialValues ]);

  const existingStudent = !!values[FormFields.Id];

  const activeScreens = useBreakpoint();

  const { current: changeOrganizationField } = useRef((val: OrganizationWithParent) => setFieldValue(FormFields.Organization, val));

  const bem = useBem({ block: 'student-form' }, props);

  return (
    <Form name={'student-create-form'} className={bem.block()}>
      <OrganizationSearch.Provider onChange={changeOrganizationField} initialValues={initialValues[FormFields.Organization]}>
        <Form.Item
          name={FormFields.Organization}
          hasFeedback={false}
          preserve={false}
          help={null}
        >
          <OrganizationSearch.District
            placeholder={organizationRequired ? 'District*' : 'District'}
            labelInValue
          />
        </Form.Item>

        <Form.Item
          name={FormFields.Organization}
          hasFeedback={false}
          preserve={false}
        >
          <OrganizationSearch.School
            placeholder={organizationRequired ? 'School*' : 'School'}
            labelInValue
          />
        </Form.Item>
      </OrganizationSearch.Provider>

      <Form.Item name={FormFields.FirstName} hasFeedback={false}>
        <Input name={FormFields.FirstName} placeholder="First Name*" disabled={!editableFields.has(FormFields.FirstName)} />
      </Form.Item>

      <Form.Item name={FormFields.LastName} hasFeedback={false}>
        <Input name={FormFields.LastName} placeholder="Last Name*" disabled={!editableFields.has(FormFields.LastName)} />
      </Form.Item>

      <Space direction="vertical" size="large" style={{ width: '100%', marginBottom: '32px' }}>
        <FieldArray name={FormFields.Needs}>
          {
            ({ remove, push }) => (
              <Flex gap="unset" vertical>
                {
                  values[FormFields.Needs]?.map((_, idx) => (
                    <Row gutter={[ 8, 8 ]} key={idx}>
                      <Col span={6}>
                        <Form.Item name={`${FormFields.Needs}.${idx}.id`} hasFeedback={false}>
                          <Select name={`${FormFields.Needs}.${idx}.id`} placeholder="Needs" options={skillsOptions} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item name={`${FormFields.Needs}.${idx}.hours`} hasFeedback={false}>
                          <Input name={`${FormFields.Needs}.${idx}.hours`} placeholder="Number of hours per a need" />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item name={`${FormFields.Needs}.${idx}.online`} style={{ marginBottom: 0 }}>
                          <Checkbox
                            name={`${FormFields.Needs}.${idx}.online`}
                          >
                            May be online
                          </Checkbox>
                        </Form.Item>

                        <Form.Item name={`${FormFields.Needs}.${idx}.group`}>
                          <Checkbox
                            name={`${FormFields.Needs}.${idx}.group`}
                          >
                            May be in a group
                          </Checkbox>
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Button type="dashed" onClick={() => remove(idx)}>
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  ))
                }

                <Toggle show={values[FormFields.Needs].length < 2}>
                  <Space size={'middle'} direction="vertical">
                    <Button
                      type="dashed"
                      onClick={() => push({ need: null,  hours: '', online: false, group: false })}
                    >
                      Add Needs
                    </Button>
                  </Space>
                </Toggle>
              </Flex>
            )
          }
        </FieldArray>
      </Space>

      <Form.Item name={FormFields.CustomAvailability}>
        <Checkbox
          name={FormFields.CustomAvailability}
        >
          {'Availability is different than the school hours'}
        </Checkbox>
      </Form.Item>

      <Toggle show={values[FormFields.CustomAvailability]}>
        <h2>Study Hours</h2>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <FieldArray name={FormFields.Availability}>
            {
              ({ remove, push }) => (
                <Flex gap="unset" vertical>
                  {
                    values[FormFields.Availability]?.map((availability, idx) => (
                      <Row gutter={[ 8, 8 ]} key={idx}>
                        <Col span={6} style={{ paddingTop: '6px' }}>
                          <Checkbox
                            name={`${FormFields.Availability}.${idx}.checked`}
                          >
                            {availability.day}
                          </Checkbox>
                        </Col>
                        <Col span={4}>
                          <Form.Item name={`${FormFields.Availability}.${idx}.start`} hasFeedback={false}>
                            <TimePicker minuteStep={15} use12Hours format="h:mm a" disabled={!values[FormFields.Availability][idx].checked} name={`${FormFields.Availability}.${idx}.intervals.start`} placeholder="From HH:MM" />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item name={`${FormFields.Availability}.${idx}.end`} hasFeedback={false}>
                            <TimePicker minuteStep={15} use12Hours format="h:mm a" disabled={!values[FormFields.Availability][idx].checked} name={`${FormFields.Availability}.${idx}.intervals.end`} placeholder="Till HH:MM" />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))
                  }
                </Flex>
              )
            }
          </FieldArray>
        </Space>
      </Toggle>

      <Space direction="vertical" size="middle" style={{ display: 'flex', marginTop: '50px' }}>
        <Toggle show={!!status.failure}>
          <Alert type="error" message={(status as AjaxFailureStatus).failure.message} closable />
        </Toggle>

        <Toggle show={status.success}>
          <Alert
            type="success"
            message={existingStudent ? 'Student updated' : 'Student added'}
            closable
          />
        </Toggle>

        <Space>
          <SubmitButton loading={status.request} block={!activeScreens['sm']}>
            { existingStudent ? 'Save' : 'Add' }
          </SubmitButton>

          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default StudentForm;


/* HELPERS */

interface IStudentFormProps extends ICommonProps {
  status: IAjaxStatus;
  editableFields: Set<FormFields>;
  onCancel?(): void;
  skillsOptions: IOption[];
  validationSchema: Yup.AnyObjectSchema;
}
