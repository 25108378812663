import { useFormikContext } from 'formik';

import {
  Form,
  Input,
  Select,
  Checkbox,
  SubmitButton } from 'formik-antd';
import {
  Grid,
  Space,
  Alert,
  Button,
} from 'antd';
import Toggle from 'src/components/Toggle';

import { FormFields } from '../enums';
import { SessionFormValues } from '../types';


const { useBreakpoint } = Grid;

const SessionForm = (props: ISessionFormProps) => {
  const {
    status,
    editableFields,
    needOptions,
    schoolsOptions,
    onCancel
  } = props;

  const {
    values,
    resetForm,
    setFieldValue,
    initialValues
  } = useFormikContext<SessionFormValues>();
  const existingSession = !!values[FormFields.Id];

  const activeScreens = useBreakpoint();


  return (
    <Form name={'session-create-form'}>
      <Form.Item name={FormFields.Name} hasFeedback={false}>
        <Input name={FormFields.Name} placeholder="Name*" />
      </Form.Item>

      <Form.Item name={FormFields.Description} hasFeedback={false}>
        <Input name={FormFields.Description} placeholder="Description" />
      </Form.Item>

      <Form.Item name={FormFields.Duration} hasFeedback={false}>
        <Input name={FormFields.Duration} placeholder="Duration" />
      </Form.Item>

      <Form.Item name={FormFields.PreparingTime} hasFeedback={false}>
        <Input type={'number'} name={FormFields.PreparingTime} placeholder="PreparingTime" />
      </Form.Item>

      <Form.Item name={FormFields.FinishingTime} hasFeedback={false}>
        <Input type={'number'} name={FormFields.FinishingTime} placeholder="FinishingTime" />
      </Form.Item>

      <Form.Item name={FormFields.Need}>
        <Select options={needOptions} name={FormFields.Need} placeholder="Need" />
      </Form.Item>

      <Form.Item name={FormFields.Group} hasFeedback={false}>
        <Checkbox name={FormFields.Group}>Group</Checkbox>
      </Form.Item>

      <Form.Item name={FormFields.Online} hasFeedback={false}>
        <Checkbox name={FormFields.Online}>Online</Checkbox>
      </Form.Item>

      <Form.Item name={FormFields.SchoolId}>
        <Select options={schoolsOptions} name={FormFields.SchoolId} placeholder="School" />
      </Form.Item>

      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Toggle show={!!status.failure}>
          <Alert type="error" message={(status as AjaxFailureStatus).failure.message} closable />
        </Toggle>

        <Toggle show={status.success}>
          <Alert
            type="success"
            message={existingSession ? 'Session updated' : 'Session created'}
            closable
          />
        </Toggle>

        <Space>
          <SubmitButton loading={status.request} block={!activeScreens['sm']}>
            { existingSession ? 'Save' : 'Create' }
          </SubmitButton>

          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default SessionForm;


/* HELPERS */

interface ISessionFormProps extends ICommonProps {
  status: IAjaxStatus;
  editableFields: Set<FormFields>;
  needOptions: IOption[];
  schoolsOptions: IOption[];
  onCancel?(): void;
}
