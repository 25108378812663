import { useEffect, useState } from 'react';
import {
  useAjaxStatus,
  useRequestKey,
} from 'src/hooks';

import { student as StudentService } from 'src/services';
import { StudentDetails } from '../models/StudentDetails';
import { ApiError } from 'src/modules/ErrorContext/models';


export const useStudentDetails = (id: string) => {
  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();
  const { engage, isCurrentRequest } = useRequestKey();

  const [ studentDetails, setStudentDetails ] = useState<Nullable<StudentDetails>>(null);

  useEffect(() => {
    const [ loadDetails, abort ] = StudentService.getStudentDetails(id);
    const key = engage();

    setRequest();

    loadDetails()
      .then(data => {
        if (isCurrentRequest(key)) {
          setStudentDetails(StudentDetails.fromJS(data));
          setSuccess();
        }
      })
      .catch((err: ApiError) => {
        if (isCurrentRequest(key)) {
          setFailure(err);
        }
      });


    return () => abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id ]);

  return {
    studentDetails,
    status
  };
};
