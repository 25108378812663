import React                        from 'react';
import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback }                     from 'react';

import { Organization }             from 'src/models/Organization';
import { IOrganizationOption }      from './types';

import { OrganizationType }         from 'src/enums';
import { OrganizationWithParent }   from 'src/modules/OrganizationSearch/types';


export const OrganizationSearchContext = React.createContext<OrganizationSearchData>({
  districtValue: null,
  schoolValue: null,
  setDistrictValue: () => void(0),
  setSchoolValue: () => void(0),
});
OrganizationSearchContext.displayName = 'OrganizationSearchContext';


export const useOrganizationSearchContext = () => useContext(OrganizationSearchContext);

export const OrganizationSearchProvider = (props: React.PropsWithChildren<IOrganizationSearchProps>) => {
  const {
    children,
    onChange,
    visibleSearchFields,
    districtValue,
    schoolValue,
    fieldId,
  } = props;

  const availableFields = useMemo(() => new Set([
    OrganizationType.District,
    OrganizationType.School,
  ]), []);

  const [ searchState, setSearchState ] = useState(() => initializeSearch(props));

  const setDistrictValue = useCallback((option: Nullable<IOrganizationOption>) => setSearchState(({ district, school }) => {
    if (!option) {
      return {
        district: null,
        school: null,
      };
    }

    const { label: name, value: id } = option;
    const districtChanged = id !== district?.id;

    if (!districtChanged) {
      return { district, school };
    }

    return {
      district: Organization.fromJS({ id, name, type: OrganizationType.District }),
      school: null,
    };
  }), []);

  const setSchoolValue = useCallback((option: Nullable<IOrganizationOption>) => setSearchState(({ district, school }) => {
    if (!option) {
      return {
        district,
        school: null,
      };
    }

    const { label: name, value: id, parent } = option;

    const schoolChanged = id !== school?.id;
    const districtChanged = parent?.value !== district?.id;

    if (!schoolChanged) {
      return { district, school };
    }

    return {
      district: districtChanged
        ? parent ? Organization.fromJS({ id: parent.value, name: parent.label, type: OrganizationType.District }) : null
        : district,
      school: Organization.fromJS({ id, name, type: OrganizationType.School }),
    };
  }), []);

  const availableSearchFields = useMemo<Set<OrganizationType>>(() => {
    const intersection = new Set<OrganizationType>();

    availableFields.forEach(type => {
      if (visibleSearchFields!.has(type)) {
        intersection.add(type);
      }
    });

    return intersection;
  }, [ availableFields, visibleSearchFields ]);

  useEffect(() => {
    setSearchState(({ district, school }) => {
      const districtValueIsDefined = districtValue !== undefined;
      const schoolValueIsDefined = schoolValue !== undefined;

      const districtChanged = districtValue?.id !== district?.id;
      const schoolChanged = schoolValue?.id !== school?.id;

      return {
        district: districtValueIsDefined && districtChanged ? districtValue : district,
        school: schoolValueIsDefined && schoolChanged ? schoolValue : school,
      };
    });
  }, [ districtValue, schoolValue ]);

  const searchValue: Nullable<OrganizationWithParent> = useMemo(() => {
    const { district, school } = searchState;
    const schoolValue = availableSearchFields.has(OrganizationType.School) ? school : null;
    const districtValue = availableSearchFields.has(OrganizationType.District) ? district : null;
    const selectedValue = schoolValue
      ? { ...schoolValue, parent: districtValue }
      : districtValue ? { ...districtValue, parent: null } : null;

    return selectedValue;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    availableSearchFields,
    searchState.district?.id,
    searchState.school?.id,
  ]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      if (fieldId) {
        onChange(searchValue, fieldId);
      } else {
        onChange(searchValue);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onChange, searchValue?.id, fieldId ]);

  const contextValue = useMemo(() => ({
    districtValue: searchState.district,
    schoolValue: searchState.school,
    setDistrictValue,
    setSchoolValue,
    availableSearchFields,
  }), [
    searchState,
    availableSearchFields,
    setDistrictValue,
    setSchoolValue,
  ]);

  return (
    <OrganizationSearchContext.Provider value={contextValue}>
      { children }
    </OrganizationSearchContext.Provider>
  );
};

OrganizationSearchProvider.defaultProps = {
  initialValues: {
    district: null,
    school: null,
  },
  visibleSearchFields: new Set([
    OrganizationType.District,
    OrganizationType.School,
  ]),
};

/* HELPERS */

type OrganizationSearchState = {
  district: Nullable<Organization>;
  school: Nullable<Organization>;
};

interface IOrganizationSearchProps {
  initialValues: OrganizationSearchState | Nullable<OrganizationWithParent>;
  onChange?(value: Nullable<OrganizationWithParent>, fieldId?: string): void;
  visibleSearchFields?: Set<OrganizationType>;
  districtValue?: Nullable<Organization>;
  schoolValue?: Nullable<Organization>;
  fieldId?: string;
}

type OrgValue = Nullable<Organization>;

type OrganizationSearchData = {
  districtValue: OrgValue;
  schoolValue: OrgValue;
  setDistrictValue(val: IOrganizationOption): void;
  setSchoolValue(val: IOrganizationOption): void;
};

function initializeSearch(props: IOrganizationSearchProps) {
  const {
    initialValues,
    districtValue,
    schoolValue
  } = props;

  if (!initialValues) {
    return {
      district: districtValue ?? null,
      school: schoolValue ?? null,
    };
  }

  let values;

  if (isStateLikeValue(initialValues)) {
    values = initialValues;
  } else {
    values = convertToSearchValue(initialValues);
  }

  return {
    district: districtValue === undefined ? values.district : districtValue,
    school: schoolValue === undefined ? values.school : schoolValue,
  };
}

function isStateLikeValue(val: any): val is OrganizationSearchState {
  return val.district !== undefined && val.facility !== undefined;
}

function convertToSearchValue(organization: Nullable<OrganizationWithParent>) {
  switch(organization?.type) {
    case OrganizationType.School:
      return {
        district: organization.parent,
        school: organization,
      };
    case OrganizationType.District:
      return {
        district: organization,
        school: null,
      };
    default:
      return {
        district: null,
        school: null,
      };
  }
}
