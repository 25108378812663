import { List, RecordOf } from 'immutable';

import { SchoolListElementDto } from 'src/services/api/school/school.dto';
import { SchoolListElement } from '../../models/SchoolListElement';
import { FilterFields } from '../../enums';
import { IPagination } from '@k12-frontend/core';
import { ISchoolListCriteria  } from 'src/services/api/school/types';
// import {convertToQueryParam} from '@k12-frontend/utils/sort'
import { SchoolsListFilters } from 'src/features/School/List/types';

export function createCriteria(filters: RecordOf<SchoolsListFilters>, pagination: IPagination): ISchoolListCriteria {
  const district = filters.get(FilterFields.Organization);
  const keyword = filters.get(FilterFields.Name);

  return {
    page: pagination.page - 1,
    limit: pagination.pageSize,
    districtId: district ? district.id : undefined,
    keyword: keyword ?? undefined,
    // sort: [ convertToQueryParam(sort) ],
  };
}


export function parseSchoolList(items: SchoolListElementDto[]): List<SchoolListElement> {
  return List(items.map(item => {
    const {
      id,
      name,
      districtId,
      districtName,
    } = item;

    return new SchoolListElement(
      id,
      name,
      districtId,
      districtName,
    );
  }));
}
