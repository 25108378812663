export class TherapistListElement {
  constructor(
    readonly id: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly pauseBetweenSessions: number,
    readonly skills: number[],
    readonly schools: TherapistSchool[],
    readonly utilization: number,
    readonly timetableId: string,
  ) {}

  get name() {
    return TherapistListElement.createNameString({
      firstName: this.firstName,
      lastName: this.lastName,
    });
  }

  static createNameString(name: {
    firstName: Nullable<string>,
    lastName: Nullable<string>,
  }) {
    return [
      name.firstName,
      name.lastName,
    ].filter(Boolean).join(' ');
  }
}

export type TherapistSchool = {
  id: string,
  name: string,
  districtId: string,
  districtName: string,
}
