import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Space,
  Typography } from 'antd';
import urls from 'src/urls';
import { StudentListProvider } from 'src/features/Student/List/containers/context';
import StudentList from 'src/features/Student/List/containers/StudentList';
import StudentListFilters from 'src/features/Student/List/containers/StudentListFilters';
import { SkillListProvider } from 'src/features/Skill/List/containers/context';


const StudentPage = () => {
  const navigate = useNavigate();

  const createButton = <Button onClick={() => navigate(urls.studentCreate())} type="primary">Add a Student</Button>;

  return (
    <Card
      title={<Typography.Title level={2}>Students</Typography.Title>}
      extra={
        <Space size="middle">
          { createButton }
        </Space>
      }
    >
      <div>
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <h2>List of Students</h2>
          <StudentListProvider>
          <SkillListProvider>
            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
              <StudentListFilters />
              <StudentList />
            </Space>
          </SkillListProvider>
          </StudentListProvider>
        </Space>
      </div>
    </Card>
  );
};

export default StudentPage;
