import Immutable                  from 'immutable';
import React, { SyntheticEvent }  from 'react';
import { useBem }                 from '@seaneiros/react-bem';

import {
  Row,
  Col,
  Input }                         from 'antd';

import { DistrictListFiltersValues } from '../types';
import { FilterFields }           from '../enums';

import './Filters.scss';


const DistrictListFilters = (props: IDistrictListFiltersProps) => {
  const {
    filterValues,
    onSearchByName,
  } = props;

  const bem = useBem({ block: 'district-list-filters' }, props);

  return (
    <Row gutter={[ 16, 16 ]} align="middle" style={{ marginBottom: '24px' }}>
      <Col xs={{ span: 24 }} sm={{ span: 6 }} className={bem.element('field-container')}>
        <Input placeholder={'Name'} value={filterValues.get(FilterFields.Name)} onChange={onSearchByName} allowClear />
      </Col>
    </Row>
  );
};

export default React.memo(DistrictListFilters);


/* HELPERS */

interface IDistrictListFiltersProps extends ICommonProps {
  filterValues: Immutable.RecordOf<DistrictListFiltersValues>;
  onSearchByName(e: SyntheticEvent<HTMLInputElement>): void;
}
