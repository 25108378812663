import { AvailabilityDays } from 'src/constants';
import { Availability } from 'src/models/Availability';


export const initialAvailability: Availability[] = AvailabilityDays.map((weekDay, idx) => Availability.fromJS({
  id: idx + 1,
  day: weekDay,
  checked: false,
  intervals: { start: null, end: null }
}));
