import { ApiIntent, ApiService } from '../types';
import {
  StudentDetailsDto,
  StudentListDto,
  StudentListElementDto,
  StudentPrescriptionDto,
  StudentUpdateDto
} from './student.dto';
import { IStudentListCriteria } from './types';


export class StudentApiService extends ApiService {
  getStudentDetails(id: string): ApiIntent<any> {
    const [ getStudent, abort ] = this.createGetIntent<StudentDetailsDto>(`students/${id}`);

    return [() => getStudent().then(({ data: eopInfo }) => eopInfo), abort];
  }

  getStudentList(criteria?: IStudentListCriteria): ApiIntent<{ count?: number; items?: StudentListElementDto[]; }> {
    const [ getStudentList, abort ] = this.createGetIntent<StudentListDto>('students', criteria);

    return [
      () => getStudentList().then(({ data: { count, items } }) => ({ count, items })),
      abort,
    ];
  }

  createStudent(payload: any): ApiIntent<string> {
    const [ createStudent, abort ] = this.createPostIntent<{ id: string; }>('students', payload);

    return [
      () => createStudent().then(({ data: { id } }) => id),
      abort,
    ];
  }

  updateStudent(payload: StudentUpdateDto):ApiIntent<boolean> {
    const [ updateStudent, abort ] = this.createPostIntent<{ id: string; }>(`students`, payload);

    return [
      () => updateStudent().then(() => true),
      abort,
    ];
  }

  getStudentPrescriptions(id: string): ApiIntent<any> {
    const [ getPrescription, abort ] = this.createGetIntent<StudentPrescriptionDto[]>(`students/${id}/prescription`);

    return [() => getPrescription().then(({ data: prescriptionInfo }) => prescriptionInfo), abort];
  }


  createStudentPrescription(id: string, payload: any): ApiIntent<string> {
    const [ createStudent, abort ] = this.createPostIntent<{ id: string; }>(`students/${id}/prescription`, payload);

    return [
      () => createStudent().then(({ data: { id } }) => id),
      abort,
    ];
  }
}
