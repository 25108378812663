import React, { useMemo, useRef }  from 'react';
import { useFormikContext }        from 'formik';
import * as Yup                    from 'yup';

import { useBem }                  from '@seaneiros/react-bem';

import {
  Form,
  Input,
  Checkbox,
  Select,
  SubmitButton,
  TimePicker }                     from 'formik-antd';
import { FieldArray }              from 'formik';
import {
  Flex,
  Row,
  Col,
  Grid,
  Space,
  Alert,
  Button,
} from 'antd';
import * as OrganizationSearch     from 'src/modules/OrganizationSearch';
import { OrganizationWithParent }  from 'src/modules/OrganizationSearch/types';

import Toggle                      from 'src/components/Toggle';

import { FormFields }              from '../enums';
import { SchoolFormValues }        from '../types';


const { useBreakpoint } = Grid;

const SchoolForm = (props: ISchoolFormProps) => {
  const {
    status,
    editableFields,
    onCancel,
    validationSchema
  } = props;

  const {
    values,
    resetForm,
    setFieldValue,
    initialValues
  } = useFormikContext<SchoolFormValues>();
  const existingSchool = !!values[FormFields.Id];

  const organizationRequired = useMemo(() => {
    try {
      validationSchema.validateSyncAt(FormFields.Organization, { [FormFields.Organization]: null });
      return false;
    } catch (e) {
      return true;
    }
  }, [ validationSchema ]);

  const activeScreens = useBreakpoint();

  const { current: changeOrganizationField } = useRef((val: OrganizationWithParent) => setFieldValue(FormFields.Organization, val));

  const bem = useBem({ block: 'school-form' }, props);

  return (
    <Form name={'school-create-form'}  className={bem.block()}>
      <OrganizationSearch.Provider onChange={changeOrganizationField} initialValues={initialValues[FormFields.Organization]}>
        <Form.Item
          name={FormFields.Organization}
          hasFeedback={false}
          preserve={false}
          help={null}
        >
          <OrganizationSearch.District
            style={{ width: '100%' }}
            placeholder={organizationRequired ? 'District*' : 'District'}
            labelInValue
          />
        </Form.Item>
      </OrganizationSearch.Provider>

      <Form.Item name={FormFields.Name} hasFeedback={false}>
        <Input name={FormFields.Name} placeholder="Name*" disabled={!editableFields.has(FormFields.Name)} />
      </Form.Item>

      <h2>Working Days and Hours</h2>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <FieldArray name={FormFields.Availability}>
          {
            ({ remove, push }) => (
              <Flex gap="unset" vertical>
                {
                  values[FormFields.Availability]?.map((availability, idx) => (
                    <Row gutter={[ 8, 8 ]} key={idx}>
                      <Col span={6} style={{ paddingTop: '6px' }}>
                        <Checkbox
                          name={`${FormFields.Availability}.${idx}.checked`}
                        >
                          {availability.day}
                        </Checkbox>
                      </Col>
                      <Col span={4}>
                        <Form.Item name={`${FormFields.Availability}.${idx}.start`} hasFeedback={false}>
                          <TimePicker minuteStep={15} use12Hours format="h:mm a" disabled={!values[FormFields.Availability][idx].checked} name={`${FormFields.Availability}.${idx}.intervals.start`} placeholder="From HH:MM" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item name={`${FormFields.Availability}.${idx}.end`} hasFeedback={false}>
                          <TimePicker minuteStep={15} use12Hours format="h:mm a" disabled={!values[FormFields.Availability][idx].checked} name={`${FormFields.Availability}.${idx}.intervals.end`} placeholder="Till HH:MM" />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))
                }
              </Flex>
            )
          }
        </FieldArray>
      </Space>

      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Toggle show={!!status.failure}>
          <Alert type="error" message={(status as AjaxFailureStatus).failure.message} closable />
        </Toggle>

        <Toggle show={status.success}>
          <Alert
            type="success"
            message={existingSchool ? 'School updated' : 'School added'}
            closable
          />
        </Toggle>

        <Space>
          <SubmitButton loading={status.request} block={!activeScreens['sm']}>
            { existingSchool ? 'Save' : 'Add' }
          </SubmitButton>

          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default SchoolForm;


/* HELPERS */

interface ISchoolFormProps extends ICommonProps {
  status: IAjaxStatus;
  editableFields: Set<FormFields>;
  onCancel?(): void;
  validationSchema: Yup.AnyObjectSchema;
}
