import React, { useRef }    from 'react';
import { useFormikContext } from 'formik';
import { FieldArray }       from 'formik';

import {
  Form,
  Input,
  DatePicker,
  SubmitButton } from 'formik-antd';
import {
  Flex,
  Row,
  Col,
  Grid,
  Space,
  Alert,
  Button }    from 'antd';
import Toggle from 'src/components/Toggle';

import * as OrganizationSearch from 'src/modules/OrganizationSearch';
import { OrganizationWithParent } from 'src/modules/OrganizationSearch/types';

import { FormFields } from '../enums';
import { CreateTimetableFormValues } from '../types';


const { useBreakpoint } = Grid;

const TimetableForm = (props: ITimetableFormProps) => {
  const {
    // schoolsOptions,
    status,
    onCancel
  } = props;

  const {
    values,
    resetForm,
    setFieldValue,
    initialValues
  } = useFormikContext<CreateTimetableFormValues>();

  const organizationRequired = true;

  const activeScreens = useBreakpoint();

  const { current: getFieldName } = useRef((id: number) => `${FormFields.Organization}.${id}`);
  const { current: changeOrganizationField } = useRef((val: OrganizationWithParent, fieldId: string) => setFieldValue(`${fieldId}`, val));

  return (
    <Form name={'timetable-create-form'}>
      <Form.Item name={FormFields.Name} hasFeedback={false}>
        <Input name={FormFields.Name} placeholder="Name*" />
      </Form.Item>

      <Row gutter={[ 8, 8 ]}>
        <Col span={6}>
          <Form.Item name={FormFields.StartDate}>
            <DatePicker name={FormFields.StartDate} placeholder="StartDate*" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={FormFields.EndDate}>
            <DatePicker name={FormFields.EndDate} placeholder="EndDate*" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Space direction="vertical" size="large" style={{ width: '100%', marginBottom: '16px' }}>
        <FieldArray name={FormFields.Organization}>
          {
            ({ remove, push }) => (
              <Flex gap="unset" vertical>
                {
                  values[FormFields.Organization]?.map((_, idx) => {
                    return (
                      <section key={idx}>
                        <OrganizationSearch.Provider
                          onChange={changeOrganizationField}
                          initialValues={initialValues[FormFields.Organization][idx]}
                          fieldId={getFieldName(idx)}
                        >
                          <Row gutter={[8, 8]} key={idx}>
                            <Col span={6}>
                              <Form.Item
                                name={`${FormFields.Organization}.${idx}`}
                                hasFeedback={false}
                                preserve={false}
                                help={null}
                              >
                                <OrganizationSearch.District
                                  style={{ width: '100%' }}
                                  placeholder={organizationRequired ? 'District*' : 'District'}
                                  labelInValue
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={`${FormFields.Organization}.${idx}`}
                                hasFeedback={false}
                                preserve={false}
                              >
                                <OrganizationSearch.School
                                  style={{ width: '100%' }}
                                  placeholder={organizationRequired ? 'School*' : 'School'}
                                  labelInValue
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Button type="dashed" onClick={() => remove(idx)}>
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        </OrganizationSearch.Provider>
                      </section>
                    );
                  })
                }

                <Toggle show={values[FormFields.Organization]?.length < 10}>
                  <Space size={'middle'} direction="vertical">
                    <Button
                      type="dashed"
                      onClick={() => push(null)}
                    >
                      Add School
                    </Button>
                  </Space>
                </Toggle>
              </Flex>
            )
          }
        </FieldArray>
      </Space>

      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Toggle show={!!status.failure}>
          <Alert type="error" message={(status as AjaxFailureStatus).failure.message} closable />
        </Toggle>

        <Toggle show={status.success}>
          <Alert
            type="success"
            message={'Timetable claim created'}
            closable
          />
        </Toggle>

        <Space style={{ marginTop: '16px' }}>
          <SubmitButton loading={status.request} block={!activeScreens['sm']}>
           Generate a Schedule
          </SubmitButton>

          <Button onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default TimetableForm;


/* HELPERS */

interface ITimetableFormProps extends ICommonProps {
  status: IAjaxStatus;
  onCancel?(): void;
}
