import { ApiIntent, ApiService } from '../types';
import {
  SchoolDetailsDto,
  SchoolListDto,
  SchoolListElementDto,
  SchoolUpdateDto,
} from './school.dto';
import { ISchoolListCriteria } from './types';


export class SchoolApiService extends ApiService {
  getSchoolDetails(id: string): ApiIntent<any> {
    const [ getSchool, abort ] = this.createGetIntent<SchoolDetailsDto>(`schools/${id}`);

    return [() => getSchool().then(({ data: eopInfo }) => eopInfo), abort];
  }

  getSchoolList(criteria?: ISchoolListCriteria): ApiIntent<{ count?: number; items?: SchoolListElementDto[]; }> {
    const [ getSchoolList, abort ] = this.createGetIntent<SchoolListDto>('schools', criteria);

    return [
      () => getSchoolList().then(({ data: { count, items } }) => ({ count, items })),
      abort,
    ];
  }

  createSchool(payload: any): ApiIntent<string> {
    const [ createSchool, abort ] = this.createPostIntent<{ id: string; }>('schools', payload);

    return [
      () => createSchool().then(({ data: { id } }) => id),
      abort,
    ];
  }

  updateSchool(payload: SchoolUpdateDto):ApiIntent<boolean> {
    const [ updateSchool, abort ] = this.createPostIntent<{ id: string; }>(`schools`, payload);

    return [
      () => updateSchool().then(() => true),
      abort,
    ];
  }
}
