import React, { useMemo } from 'react';
import { useStudentListContext } from './context';
import { useSkillListContext } from 'src/features/Skill/List/containers/context';
import { StudentNeed } from 'src/features/Student/List/models/StudentListElement';

import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import SchoolStudentList from '../components/SchoolStudentList';

import type { ColumnsType } from 'antd/es/table';
import { TableColumns } from '../enums';

import urls from 'src/urls';


const SchoolStudentListContainer = () => {
  const {
    status,
    collection,
    total,
    pagination: { page, pageSize },
    changePage,
  } = useStudentListContext();

  const { collection: skillCollection } = useSkillListContext();
  const skillsMap = useMemo(() => skillCollection.reduce((arr, element) => {
    const { id, name } = element;

    arr.set(Number(id), name);

    return arr;
  }, new Map<number, string>()), [
    skillCollection,
  ]);

  const tableData = useMemo(() => collection.reduce((arr, element) => {
    const { id, name, schoolName, schoolId, schoolDistrictName, needs, timetableId } = element;

    arr.push({
      key: id,
      [TableColumns.Name]: name,
      [TableColumns.Needs]: needs ?? [],
      [TableColumns.Schedule]: timetableId ?? null,
      [TableColumns.Actions]: id,
      [TableColumns.School]: schoolId,
    });

    return arr;
  }, [] as ISchoolStudentListElementData[]), [
    collection,
  ]);

  const tableColumns: ColumnsType<ISchoolStudentListElementData> = useMemo(() => ([
    {
      key: TableColumns.Name,
      title: 'Student Name',
      dataIndex: TableColumns.Name,
      render: (value: string, record: ISchoolStudentListElementData) => <Link to={urls.studentDetails(record.key)}>{ value }</Link>
    },
    {
      key: TableColumns.Needs,
      title: 'Needs',
      dataIndex: TableColumns.Needs,
      render: (value: number[]) => {
        const needs = value?.map(id => skillsMap?.get(id) || '');

        return needs.map((name: string) => (
          <div><Typography.Text>{name ?? <>&mdash;</>}</Typography.Text></div>
        ));
      }
    },
    // {
    //   key: TableColumns.Schedule,
    //   title: 'Latest Generated Schedule',
    //   dataIndex: TableColumns.Schedule,
    //   render: (value: string, record: ISchoolStudentListElementData) => value ? <Link to={`${urls.scheduleCalendar(value)}?school_id=${record[TableColumns.School]}`} title={value}>View Schedule</Link> : <>&mdash;</>
    // },
    {
      key: TableColumns.Actions,
      title: 'Actions',
      dataIndex: TableColumns.Actions,
      render: (value: string, record: ISchoolStudentListElementData) => value ? <div>
        <Link to={urls.studentDetails(value)} style={{ marginRight: '14px' }}>View</Link>
        {' '}
        <Link to={urls.studentEdit(value)} title={value}>Edit</Link>
      </div> : <>&mdash;</>
    },
  ].filter(Boolean) as ColumnsType<ISchoolStudentListElementData>), [ skillsMap ]);

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
  }), [ page, total, pageSize ]);

  return (
    <SchoolStudentList
      tableColumns={tableColumns}
      tableData={tableData}
      pagination={pagination}
      changePage={changePage}
      loading={status.request}
    />
  );
};

export default SchoolStudentListContainer;


export interface ISchoolStudentListElementData {
  key: React.Key;
  [TableColumns.Name]: string;
  [TableColumns.Needs]: StudentNeed[];
  [TableColumns.Schedule]: string;
  [TableColumns.Actions]: string;
  [TableColumns.School]?: string;
}
