import React from 'react';
import { useMemo } from 'react';

import { Typography }        from 'antd';
import { Link }              from 'react-router-dom';

import type { ColumnsType }  from 'antd/es/table';
import { TableColumns }      from 'src/features/School/List/enums';

import { SchoolListElement } from 'src/features/School/List/models/SchoolListElement';
import { ISchoolListElementData } from 'src/features/School/List/containers/SchoolList';
import { TimetableDetailsSchoolDto } from 'src/services/api/timetable/timetable.dto';
import SchoolList from 'src/features/School/List/components/SchoolList';

import urls                  from 'src/urls';


interface TimetableSchoolListContainerProps {
  timetableSchools: TimetableDetailsSchoolDto[]
}

const TimetableSchoolListContainer = ({ timetableSchools }: TimetableSchoolListContainerProps) => {
  const tableData = useMemo(() => timetableSchools.reduce((arr, school) => {
    const { id, name, districtName } = school as SchoolListElement;

    arr.push({
      key: id,
      [TableColumns.Name]: name,
      [TableColumns.District]: districtName,
      [TableColumns.Actions]: id,
    });

    return arr;
  }, [] as ISchoolListElementData[]), [
    timetableSchools
  ]);

  const tableColumns: ColumnsType<ISchoolListElementData> = useMemo(() => ([
    {
      key: TableColumns.Name,
      title: 'Name',
      dataIndex: TableColumns.Name,
      render: (value: string, record: ISchoolListElementData) => <Link to={urls.schoolDetails(record.key)}>{ value }</Link>
    },
    {
      key: TableColumns.District,
      title: 'District',
      dataIndex: TableColumns.District,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },

  ].filter(Boolean) as ColumnsType<ISchoolListElementData>), []);


  return (
    <SchoolList
      tableColumns={tableColumns}
      tableData={tableData}
      loading={false}
      pagination={false}
    />
  );
};

export default TimetableSchoolListContainer;
