import {
  useState,
  useCallback }            from 'react';

import { AjaxStatusError } from 'src/utils/ajax';
import { AjaxStatus }      from 'src/constants';


export const useAjaxStatus = (defaultStatus: IAjaxStatus = AjaxStatus.default()) => {
  const [ status, setStatus ] = useState<IAjaxStatus>(defaultStatus);

  const setSuccess = useCallback( () => setStatus(AjaxStatus.success() ), []);
  const setRequest = useCallback( () => setStatus(AjaxStatus.request() ), []);
  const setFailure = useCallback( (err: AjaxStatusError) => setStatus(AjaxStatus.failure(err)), []);


  return {
    status,
    setSuccess,
    setRequest,
    setFailure,
  };
};