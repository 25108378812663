import React, { useMemo } from 'react';
import { Table, TablePaginationConfig } from 'antd';

import type { ColumnsType } from 'antd/es/table';

import { IDistrictListElementData } from '../containers/DistrictList';
import { createTableChangeHandler } from 'src/utils/antd';

import './DistrictList.scss';


interface IDistrictListProps extends ICommonProps {
  tableData: IDistrictListElementData[]
  tableColumns: ColumnsType<IDistrictListElementData>
  loading: boolean
  pagination?: false | TablePaginationConfig
  changePage?(page: number, pageSize: number): void
}

const DistrictList = (props: IDistrictListProps) => {
  const {
    tableData,
    tableColumns,
    loading,
    pagination,
    changePage
  } = props;

  const onTableStateChange = useMemo(() => createTableChangeHandler<IDistrictListElementData>({
    onPageChange: changePage,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return (
    <Table
      columns={tableColumns}
      dataSource={tableData}
      loading={loading}
      pagination={pagination}
      onChange={onTableStateChange}
    />
  );
};

export default React.memo(DistrictList);
