import { useCallback, useMemo } from 'react';
import { useAjaxStatus }            from 'src/hooks';
import { useValidationSchema }      from '../hooks';

import { Formik }                   from 'formik';
import Form                         from '../components/Form';

import { session as SessionService }  from 'src/services';

import { prepareCreateFormData }    from '../utils';
import { FormFields }               from '../enums';
import { SessionFormValues }        from '../types';
import { ApiError }                 from 'src/modules/ErrorContext/models';
import { useSchoolListContext }     from 'src/features/School/List/containers/context';


const SessionCreateFormContainer = (props: ISessionCreateFormContainerProps) => {
  const { onCancel, onCreated } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();


  const { collection: schoolCollection } = useSchoolListContext();
  const schoolsOptions = useMemo(() => schoolCollection.reduce((arr, element) => {
    const { id, name } = element;

    arr.push({ value: id, label: name });

    return arr;
  }, [] as IOption[]), [
    schoolCollection,
  ]);

  const createSession = useCallback(async (values: SessionFormValues) => {
    setRequest();

    try {
      const [ createSession ] = SessionService.createSession(prepareCreateFormData(values));

      const id = await createSession();

      setSuccess();

      if (typeof onCreated === 'function') {
        onCreated({
          id,
          name: values[FormFields.Name],
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onCreated ]);

  const validationSchema = useValidationSchema();
  const editableFields = new Set([
    FormFields.Name,
    FormFields.Description,
    FormFields.Description
  ]);
  const needOptions = [
    { value: '1', label: 'Speech therapist (logopedist)' },
    { value: '2', label: 'Behavior therapist' }
  ];

  return (
    <Formik
      initialValues={{
        [FormFields.Id]: null,
        [FormFields.Name]: '',
        [FormFields.Description]: '',
        [FormFields.Duration]: '',
        [FormFields.PreparingTime]: '',
        [FormFields.FinishingTime]: '',
        [FormFields.Need]: '',
        [FormFields.Group]: false,
        [FormFields.Online]: false,
        [FormFields.SchoolId]: '',
      }}
      validationSchema={validationSchema}
      onSubmit={createSession}
    >
      <Form
        status={status}
        editableFields={editableFields}
        needOptions={needOptions}
        schoolsOptions={schoolsOptions}
        onCancel={onCancel}
      />
    </Formik>
  );
};

export default SessionCreateFormContainer;


/* HELPERS */

interface ISessionCreateFormContainerProps extends ICommonProps {
  onCancel?(): void;
  onCreated?(schoolEntity: IEntity): void;
}
