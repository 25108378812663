import React from 'react';
import { useMemo } from 'react';
import { useSchoolListContext } from './context';

import {
  Typography }               from 'antd';
import { Link }              from 'react-router-dom';

import SchoolList               from '../components/SchoolList';
import { useDistrictListContext } from 'src/features/District/List/containers/context';

import type { ColumnsType }  from 'antd/es/table';
import { TableColumns }      from '../enums';

import urls                  from 'src/urls';


const SchoolListContainer = () => {
  const {
    status,
    collection,
    total,
    pagination: { page, pageSize },
    changePage,
  } = useSchoolListContext();

  const { collection: districtCollection } = useDistrictListContext();
  const districtMap = useMemo(() => districtCollection.reduce((arr, element) => {
    const { id, name } = element;

    arr.set(id, name);

    return arr;
  }, new Map<string, string>()), [
    districtCollection,
  ]);


  const tableData = useMemo(() => collection.reduce((arr, element) => {
    const { id, name, districtName } = element;

    arr.push({
      key: id,
      [TableColumns.Name]: name,
      [TableColumns.District]: districtName,
      [TableColumns.Actions]: id,
    });

    return arr;
  }, [] as ISchoolListElementData[]), [
    collection, districtMap
  ]);

  const tableColumns: ColumnsType<ISchoolListElementData> = useMemo(() => ([
    {
      key: TableColumns.District,
      title: 'District Name',
      dataIndex: TableColumns.District,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.Name,
      title: 'School Name',
      dataIndex: TableColumns.Name,
      render: (value: string, record: ISchoolListElementData) => <Link to={urls.schoolDetails(record.key)}>{ value }</Link>
    },
    {
      key: TableColumns.Actions,
      title: 'Actions',
      dataIndex: TableColumns.Actions,
      render: (value: string, record: ISchoolListElementData) => value ? <div>
        <Link to={urls.schoolDetails(record.key)} style={{ marginRight: '14px' }}>View</Link>
        {' '}
        <Link to={urls.schoolEdit(value)}>Edit</Link>
      </div> : <>&mdash;</>
    }
    // {
    //   key: 'Schedule',
    //   title: 'Latest Generated Schedule',
    //   dataIndex: 'Schedule',
    //   render: (value: string, record: ISchoolListElementData) => <span>N/A</span>
    // },
  ].filter(Boolean) as ColumnsType<ISchoolListElementData>), []);

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
  }), [ page, total, pageSize ]);


  return (
    <SchoolList
      tableColumns={tableColumns}
      tableData={tableData}
      pagination={pagination}
      changePage={changePage}
      loading={status.request}
    />
  );
};


export default SchoolListContainer;


export interface ISchoolListElementData {
  key: React.Key;
  [TableColumns.Name]: string;
  [TableColumns.District]: string;
  [TableColumns.Actions]: string;
}
