import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  Typography,
  notification } from 'antd';
import CreateForm from 'src/features/Session/Forms/containers/CreateForm';
import { SchoolListProvider } from 'src/features/School/List/containers/context';

import urls from 'src/urls';



const SessionCreatePage = () => {
  const navigate = useNavigate();

  const redirectToSessionList = useCallback((sessionEntity: IEntity) => {
    notification.success({
      message: <Typography.Text strong>Session has been created!</Typography.Text>,
      description: (
        <Typography.Text>
          Session <Typography.Text strong>{ sessionEntity.name }</Typography.Text> was created
        </Typography.Text>
      ),
      duration: null,
    });

    navigate(urls.session());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card title={<Typography.Title level={2}>Create Session</Typography.Title>}>
      <Row>
        <Col xl={12} lg={16} md={24}>
          <SchoolListProvider>
            <CreateForm onCreated={redirectToSessionList} onCancel={() => navigate(urls.session())} />
          </SchoolListProvider>
        </Col>
      </Row>
    </Card>
  );
};

export default SessionCreatePage;
