import { useEffect, useState } from 'react';

import { useAjaxStatus, useRequestKey, useDebouncedValue } from 'src/hooks';

import { Select, SelectProps } from 'antd';

import { CommonOption } from 'src/models/CommonOption';

import { ApiError } from 'src/modules/ErrorContext/models';

import { district as DistrictService } from 'src/services';
import { school as SchoolService } from 'src/services';
import { OrganizationType } from 'src/enums';


type OptionsState = {
  total: number;
  options: IOption[];
};

const OrganizationSearch = (props: any) => {
  const {
    type,
    parentOrgId,
    ...rest
  } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const { engage, isCurrentRequest } = useRequestKey();

  const [ searchString, setSearchString ] = useState('');
  const [ optionsState, setOptionsState ] = useState<OptionsState>({ total: 0, options: [] });

  const searchValue = useDebouncedValue(searchString, 500);

  useEffect(() => {
    const [ loadDistricts, abortDistricts ] = DistrictService.getDistrictList({
      keyword: searchValue,
    });
    const schoolsCriteria = parentOrgId ? { districtId: parentOrgId } : {};
    const [ loadSchools, abortSchools ] = SchoolService.getSchoolList({
      keyword: searchValue,
      ...schoolsCriteria,
    });

    const key = engage();
    setRequest();

    if (type === OrganizationType.District) {
      loadDistricts()
        .then(({ count, items }) => {
          if (!isCurrentRequest(key)) {
            return;
          }

          const options = items!.map( (entity) => ({
            ...CommonOption.create(entity.id, entity.name),
            parent: null,
          }));

          setOptionsState(() => ({
            total: Number(count),
            options,
          }));

          setSuccess();
        })
        .catch((err: ApiError) => {
          isCurrentRequest(key) && setFailure(err);
        });
    }

    if (type === OrganizationType.School) {
      loadSchools()
        .then(({ count, items }) => {
          if (!isCurrentRequest(key)) {
            return;
          }

          const options = items!.map( (entity) => ({
            ...CommonOption.create(entity.id, entity.name),
            parent: CommonOption.create(entity.districtId, entity.districtName)
          }));

          setOptionsState(() => ({
            total: Number(count),
            options,
          }));

          setSuccess();
        })
        .catch((err: ApiError) => {
          isCurrentRequest(key) && setFailure(err);
        });
    }

    return () => {
      if (type === OrganizationType.District) {
        abortDistricts();
      }
      if (type === OrganizationType.School) {
        abortSchools();
      }
    };
  }, [ searchValue, parentOrgId ]);


  return (
    <Select
      {...rest}
      showSearch
      // value={value}
      // style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={setSearchString}
      notFoundContent={null}
      options={optionsState.options}
      allowClear
    />
  );
};

OrganizationSearch.defaultProps = {
  notFoundContent: null,
};

export default OrganizationSearch;


/* HELPERS */

interface IOrganizationSearchProps extends ICommonProps {
  type: OrganizationType;
  parentOrgId?: Nullable<string>;
}
