import { addMinutes, startOfDay } from 'date-fns';
import { StudentAvailabilityDto } from 'src/services/api/student/student.dto';
import { initialAvailability } from 'src/features/Student/Forms/constants';
import { Availability } from 'src/models/Availability';


export function parseStudentAvailability(data: StudentAvailabilityDto[]) {
  const dataMap = data.reduce((acc, item) => {
    if (item) {
      acc.set(item.day, item);
    }
    return acc;
  }, new Map());

  return initialAvailability.reduce((acc, item) => {
    const { id, day } = item;

    if (dataMap.has(id)) {

      const { start, end } = dataMap.get(id).intervals[0];

      const startTime = addMinutes(startOfDay(new Date()), start);
      const endTime = addMinutes(startOfDay(new Date()), end);

      acc.push(Availability.fromJS({
        id,
        day,
        checked: true,
        intervals: { start: startTime, end: endTime }
      }));
    }

    return acc;
  }, [] as Availability[]);
}
