import { Record }                     from 'immutable';

import { FilterFields }               from './enums';
import { StudentListFiltersValues }   from './types';


export const StudentsFilters = Record<StudentListFiltersValues>({
  [FilterFields.Name]: '',
  [FilterFields.Organization]: null,
});

