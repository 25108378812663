import React from 'react';
import { useContext } from 'react';
import { useTherapistDetails } from '../hooks';

import { AjaxStatus } from 'src/constants';
import { TherapistDetails } from '../models/TherapistDetails';
import { ChildrenFunc } from 'src/utils';


export const TherapistDetailsContext = React.createContext<TherapistDetailsContextValue>({
  status: AjaxStatus.default(),
  therapistDetails: null,
});
TherapistDetailsContext.displayName = 'TherapistDetailsContext';


export const useTherapistDetailsContext = () => useContext(TherapistDetailsContext);

export const TherapistDetailsProvider = (props: ITherapistDetailsProviderProps) => {
  const { id, children } = props;

  const value = useTherapistDetails(id);

  return (
    <TherapistDetailsContext.Provider value={value}>
      { typeof children === 'function' ? children(value) : children }
    </TherapistDetailsContext.Provider>
  );
};

/* HELPERS */

interface ITherapistDetailsProviderProps {
  id: string;
  children?: React.ReactNode | ChildrenFunc<TherapistDetailsContextValue>;
}

export type TherapistDetailsContextValue = {
  therapistDetails: Nullable<TherapistDetails>;
  status: IAjaxStatus;
}
