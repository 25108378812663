import React, { useMemo }          from 'react';
import { useTimetableListContext } from './context';

import { Typography }        from 'antd';
import { Link }              from 'react-router-dom';

import TimetableList          from '../components/TimetableList';

import type { ColumnsType }  from 'antd/es/table';
import { TimetableSchool }   from 'src/features/Timetable/List/models/TimetableListElement';
import { TableColumns }      from '../enums';

import urls                  from 'src/urls';


const TimetableListContainer = () => {
  const {
    status,
    collection,
    total,
    pagination: { page, pageSize },
    changePage,
  } = useTimetableListContext();

  const tableData = useMemo(() => collection.reduce((arr, element) => {
    const { id, createdAt, name, schools, startDate, endDate, status, error} = element;

    arr.push({
      key: id,
      [TableColumns.CreatedAt]: createdAt,
      [TableColumns.Name]: name,
      [TableColumns.Districts]: schools || [],
      [TableColumns.Schools]: schools || [],
      [TableColumns.DateRange]: `${startDate} — ${endDate}`,
      [TableColumns.Error]: error || null,
      [TableColumns.Status]: status || '',
    });

    return arr;
  }, [] as ITimetableListElementData[]), [
    collection,
  ]);

  const tableColumns: ColumnsType<ITimetableListElementData> = useMemo(() => ([
    {
      key: TableColumns.CreatedAt,
      title: 'Created At',
      dataIndex: TableColumns.CreatedAt,
      render: (value: string, record: ITimetableListElementData) => <Typography.Text>{ value.substring(0, 10) ?? <>&mdash;</> }</Typography.Text>
    },
    {
      key: TableColumns.Districts,
      title: 'Districts',
      dataIndex: TableColumns.Schools,
      render: (value: TimetableSchool[]) => {
        const districtsSet = new Set(value.map((school: TimetableSchool) => school?.districtName));

        return [...districtsSet].map((name: string) => (
          <div><Typography.Text>{name}</Typography.Text></div>
        ));
      }
    },
    {
      key: TableColumns.Schools,
      title: 'Schools',
      dataIndex: TableColumns.Schools,
      render: (value: TimetableSchool[]) => value.map((school: TimetableSchool) => (
        <div><Typography.Text>{school?.name}</Typography.Text></div>
      ))
    },
    {
      key: TableColumns.DateRange,
      title: 'Date Range',
      dataIndex: TableColumns.DateRange,
      render: (value: string) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.Status,
      title: 'Status',
      dataIndex: TableColumns.Status,
      render: (value: string) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.Name,
      title: 'Latest Generated Schedule',
      dataIndex: TableColumns.Name,
      render: (value: string, record: ITimetableListElementData) => <Link to={urls.scheduleCalendar(record.key)} title={value}>View</Link>
    },
  ].filter(Boolean) as ColumnsType<ITimetableListElementData>), [ ]);

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
  }), [ page, total, pageSize ]);

  return (
    <TimetableList
      tableColumns={tableColumns}
      tableData={tableData}
      loading={status.request}
      pagination={pagination}
      changePage={changePage}
    />
  );
};

export default TimetableListContainer;


export interface ITimetableListElementData {
  key: React.Key;
  [TableColumns.Name]: string;
  [TableColumns.CreatedAt]: string;
  [TableColumns.DateRange]: string;
  [TableColumns.Schools]: string[];
  [TableColumns.Districts]: string[];
  [TableColumns.Error]: Nullable<string>;
  [TableColumns.Status]: string;
}
