import {
  Space,
  Descriptions } from 'antd';
import { PrescriptionDetails } from 'src/features/Prescription/models/PrescriptionDetails';


const PrescriptionInfo = (props: IPrescriptionInfoProps) => {
  const { details } = props;

  return (
    <Space direction="vertical" size="large">
      {details.map(prescription => (
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Days">{prescription?.days}</Descriptions.Item>
          <Descriptions.Item label="Sessions">{prescription?.sessions}</Descriptions.Item>
        </Descriptions>
      ))}

    </Space>
  );
};

export default PrescriptionInfo;


/* HELPERS */

interface IPrescriptionInfoProps extends ICommonProps {
  details: PrescriptionDetails[];
}
