import React from 'react';
import { Table } from 'antd';

import type { ColumnsType } from 'antd/es/table';

import { ITimetableEventsListElementData } from '../containers/TimetableEventsList';

import './TimetableEventsList.scss';


interface ITimetableListProps extends ICommonProps {
  tableData: ITimetableEventsListElementData[]
  tableColumns: ColumnsType<ITimetableEventsListElementData>
}

const TimetableEventsList = (props: ITimetableListProps) => {
  const {
    tableData,
    tableColumns,
  } = props;

  return (
    <Table
      columns={tableColumns}
      dataSource={tableData}
      pagination={false}
    />
  );
};

export default React.memo(TimetableEventsList);
