import React, { useMemo } from 'react';
import { Table, TablePaginationConfig } from 'antd';
import { createTableChangeHandler } from 'src/utils/antd';

import type { ColumnsType } from 'antd/es/table';

import { ITherapistListElementData } from '../containers/TherapistList';
import { TableColumns } from 'src/features/Therapist/List/enums';

import './TherapistList.scss';


type ISchoolTherapistListElementData = Omit<ITherapistListElementData, TableColumns.Districts | TableColumns.Schools>;

interface ISchoolTherapistListProps extends ICommonProps {
  tableData: ISchoolTherapistListElementData[]
  tableColumns: ColumnsType<ISchoolTherapistListElementData>
  loading: boolean
  pagination?: false | TablePaginationConfig
  changePage?(page: number, pageSize: number): void
}

const SchoolTherapistList = (props: ISchoolTherapistListProps) => {
  const {
    tableData,
    tableColumns,
    loading,
    pagination,
    changePage
  } = props;

  const onTableStateChange = useMemo(() => createTableChangeHandler<ISchoolTherapistListElementData>({
    onPageChange: changePage,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return (
    <Table
      columns={tableColumns}
      dataSource={tableData}
      loading={loading}
      pagination={pagination}
      onChange={onTableStateChange}
    />
  );
};

export default React.memo(SchoolTherapistList);
