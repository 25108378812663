import { useMemo }           from 'react';
import { isAfter }           from 'date-fns';

import * as Yup              from 'yup';

import { FormFields }        from '../enums';
import { OrganizationType }  from 'src/enums';


const TIME_REGEXP = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/;

export const useValidationSchema = () => useMemo(() => Yup.object({
  [FormFields.FirstName]: Yup.string().required('First Name is required'),
  [FormFields.LastName]: Yup.string().required('Last Name is required'),
  [FormFields.Organization]: Yup.array().of(Yup.object().shape({
    id: Yup.string(),
    name: Yup.string(),
    type: Yup.string(),
  }).nullable().test({
    name: 'SchoolRequired',
    test: (value) => value?.type === OrganizationType.School,
    message: 'School required',
    exclusive: true,
  })),
  [FormFields.Skills]: Yup.array().of(Yup.string()).required('Skills is required'),
  [FormFields.PauseBetweenSessions]: Yup.string().required('Time in minutes is required'),
  [FormFields.CustomAvailability]: Yup.boolean(),
  [FormFields.Availability]: Yup.array().when('customAvailability', {
    is: true,
    then: () => {
      return Yup.array().of(
        Yup.object().shape({
          id: Yup.number().required(),
          day: Yup.string().required(),
          checked: Yup.boolean().required(),
          intervals: Yup.object().when('checked', {
            is: (values: any) => {
              return values === true;
            },
            then: () => {
              return Yup.object().shape({
                start: Yup.date().required('Start time required'),
                end: Yup.date().required('End time required')
              });
            },
            otherwise: () => {
              return Yup.object().notRequired();
            }
          })
        })
        .test({
          name: 'IntervalRequired',
          test: (value: any) => {
            if (!value?.checked) {
              return true;
            }

            return value?.checked && value?.intervals?.start && value?.intervals?.end && isAfter(value?.intervals?.end, value?.intervals?.start);
          },
          message: 'End time must be after start time',
          exclusive: true,
        }),
      )
      .test({
        name: 'AvailabilityRequired',
        test: (value: any) => {
          return value.find((interval: any) => interval.checked);
        },
        message: 'Availability required',
        exclusive: true,
      });
    }
  })
}), [ ]);
