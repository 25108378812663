import { useCallback, useMemo, useState} from 'react';
import { TimetableDetails } from 'src/features/Timetable/models/TimetableDetails';
import { CalendarFilterFields } from 'src/features/Timetable/Details/enums';
import { CalendarFiltersValues } from 'src/features/Timetable/types';
import { intersect } from 'src/features/Timetable/Details/utils';

export function useCalendarFilters(searchFilters: Record<string, string | (string | null)[] | null>, details: TimetableDetails) {

  const { therapists, students } = details;

  // console.log('students', students)

  const initialFilters: CalendarFiltersValues = useMemo(() => {
    const hasSpecificSchool = Boolean(searchFilters.schoolId);
    const hasSpecificTherapist = Boolean(searchFilters.therapistId);

    return {
      [CalendarFilterFields.School]: details.schools.reduce((acc, school) => {
        acc.set(school.id, {
          ...school,
          checked: hasSpecificSchool ? searchFilters.schoolId === school.id : true,
          therapists: details.therapists.reduce((acc, therapist) => {
            if (therapist.schools.includes(school.id)) {
              acc.set(therapist.id, {
                ...therapist,
                checked: hasSpecificSchool ? hasSpecificTherapist ? searchFilters.schoolId === school.id && searchFilters.therapistId === therapist.id : searchFilters.schoolId === school.id : true,
              });
            }
            return acc;
          }, new Map())
        });
        return acc;
      }, new Map())};
    }, [details, searchFilters]);


  const [filters, setFilters] = useState(initialFilters);
  // filter handlers

  const changeSchoolFilter = useCallback((schoolId: string) => (value: any) => {
    if (value.target.checked) {
      setFilters(filters => {
        return {
          [CalendarFilterFields.School]: filters[CalendarFilterFields.School].set(schoolId, {
            ...filters[CalendarFilterFields.School].get(schoolId),
            checked: true,
            therapists: [...filters[CalendarFilterFields.School]?.get(schoolId)?.therapists.keys() as any].reduce((acc, therapistId) => {
              acc.set(therapistId, {
                ...filters[CalendarFilterFields.School]?.get(schoolId)?.therapists.get(therapistId),
                checked: true,
              });
              return acc;
            }, new Map())
        })};
      });
    } else {
      setFilters(filters => {
        return {
          [CalendarFilterFields.School]: filters[CalendarFilterFields.School].set(schoolId, {
            ...filters[CalendarFilterFields.School].get(schoolId),
            checked: false,
            therapists: [...filters[CalendarFilterFields.School]?.get(schoolId)?.therapists.keys() as any].reduce((acc, therapistId) => {
              acc.set(therapistId, {
                ...filters[CalendarFilterFields.School]?.get(schoolId)?.therapists.get(therapistId),
                checked: false,
              });
              return acc;
            }, new Map())
          })};
      });
    }
  }, []);

  const changeTherapistFilter = useCallback((schoolId: string, filterTherapistId: string) => (value: any) => {
    if (value.target.checked) {
      setFilters(filters => {
        return {
          [CalendarFilterFields.School]: filters[CalendarFilterFields.School].set(schoolId, {
            ...filters[CalendarFilterFields.School].get(schoolId) as any,
            therapists: [...filters[CalendarFilterFields.School]?.get(schoolId)?.therapists.keys() as any].reduce((acc, therapistId) => {
              if (therapistId === filterTherapistId) {
                acc.set(therapistId, {
                  ...filters[CalendarFilterFields.School]?.get(schoolId)?.therapists.get(therapistId),
                  checked: true,
                });
              } else {
                acc.set(therapistId, {
                  ...filters[CalendarFilterFields.School]?.get(schoolId)?.therapists.get(therapistId),
                });
              }
              return acc;
            }, new Map()),
          })};
      });
    } else {
      setFilters(filters => {
        return {
          [CalendarFilterFields.School]: filters[CalendarFilterFields.School].set(schoolId, {
            ...filters[CalendarFilterFields.School].get(schoolId) as any,
            therapists: [...filters[CalendarFilterFields.School]?.get(schoolId)?.therapists.keys() as any].reduce((acc, therapistId) => {
              if (therapistId === filterTherapistId) {
                acc.set(therapistId, {
                  ...filters[CalendarFilterFields.School]?.get(schoolId)?.therapists.get(therapistId),
                  checked: false,
                });
              } else {
                acc.set(therapistId, {
                  ...filters[CalendarFilterFields.School]?.get(schoolId)?.therapists.get(therapistId),
                });
              }
              return acc;
            }, new Map()),
          })};
      });
    }
  }, []);

  const filteredTherapists = useMemo(() => {
    const selectedSchools = [...filters[CalendarFilterFields.School].keys() as any].reduce((acc, schoolId) => {

      if (filters[CalendarFilterFields.School]?.get(schoolId)?.checked) {
        acc.push(schoolId);
      }

      return acc;
    }, [] as string[]);

    const selectedSchoolsTherapists = therapists.filter(therapist => {
        const intersectedSchools = intersect(therapist.schools, selectedSchools);

        return Boolean(intersectedSchools.length);
      }
    );

    // const selected
    // если терапевт выключен то фильтруем его timetable item, убираем принадлежащие школе

    // console.log('selectedSchoolsTherapists', selectedSchoolsTherapists);

    const filtered = selectedSchoolsTherapists.map((therapist, idx) => {
      return {
        ...therapist,
        timetable: therapist.timetable.filter((event, idx) => {
          const { schoolId } = event;

          if (!filters[CalendarFilterFields.School].get(schoolId)?.checked) {
            return false;
          }

          if (!filters[CalendarFilterFields.School].get(schoolId)?.therapists?.get(therapist.id)?.checked) {
            return false;
          }

          return true;
        })
      };
    });

    return filtered;
  }, [filters, therapists]);

  return {
    filters,
    changeSchoolFilter,
    changeTherapistFilter,
    filteredTherapists,
  };
}
