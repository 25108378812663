import { useCallback, useMemo }     from 'react';
import { Formik }                   from 'formik';
import { useAjaxStatus }            from 'src/hooks';
import { useValidationSchema }      from '../hooks';

import { student as StudentService } from 'src/services';

import Form                         from '../components/Form';
import { prepareCreateFormData }    from '../utils';
import { FormFields }               from '../enums';
import { StudentFormValues }        from '../types';
import { ApiError }                 from 'src/modules/ErrorContext/models';
import { useTherapistSkillsOptions } from 'src/common/hooks/useTherapistSkillsOptions';
import { SchoolDetails }            from 'src/features/School/models/SchoolDetails';
import { initialAvailability }      from '../constants';
import { OrganizationType }         from 'src/enums';


const StudentCreateFormContainer = (props: IStudentCreateFormContainerProps) => {
  const { schoolDetails, onCancel, onCreated } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const initialOrganization = useMemo(() => {
    if (!schoolDetails) {
      return;
    }

    return ({
      id: schoolDetails?.id || '',
      name: schoolDetails?.name || '',
      type: OrganizationType.School,
      parent: schoolDetails?.organization || null,
    });

  }, [ schoolDetails ]);

  const { options: skillsOptions } = useTherapistSkillsOptions();

  const createStudent = useCallback(async (values: StudentFormValues) => {
    setRequest();

    try {
      const [ createStudent ] = StudentService.createStudent(prepareCreateFormData(values));

      const id = await createStudent();

      setSuccess();

      if (typeof onCreated === 'function') {
        onCreated({
          id,
          name: values[FormFields.FirstName],
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onCreated ]);

  const validationSchema = useValidationSchema();
  const editableFields = new Set([
    FormFields.FirstName,
    FormFields.LastName,
    FormFields.Organization,
    FormFields.CustomAvailability
  ]);

  const initialValues = useMemo<StudentFormValues>(() => ({
      [FormFields.Id]: null,
      [FormFields.FirstName]: '',
      [FormFields.LastName]: '',
      [FormFields.Organization]: initialOrganization ?? null,
      [FormFields.Needs]: [],
      [FormFields.CustomAvailability]: false,
      [FormFields.Availability]: initialAvailability,
    }), [ initialOrganization ]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createStudent}
    >
      <Form
        status={status}
        editableFields={editableFields}
        onCancel={onCancel}
        validationSchema={validationSchema}
        skillsOptions={skillsOptions}
      />
    </Formik>
  );
};

export default StudentCreateFormContainer;


/* HELPERS */

interface IStudentCreateFormContainerProps extends ICommonProps {
  onCancel?(): void;
  onCreated?(schoolEntity: IEntity): void;
  schoolDetails?: Nullable<SchoolDetails>;
}
