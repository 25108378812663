import { List } from 'immutable';

import { SkillListElementDto } from 'src/services/api/skill/skill.dto';
import { SkillListElement } from '../../models/SkillListElement';


export function parseSkillList(items: SkillListElementDto[]): List<SkillListElement> {
  return List(items.map(item => {
    const {
      id,
      name,
    } = item;

    // const rootOrgIsDistrict = organization.type === OrganizationType.District;

    return new SkillListElement(
      id,
      name,
    );
  }));
}
