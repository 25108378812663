import { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { useAjaxStatus } from 'src/hooks';
import { useValidationSchema } from '../hooks';
import { useStudentDetailsContext } from '../../containers/DetailsProvider';
import Form from '../components/Form';

import { student as StudentService } from 'src/services';

import { createStudentAvailability, prepareUpdateFormData } from '../utils';
import { FormFields } from '../enums';
import { StudentFormValues } from '../types';
import { ApiError } from 'src/modules/ErrorContext/models';
import { useTherapistSkillsOptions } from 'src/common/hooks/useTherapistSkillsOptions';


const StudentEditFormContainer = (props: IStudentEditFormContainerProps) => {
  const { onCancel, onUpdated } = props;

  const { studentDetails } = useStudentDetailsContext();

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const { options: skillsOptions } = useTherapistSkillsOptions();

  const updateStudent = useCallback(async (values: StudentFormValues) => {
    setRequest();

    try {
      const id = values[FormFields.Id]!;
      const [ updateStudent ] = StudentService.updateStudent(prepareUpdateFormData(values));

      await updateStudent();

      setSuccess();

      if (typeof onUpdated === 'function') {
        onUpdated({
          id,
          name: values[FormFields.FirstName],
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onUpdated ]);

  const handleSubmit = useCallback((values: StudentFormValues) => updateStudent(values), [ updateStudent ]);

  const validationSchema = useValidationSchema();
  const editableFields = new Set([
    FormFields.FirstName,
    FormFields.LastName,
    FormFields.Organization
  ]);

  const initialValues = useMemo<StudentFormValues>(() => {
    if (!studentDetails) {
      return {
        [FormFields.Id]: null,
        [FormFields.FirstName]: '',
        [FormFields.LastName]: '',
        [FormFields.Organization]: null,
        [FormFields.Needs]: [],
        [FormFields.CustomAvailability]: false,
        [FormFields.Availability]: [],
      };
    }

    const {
      id,
      firstName,
      lastName,
      organization,
      parentOrganization,
      needs,
      availability,
    } = studentDetails;

    return {
      [FormFields.Id]: id,
      [FormFields.FirstName]: firstName,
      [FormFields.LastName]: lastName,
      [FormFields.Organization]: organization ? { ...organization, parent: parentOrganization } : null,
      [FormFields.Needs]: needs || [],
      [FormFields.CustomAvailability]: Boolean(availability?.length),
      [FormFields.Availability]: createStudentAvailability(availability),
    };
  }, [ studentDetails ]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {
        () => (
          <>
            <Form
              status={status}
              editableFields={editableFields}
              onCancel={onCancel}
              skillsOptions={skillsOptions}
              validationSchema={validationSchema}
            />
          </>
        )
      }

    </Formik>
  );
};

export default StudentEditFormContainer;


/* HELPERS */

interface IStudentEditFormContainerProps extends ICommonProps {
  onCancel?(): void;
  onUpdated?(schoolEntity: IEntity): void;
}
