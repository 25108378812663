import React from 'react';
import { useContext } from 'react';
import { useTimetableDetails } from '../hooks';

import { AjaxStatus } from 'src/constants';
import { TimetableDetails } from '../models/TimetableDetails';
import { ChildrenFunc } from 'src/utils';


export const TimetableDetailsContext = React.createContext<TimetableDetailsContextValue>({
  status: AjaxStatus.default(),
  timetableDetails: null,
});
TimetableDetailsContext.displayName = 'TimetableDetailsContext';


export const useTimetableDetailsContext = () => useContext(TimetableDetailsContext);

export const TimetableDetailsProvider = (props: ITimetableDetailsProviderProps) => {
  const { id, children } = props;

  const value = useTimetableDetails(id);

  return (
    <TimetableDetailsContext.Provider value={value}>
      { typeof children === 'function' ? children(value) : children }
    </TimetableDetailsContext.Provider>
  );
};

/* HELPERS */

interface ITimetableDetailsProviderProps {
  id: string;
  children?: React.ReactNode | ChildrenFunc<TimetableDetailsContextValue>;
}

export type TimetableDetailsContextValue = {
  timetableDetails: Nullable<TimetableDetails>;
  status: IAjaxStatus;
}
