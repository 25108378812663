export const enum FilterFields {
  Name = 'name',
  Organization = 'organization',
  Signed = 'signed',
}

export const enum SortFields {
  Name = 'name',
  ModificationDate = 'modifiedAt',
}

export const enum TableColumns {
  Name = 'name',
  Timetable = 'timetable'
}

export const enum CalendarFilterFields {
  School = 'school'
}
