export const enum FilterFields {
  Name = 'name',
}

export const enum SortFields {
  Name = 'name',
}

export const enum TableColumns {
  Name = 'name',
  Actions = 'actions',
}
