import { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { useAjaxStatus } from 'src/hooks';
import { ApiError } from 'src/modules/ErrorContext/models';

import { school as SchoolService } from 'src/services';

import Form from '../components/Form';
import { useValidationSchema } from '../hooks';
import { useSchoolDetailsContext } from '../../containers/DetailsProvider';
import { createSchoolAvailability, prepareUpdateFormData } from '../utils';
import { SchoolFormValues } from '../types';

import { FormFields } from '../enums';


const SchoolEditFormContainer = (props: ISchoolEditFormContainerProps) => {
  const { onCancel, onUpdated } = props;

  const { schoolDetails } = useSchoolDetailsContext();

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const updateSchool = useCallback(async (values: SchoolFormValues) => {
    setRequest();

    try {
      const id = values[FormFields.Id]!;
      const [ updateSchool ] = SchoolService.updateSchool(prepareUpdateFormData(values));

      await updateSchool();

      setSuccess();

      if (typeof onUpdated === 'function') {
        onUpdated({
          id,
          name: values[FormFields.Name],
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onUpdated ]);

  const handleSubmit = useCallback((values: SchoolFormValues) => updateSchool(values), [ updateSchool ]);

  const validationSchema = useValidationSchema();
  const editableFields = new Set([
    FormFields.Name,
    FormFields.Organization,
  ]);

  const initialValues = useMemo<SchoolFormValues>(() => {
    if (!schoolDetails) {
      return {
        [FormFields.Id]: null,
        [FormFields.Name]: '',
        [FormFields.Organization]: null,
        [FormFields.Availability]: [],
      };
    }

    const {
      id,
      name,
      organization,
      availability,
    } = schoolDetails;

    return {
      [FormFields.Id]: id,
      [FormFields.Name]: name,
      [FormFields.Organization]: organization ? { ...organization, parent: null } : null,
      [FormFields.Availability]: createSchoolAvailability(availability),
    };
  }, [ schoolDetails ]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {
        () => (
          <>
            <Form
              status={status}
              editableFields={editableFields}
              onCancel={onCancel}
              validationSchema={validationSchema}
            />
          </>
        )
      }

    </Formik>
  );
};

export default SchoolEditFormContainer;


/* HELPERS */

interface ISchoolEditFormContainerProps extends ICommonProps {
  onCancel?(): void;
  onUpdated?(schoolEntity: IEntity): void;
}
