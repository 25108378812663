import { AvailabilityWeekDay } from 'src/enums';


export interface AvailabilityInterval {
  start: Nullable<Date>;
  end: Nullable<Date>;
}

export interface AvailabilityItem {
  id: number;
  day: AvailabilityWeekDay;
  checked: boolean;
  intervals: AvailabilityInterval;
}

export class Availability {
  private constructor(
    readonly id: number,
    readonly day: AvailabilityWeekDay,
    readonly checked: boolean,
    readonly intervals: AvailabilityInterval,
  ) {}

  static fromJS(data: AvailabilityItem) {
    return new Availability(data.id, data.day, data.checked, data.intervals);
  }
}
