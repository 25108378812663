// import { OrganizationType } from 'src/enums';


export class Interval {
  private constructor(
    readonly start: number,
    readonly end: number,
  ) {}

  static fromJS(data: { start: number; end: number }) {
    return new Interval(data.start, data.end);
  }
}
