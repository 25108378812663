import { ApiIntent, ApiService } from '../types';
import {
  TherapistDetailsDto,
  TherapistListDto,
  TherapistListElementDto,
  TherapistUpdateDto,
} from './therapist.dto';
import { ITherapistListCriteria } from './types';


export class TherapistApiService extends ApiService {
  getTherapistDetails(id: string): ApiIntent<any> {
    const [ getTherapist, abort ] = this.createGetIntent<TherapistDetailsDto>(`therapists/${id}`);

    return [() => getTherapist().then(({ data: eopInfo }) => eopInfo), abort];
  }

  getTherapistList(criteria?: ITherapistListCriteria): ApiIntent<{ count?: number; items?: TherapistListElementDto[]; }> {
    const [ getTherapistList, abort ] = this.createGetIntent<TherapistListDto>('therapists', criteria);

    return [
      () => getTherapistList().then(({ data: { count, items } }) => ({ count, items })),
      abort,
    ];
  }

  createTherapist(payload: any): ApiIntent<string> {
    const [ createTherapist, abort ] = this.createPostIntent<{ id: string; }>('therapists', payload);

    return [
      () => createTherapist().then(({ data: { id } }) => id),
      abort,
    ];
  }

  updateTherapist(payload: TherapistUpdateDto):ApiIntent<boolean> {
    const [ updateTherapist, abort ] = this.createPostIntent<{ id: string; }>('therapists', payload);

    return [
      () => updateTherapist().then(() => true),
      abort,
    ];
  }
}
