import { ApiIntent, ApiService } from '../types';
import {
  SessionDetailsDto,
  SessionListDto,
  SessionListElementDto,
  SessionUpdateDto,
} from './session.dto';
import { ISessionListCriteria } from './types';


export class SessionApiService extends ApiService {
  getSessionDetails(id: string): ApiIntent<any> {
    const [ getSession, abort ] = this.createGetIntent<SessionDetailsDto>(`sessions/${id}`);

    return [() => getSession().then(({ data: eopInfo }) => eopInfo), abort];
  }

  getSessionList(criteria?: ISessionListCriteria): ApiIntent<{ count?: number; items?: SessionListElementDto[]; }> {
    const [ getSessionList, abort ] = this.createGetIntent<SessionListDto>('sessions', criteria);

    return [
      () => getSessionList().then(({ data: { count, items } }) => ({ count, items })),
      abort,
    ];
  }

  createSession(payload: any): ApiIntent<string> {
    const [ createSession, abort ] = this.createPostIntent<{ id: string; }>('sessions', payload);

    return [
      () => createSession().then(({ data: { id } }) => id),
      abort,
    ];
  }

  updateSession(id: string, payload: SessionUpdateDto):ApiIntent<boolean> {
    const [ updateSession, abort ] = this.createPutIntent<{}>(`sessions/${id}`, payload);

    return [
      () => updateSession().then(() => true),
      abort,
    ];
  }
}
