import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Space,
  Typography } from 'antd';
import urls from 'src/urls';
import { TimetableListProvider } from 'src/features/Timetable/List/containers/context';
import { SchoolListProvider } from 'src/features/School/List/containers/context';
import TimetableList from 'src/features/Timetable/List/containers/TimetableList';



const TimetablePage = () => {
  const navigate = useNavigate();

  const createButton = <Button onClick={() => navigate(urls.timetableCreate())} type="primary">Create</Button>;

  return (
    <Card
      title={<Typography.Title level={2}>Timetables</Typography.Title>}
      extra={
        <Space size="middle">
          { createButton }
        </Space>
      }
    >
      <div>
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <h2>List of Timetables</h2>

          <TimetableListProvider>
          <SchoolListProvider>
            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
              <TimetableList />
            </Space>
          </SchoolListProvider>
          </TimetableListProvider>
        </Space>
      </div>
    </Card>
  );
};

export default TimetablePage;
