import queryString from 'query-string';
import { FileService } from 'src/utils/file';

import { LocalStorage } from './storage/Local';
import { AxiosHttpClient } from './http/AxiosClient';

import { DistrictApiService } from './api/district';
import { SchoolApiService } from './api/school';
import { StudentApiService } from './api/student';
import { TherapistApiService } from './api/therapist';
import { SessionApiService } from './api/session';
import { SkillApiService } from './api/skill';
import { AvailabilityApiService } from './api/availability';
import { TimetableApiService } from './api/timetable';

import config from 'src/config/appConfig';


export const storage = new LocalStorage();

const httpClient = new AxiosHttpClient({
  baseURL: config.apiUrl,
  paramsSerializer: {
    // @ts-ignore
    serialize: (params) => queryString.stringify(params, { arrayFormat: 'comma' }),
  },
});

export const district = new DistrictApiService(httpClient);
export const school = new SchoolApiService(httpClient);
export const student = new StudentApiService(httpClient);
export const therapist = new TherapistApiService(httpClient);
export const session = new SessionApiService(httpClient);
export const skill = new SkillApiService(httpClient);
export const availability = new AvailabilityApiService(httpClient);
export const timetable = new TimetableApiService(httpClient);

export const file = new FileService(new AxiosHttpClient());
