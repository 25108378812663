import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Result, Typography } from 'antd';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import enUS from 'date-fns/locale/en-US';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { useModalState } from '@k12-frontend/utils/hooks';
import { TimetableCalendarEvent } from 'src/features/Timetable/types';

import './TimetableCalendar.scss';


interface ITimetableCalendarProps {
  initialEvents: TimetableCalendarEvent[];
  loading: boolean;
}

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const TimetableCalendarComponent = (props: ITimetableCalendarProps) => {
  const {
    initialEvents,
    loading,
  } = props;

  const { modalOpened, openModal, closeModal } = useModalState();

  const [events, setEvents] = useState<TimetableCalendarEvent[] | undefined>([]);
  const [selectedEvent, setSelectedEvent] = useState<TimetableCalendarEvent>();
  const [date, setDate] = useState(initialEvents[0]?.start || new Date());

  const onNavigate = useCallback((newDate: Date) => setDate(newDate), [setDate]);

  useEffect(() => {
    if (initialEvents && initialEvents?.length && initialEvents[0].start) {
      setEvents(initialEvents);
    } else {
      setEvents(undefined);
    }
  }, [initialEvents]);

  const components = useMemo(() => ({
    // event: MyEvent, // used by each view (Month, Day, Week)
    // eventWrapper: MyEventWrapper,
    // eventContainerWrapper: MyEventContainerWrapper,
    // dateCellWrapper: MyDateCellWrapper,
    // dayColumnWrapper: MyDayColumnWrapper,
    // timeSlotWrapper: MyTimeSlotWrapper,
    // timeGutterHeader: MyTimeGutterWrapper,
    // resourceHeader: MyResourceHeader,
    // toolbar: MyToolbar,
    agenda: {
      event: (data: any) => {
        return (
          <div style={{ backgroundColor: 'none' }}>{data.event?.info}</div>
        );
      },
      // time: MyAgendaTime,
      // date: MyAgendaDate,
    },
    day: {
      // header: MyDayHeader,
      event: (data: any) => {
        return (
          <div style={{ backgroundColor: data.event?.color }}>{data.event?.info}</div>
        );
      },
    },
    week: {
      // header: () => null,
      event: (data: any) => {
        return (
          <div style={{ backgroundColor: data.event?.color }}>{data.event?.info}</div>
        );
      },
    },
    month: {
      // header: () => (<div>Header</div>),
      // dateHeader: MyMonthDateHeader,
      event: (data: any) => {
        return (
          <div style={{ backgroundColor: data.event?.color }}>{`${data.event?.time}`}</div>
        );
      },
    }
  }), []);

  const handleSelectEvent = useCallback(
    (event: any) => {
      if (!event) {
        return;
      }

      setSelectedEvent(event);
      openModal();
    },
    [openModal]);

  if (!initialEvents) {
    return (
      <div>
        <Result
          status="500"
          title="Sorry, something went wrong."
        />
      </div>
    );
  }

  const eventPropGetter = (event: any) => {
    return {
      // className: 'event-dot',
      style: {
        backgroundColor: event?.color,
        // display: 'inline-block !important',
      },
    };
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        dayLayoutAlgorithm={'no-overlap'}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={handleSelectEvent}
        date={date}
        min={new Date(date.getFullYear(), date.getMonth(), date.getDate(), 7)}
        max={new Date(date.getFullYear(), date.getMonth(), date.getDate(), 21)}
        onNavigate={onNavigate}
        style={{ minHeight: 900 }}
        // defaultView={'week'}
        step={15}
        eventPropGetter={eventPropGetter}
        components={components}
        // timeslots={4}
      />

      <Modal
        open={modalOpened}
        footer={null}
        onCancel={() => { closeModal(); }}
        cancelText="Close"
        maskClosable={false}
      >
        <Typography.Paragraph>
          {selectedEvent && (
            <div style={{ padding: '15px 15px 15px 0' }}>
              <div><b>{selectedEvent?.time}</b></div>
              {selectedEvent?.info}
            </div>
          )}
        </Typography.Paragraph>
      </Modal>
    </div>
  );
};

export default TimetableCalendarComponent;
