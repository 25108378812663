interface DistrictDetailsDto {
   id: string,
   name: string,
}

export class DistrictDetails {
  private constructor(
    readonly id: string,
    readonly name: string,
  ) {}

  static fromJS(data: DistrictDetailsDto) {

    return new DistrictDetails(
      data.id,
      data.name,
    );
  }
}



