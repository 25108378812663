export class SessionListElement implements IEntity {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly description: string,
    readonly duration: number,
    readonly preparingTime: number,
    readonly finishingTime: number,
    readonly need: number,
    readonly group: boolean,
    readonly online: boolean,
    readonly schoolId: string,
  ) {}
}
