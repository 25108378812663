import { ApiIntent, ApiService } from '../types';
import {
  SkillDetailsDto,
  SkillListDto,
  SkillListElementDto,
  SkillUpdateDto,
} from './skill.dto';
import { ISkillListCriteria } from './types';


export class SkillApiService extends ApiService {
  getSkillDetails(id: string): ApiIntent<any> {
    const [ getSkill, abort ] = this.createGetIntent<SkillDetailsDto>(`skills/${id}`);

    return [() => getSkill().then(({ data: eopInfo }) => eopInfo), abort];
  }

  getSkillList(criteria?: ISkillListCriteria): ApiIntent<SkillListElementDto[]> {
    const [ getSkillList, abort ] = this.createGetIntent<SkillListDto>('skills', criteria);

    return [
      () => getSkillList().then(({ data }) => {
        return data;
      }),
      abort,
    ];
  }

  createSkill(payload: any): ApiIntent<string> {
    const [ createSkill, abort ] = this.createPostIntent<{ id: string; }>('skills', payload);

    return [
      () => createSkill().then(({ data: { id } }) => id),
      abort,
    ];
  }

  updateSkill(id: string, payload: SkillUpdateDto):ApiIntent<boolean> {
    const [ updateSkill, abort ] = this.createPutIntent<{}>(`skills/${id}`, payload);

    return [
      () => updateSkill().then(() => true),
      abort,
    ];
  }
}
