import { FormFields } from './enums';
import { SessionFormValues } from './types';


export function prepareCreateFormData(values: SessionFormValues) {
  return {
    name: values[FormFields.Name],
    description: values[FormFields.Description],
    duration: Number(values[FormFields.Duration]),
    preparingTime: Number(values[FormFields.PreparingTime]),
    finishingTime: Number(values[FormFields.FinishingTime]),
    need: Number(values[FormFields.Need]),
    group: values[FormFields.Group],
    online: values[FormFields.Online],
    schoolId: values[FormFields.SchoolId]
  };
}

export function prepareUpdateFormData(values: SessionFormValues) {
  return {
    name: values[FormFields.Name],
  };
}
