import { useCallback }                  from 'react';
import { useDistrictField }             from '../hooks';

import OrganizationSearch               from './SearchField';

import { SelectProps }                  from 'antd';
import { IOrganizationOption }          from '../types';
import { OrganizationType }             from 'src/enums';


const DistrictSearch = (props: IDistrictSearchProps) => {
  const { onChange } = props;

  const { value, changeFieldValue } = useDistrictField();

  const changeDistrictValue = useCallback((value: any, option: IOrganizationOption | IOrganizationOption[]) => {
    if (!Array.isArray(option)) {
      changeFieldValue(option);
    }

    if (typeof onChange === 'function') {
      onChange(value, option);
    }
  }, [ onChange, changeFieldValue ]);


  return (
    <OrganizationSearch
      {...props}
      type={OrganizationType.District}
      value={value}
      onChange={changeDistrictValue}
      placeholder='District'
    />
  );
};

export default DistrictSearch;


/* HELPERS */

interface IDistrictSearchProps extends SelectProps<any, IOrganizationOption>, ICommonProps {
  onChange?: SelectProps['onChange'];
  optionsFilter?(option: IOrganizationOption): boolean;
}
