import React, { useMemo } from 'react';
import { Table, TablePaginationConfig } from 'antd';
import { createTableChangeHandler } from 'src/utils/antd';

import type { ColumnsType } from 'antd/es/table';

import { IStudentListElementData } from '../containers/StudentList';
import { TableColumns } from 'src/features/Student/List/enums';

import './StudentList.scss';


type ISchoolStudentListElementData = Omit<IStudentListElementData, TableColumns.District | TableColumns.School>;

interface ISchoolStudentListProps extends ICommonProps {
  tableData: ISchoolStudentListElementData[];
  tableColumns: ColumnsType<ISchoolStudentListElementData>;
  loading: boolean;
  pagination?: false | TablePaginationConfig;
  changePage?(page: number, pageSize: number): void;
}

const SchoolStudentList = (props: ISchoolStudentListProps) => {
  const {
    tableData,
    tableColumns,
    loading,
    pagination,
    changePage
  } = props;

  const onTableStateChange = useMemo(() => createTableChangeHandler<ISchoolStudentListElementData>({
    onPageChange: changePage,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return (
    <Table
      columns={tableColumns}
      dataSource={tableData}
      loading={loading}
      pagination={pagination}
      onChange={onTableStateChange}
    />
  );
};

export default React.memo(SchoolStudentList);
