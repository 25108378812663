import { RequestConfig } from '../http/AxiosClient';
import { IHttpClient, IResponseFormat } from '../http/types';


export class ApiService {
  constructor(private httpClient: IHttpClient) {}

  private createAbortHandler() {
    const controller = new AbortController();
    const { signal } = controller;

    return {
      signal,
      abort: () => controller.abort(),
    };
  }

  protected createHeadIntent<T>(url: string, params?: any, config?: RequestConfig): ApiIntent<IResponseFormat<T>> {
    const { signal, abort } = this.createAbortHandler();

    return [() => this.httpClient.head(url, params, signal, config), abort];
  }

  protected createGetIntent<T>(url: string, params?: any, config?: RequestConfig): ApiIntent<IResponseFormat<T>> {
    const { signal, abort } = this.createAbortHandler();

    return [() => this.httpClient.get(url, params, signal, config), abort];
  }

  protected createPutIntent<T>(url: string, data: unknown, params?: any, config?: RequestConfig): ApiIntent<IResponseFormat<T>> {
    const { signal, abort } = this.createAbortHandler();

    return [() => this.httpClient.put(url, data, params, signal, config), abort];
  }

  protected createPostIntent<T>(url: string, data: unknown, params?: any, config?: RequestConfig): ApiIntent<IResponseFormat<T>> {
    const { signal, abort } = this.createAbortHandler();

    return [() => this.httpClient.post(url, data, params, signal, config), abort];
  }

  protected createPatchIntent<T>(url: string, data: unknown, params?: any, config?: RequestConfig): ApiIntent<IResponseFormat<T>> {
    const { signal, abort } = this.createAbortHandler();

    return [() => this.httpClient.patch(url, data, params, signal, config), abort];
  }

  protected createDeleteIntent<T>(url: string, params?: any, config?: RequestConfig): ApiIntent<IResponseFormat<T>> {
    const { signal, abort } = this.createAbortHandler();

    return [() => this.httpClient.delete(url, params, signal, config), abort];
  }
}

/* HELPERS */

export type ApiIntent<T = IResponseFormat> = [() => Promise<T>, () => void]
