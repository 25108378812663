import React from 'react';
import { useMemo } from 'react';

import TimetableEventsList from '../components/TimetableEventsList';

import { addMinutes } from 'date-fns';

import type { ColumnsType }  from 'antd/es/table';
import { useSessionListContext } from 'src/features/Session/List/containers/context';
import { useStudentListContext } from 'src/features/Student/List/containers/context';
import { useTherapistListContext } from 'src/features/Therapist/List/containers/context';
import type { TimetableDetails } from 'src/features/Timetable/models/TimetableDetails';
import type { SessionListElement } from 'src/features/Session/List/models/SessionListElement';
import type { TimetableEventDto } from 'src/services/api/timetable/timetable.dto';
import { TableColumns } from '../enums';

import { toUtcString } from 'src/utils/date';


interface TimetableEventsListContainerProps {
  details: TimetableDetails
}

const TimetableEventsListContainer = ({ details }: TimetableEventsListContainerProps) => {
  const { students } = details;

  const { collection: sessionCollection, status: sessionStatus } = useSessionListContext();
  const sessionsMap = useMemo(() => sessionCollection.reduce((arr, element) => {
    const { id } = element;

    arr.set(id, element);

    return arr;
  }, new Map<string, SessionListElement>()), [
    sessionCollection,
  ]);

  const { collection: studentsCollection, status: studentsStatus } = useStudentListContext();
  const studentsMap = useMemo(() => studentsCollection.reduce((arr, element) => {
    const { id, firstName, lastName } = element;

    arr.set(id, `${firstName} ${lastName}`);

    return arr;
  }, new Map<string, string>()), [
    studentsCollection,
  ]);


  const {collection: therapistsCollection, status: therapistStatus } = useTherapistListContext();
  const therapistsMap = useMemo(() => therapistsCollection.reduce((arr, element) => {
    const { id, firstName, lastName } = element;

    arr.set(id, `${firstName} ${lastName}`);

    return arr;
  }, new Map<string, string>()), [
    therapistsCollection,
  ]);

  const eventsData = useMemo(() => students.reduce((arr, student) => {
    const { id, covering, timetable } = student;

    let events = timetable.map(item => ({
      // studentId: id,
      ...item
    }));

    arr = [...arr, ...events];

    return arr;
  }, [] as any[]), [students]);

  const sortedEvents =  useMemo(() => eventsData.sort(function (a, b) {
    a = a.date.split('-');
    b = b.date.split('-');
    return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
  }), [eventsData]);

  const eventsByDates = sortedEvents.reduce((acc, event) => {
    if (acc.has(`${event.date}`)) {
      acc.set(event.date, [...acc.get(`${event.date}`), event]);
    }

    acc.set(event.date, [event]);

    return acc;
  }, new Map());

  const tableByDateData = useMemo(() => [...eventsByDates].map(
    (item: any) => ({ date: item[0], events: item[1] })
  ), [eventsByDates]);

  const tableByDateColumns: ColumnsType<any> = useMemo(() => ([
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      className: 'name',
      render: (value: string) => <h4>{value}</h4>
    },
    {
      key: 'events',
      title: 'Sessions',
      dataIndex: 'events',
      render: (value: any) => value.map((event: any, index: any) => (
        <div key={index} style={{marginBottom: '10px'}}>
          <div><b>{`${studentsMap.get(event.studentId)}`}</b></div>
          <div>{`Start: ${toUtcString(addMinutes(new Date(event.date), new Date(event.date).getTimezoneOffset() + event.startTime))}`}</div>
          <div>{`End: ${toUtcString(addMinutes(new Date(event.date), new Date(event.date).getTimezoneOffset() + event.endTime))}`}</div>
          <div>{`${sessionsMap.get(event.sessionId)?.name}`}</div>
          <div>{`${therapistsMap.get(event.therapistId || '')}`}</div>
        </div>
      ))
    }
  ]), [therapistsMap, sessionsMap, studentsMap]);


  if (!sessionStatus.success || !therapistStatus.success || !studentsStatus.success || !sessionsMap.size || !therapistsMap.size) {
      return null;
    }

    return (
      <>
        <TimetableEventsList
          tableColumns={tableByDateColumns}
          tableData={tableByDateData as any}
        />
      </>
  );
};

export default TimetableEventsListContainer;


export interface ITimetableEventsListElementData {
  key: React.Key;
  [TableColumns.Name]: string;
  [TableColumns.Timetable]: TimetableEventDto[];
}
