import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Space,
  Typography } from 'antd';
import DistrictList from 'src/features/District/List/containers/DistrictList';
import DistrictListFilters from 'src/features/District/List/containers/DistrictListFilters';
import { DistrictListProvider } from 'src/features/District/List/containers/context';

import urls from 'src/urls';


const DistrictPage = () => {
  const navigate = useNavigate();

  const createButton = <Button onClick={() => navigate(urls.districtCreate())} type="primary">Create</Button>;

  return (
    <Card
      title={<Typography.Title level={2}>Districts</Typography.Title>}
      extra={
        <Space size="middle">
          { createButton }
        </Space>
      }
    >
      <div>
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <h2>List of Districts</h2>

          <DistrictListProvider>
            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
              <DistrictListFilters />

              <DistrictList />
            </Space>
          </DistrictListProvider>
        </Space>
      </div>
    </Card>
  );
};

export default DistrictPage;
