import React, { useEffect } from 'react';
import { List } from 'immutable';
import { useMemo } from 'react';
import {
  useRequestKey,
  useListState,
  useAjaxStatus,
  } from 'src/hooks';

import { availability as AvailabilityService } from 'src/services';

import { parseAvailabilityList } from './utils';
import { AjaxStatus } from 'src/constants';
import { AvailabilityListData } from './types';
import { AvailabilityListElement } from '../../models/AvailabilityListElement';


export const AvailabilityListContext = React.createContext<AvailabilityListData>({
  status: AjaxStatus.default(),
  total: 0,
  collection: List<AvailabilityListElement>(),
});


export const AvailabilityListProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();


  const {
    total,
    collection,
    setCollectionState
  } = useListState<any>();

  const { engage, isCurrentRequest } = useRequestKey();

  useEffect(() => {
    const [ loadList, abort ] = AvailabilityService.getAvailabilityList();
    const key = engage();

    setRequest();

    loadList()
      .then((data) => {

        setCollectionState({
          total: data ? data.length : 0,
          collection: parseAvailabilityList(data!),
        });
        setSuccess();
      })
      .catch(err => {
        isCurrentRequest(key) && setFailure(err);
      });

    return () => abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => ({
    status,
    total,
    collection,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    status,
    total,
    collection,
  ]);

  return (
    <AvailabilityListContext.Provider value={value}>
      { children }
    </AvailabilityListContext.Provider>
  );
};

