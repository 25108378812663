import React, { useCallback, useMemo, useState } from 'react';

import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import type { ColumnsType } from 'antd/es/table';
import { TableColumns } from 'src/features/Student/List/enums';

import { StudentListElement } from 'src/features/Student/List/models/StudentListElement';
import StudentList from 'src/features/Student/List/components/StudentList';
import { IStudentListElementData } from 'src/features/Student/List/containers/StudentList';
import { useStudentListContext } from 'src/features/Student/List/containers/context';

import urls from 'src/urls';
import { student as StudentService } from 'src/services';
import { useAjaxStatus } from 'src/hooks';
import { useSessionListContext } from 'src/features/Session/List/containers/context';
import { SessionListElement } from 'src/features/Session/List/models/SessionListElement';
import { useSchoolListContext } from 'src/features/School/List/containers/context';
import { SchoolListElement } from 'src/features/School/List/models/SchoolListElement';
import { useAvailabilityListContext } from 'src/features/Availability/List/containers/context';
import { AvailabilityListElement } from 'src/features/Availability/List/models/AvailabilityListElement';
import { toUtcTimeString } from 'src/utils/date';
import { addMinutes } from 'date-fns';
import { useSkillListContext } from 'src/features/Skill/List/containers/context';


interface TimetableStudentListContainerProps {
  timetableStudentsIds: string[]
}

const TimetableStudentListContainer = ({ timetableStudentsIds }: TimetableStudentListContainerProps) => {
  const { collection: sessionCollection, status: sessionStatus } = useSessionListContext();
  const sessionsMap = useMemo(() => sessionCollection.reduce((arr, element) => {
    const { id } = element;

    arr.set(id, element);

    return arr;
  }, new Map<string, SessionListElement>()), [
    sessionCollection,
  ]);

  const { collection: studentsCollection, status: studentsStatus } = useStudentListContext();
  const studentsMap = useMemo(() => studentsCollection.reduce((arr, element) => {
    const { id } = element;

    arr.set(id, element);

    return arr;
  }, new Map<string, StudentListElement>()), [
    studentsCollection,
  ]);

  const { collection: schoolCollection, status: schoolStatus } = useSchoolListContext();
  const schoolMap = useMemo(() => schoolCollection.reduce((arr, element) => {
    const { id } = element;

    arr.set(id, element);

    return arr;
  }, new Map<string, SchoolListElement>()), [
    schoolCollection,
  ]);


  const { status: availabilityStatus, collection: availabilityCollection } = useAvailabilityListContext();
  const availabilityMap = useMemo(() => availabilityCollection.reduce((arr, element) => {
    const { subject } = element;

    arr.set(subject, [...arr?.get(`${subject}`) || [], element]);

    return arr;
  }, new Map<string, AvailabilityListElement[]>()), [
    availabilityCollection,
  ]);

  const { collection: skillCollection } = useSkillListContext();
  const skillsMap = useMemo(() => skillCollection.reduce((arr, element) => {
    const { id, name } = element;

    arr.set(Number(id), name);

    return arr;
  }, new Map<number, string>()), [
    skillCollection,
  ]);

  const getSkillByNeed = useCallback((need?: number) => {
    return need ? skillsMap.get(need) : '-';
  }, [ skillsMap ]);

  const [pres, setPres] = useState<Record<string, any>>();

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const getPrescriptionsCharts = useCallback(() => {
    if (!timetableStudentsIds) {
      return [];
    }

    const { loadHandlers, abortHandlers } = timetableStudentsIds.reduce((m, id) => {
      const [ loadPrescription, abort ] = StudentService.getStudentPrescriptions(id);

      m.loadHandlers.push(loadPrescription);
      m.abortHandlers.push(abort);

      return m;
    }, {
      loadHandlers: [] as any,
      abortHandlers: [] as any,
    });

    setRequest();

    return Promise.allSettled(loadHandlers.map((handler: any) => handler()))
      .then((data) => {
        setSuccess();

        const reduced = timetableStudentsIds.reduce((acc, id, currentIndex) => {
          acc[id] = (data[currentIndex] as any)?.value;

          return acc;
        }, {} as Record<string, any>);

        setPres(reduced);
      })
      .catch((err: any) => {
        abortHandlers.forEach((h: any) => h());
        setFailure(err);

        throw err;
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ timetableStudentsIds ]);


  useMemo(() => {
    if (!timetableStudentsIds) {
      return;
    }

    getPrescriptionsCharts();
  }, [timetableStudentsIds, getPrescriptionsCharts]);

  const tableData = useMemo(() => timetableStudentsIds.reduce((arr, studentId) => {
    const { id, name, schoolDistrictName, schoolName } = studentsMap.get(`${studentId}`) || {} as StudentListElement;
    const prescription = pres ? pres[studentId] : [{}];

    arr.push({
      key: id,
      [TableColumns.Name]: name,
      [TableColumns.District]: schoolDistrictName || null,
      [TableColumns.School]: schoolName || null,
      [TableColumns.Needs]: prescription as any,
      [TableColumns.Availability]: availabilityMap?.get(`${id}`),
      [TableColumns.Schedule]: '',
      [TableColumns.Actions]: id,
    });

    return arr;
  }, [] as IStudentListElementData[]), [
    studentsMap, availabilityMap, timetableStudentsIds, pres
  ]);

  const tableColumns: ColumnsType<IStudentListElementData> = useMemo(() => ([
    {
      key: TableColumns.Name,
      title: 'Name',
      dataIndex: TableColumns.Name,
      render: (value: string, record: IStudentListElementData) => <Link to={urls.studentDetails(record.key)}>{ value }</Link>
    },
    {
      key: TableColumns.District,
      title: 'District',
      dataIndex: TableColumns.District,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.School,
      title: 'School',
      dataIndex: TableColumns.School,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.Needs,
      title: 'Prescriptions',
      dataIndex: TableColumns.Needs,
      render: (value: any) => (
        <div style={{ display: 'flex', overflowX: 'auto' }}>
          {value?.map((item: any) => (
            <div style={{ background: '#eee', margin: '2px', padding: '5px', borderRadius: '3px'}}>
              <div>
                <Typography.Text>Days - { item?.days }</Typography.Text>
              </div>
              <div>
                <Typography.Text>
                  {item?.sessions?.map((id: string) => (
                    <div style={{ background: '#eee', margin: '5px 0 10px' }}>
                      <div><b>{sessionsMap.get(`${id}`)?.name}</b></div>
                      <div>{sessionsMap.get(`${id}`)?.duration} minutes</div>
                      <div>Need - {getSkillByNeed(sessionsMap.get(`${id}`)?.need)}</div>
                      <div>{schoolMap.get(`${sessionsMap?.get(`${id}`)?.schoolId}`)?.name}</div>
                    </div>
                    )
                  )}
                </Typography.Text>
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      key: TableColumns.Availability,
      title: 'Availability',
      dataIndex: TableColumns.Availability,
      render: (value: any) => (
        <div style={{ display: 'flex', overflowX: 'auto' }}>
          {value?.map((item: any) => (
            <div style={{ background: '#eee', margin: '2px', padding: '5px', borderRadius: '3px', minWidth: '85px'}}>
              <div>
                <Typography.Text>Date - { item?.date }</Typography.Text>
              </div>
              <div>
                <Typography.Text>
                  {item?.intervals?.map((interval: any) => (
                    <div style={{ margin: '5px 0 10px' }}>
                      <div>
                        {
                          `${toUtcTimeString(addMinutes(new Date(item.date), new Date(item.date).getTimezoneOffset() + interval.start))}
                          -
                          ${toUtcTimeString(addMinutes(new Date(item.date), new Date(item.date).getTimezoneOffset() + interval.end))}`
                        }
                      </div>
                    </div>
                  ))}
                </Typography.Text>
              </div>
            </div>
          ))}
        </div>
      ),
    }
  ].filter(Boolean) as ColumnsType<IStudentListElementData>), [sessionsMap, schoolMap, getSkillByNeed]);


  return (
    <StudentList
      tableColumns={tableColumns}
      tableData={tableData}
      loading={studentsStatus.request}
      pagination={false}
      changePage={() => void(0)}
    />
  );
};

export default TimetableStudentListContainer;
