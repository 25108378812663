import { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { useAjaxStatus } from 'src/hooks';
import { useValidationSchema } from '../hooks';

import { therapist as TherapistService } from 'src/services';

import Form from '../components/Form';
import { prepareCreateFormData } from '../utils';
import { FormFields } from '../enums';
import { TherapistFormValues } from '../types';
import { ApiError } from 'src/modules/ErrorContext/models';
import { useTherapistSkillsOptions } from 'src/common/hooks/useTherapistSkillsOptions';
import { initialAvailability } from 'src/features/Student/Forms/constants';
import { SchoolDetails } from 'src/features/School/models/SchoolDetails';
import { OrganizationType } from 'src/enums';


const TherapistCreateFormContainer = (props: ITherapistCreateFormContainerProps) => {
  const { schoolDetails, onCancel, onCreated } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const initialOrganization = useMemo(() => {
    if (!schoolDetails) {
      return;
    }

    return ({
      id: schoolDetails?.id || '',
      name: schoolDetails?.name || '',
      type: OrganizationType.School,
      parent: schoolDetails?.organization || null,
    });

  }, [ schoolDetails ]);

  const { options: skillsOptions } = useTherapistSkillsOptions();

  const createTherapist = useCallback(async (values: TherapistFormValues) => {
    setRequest();

    try {
      const [ createTherapist ] = TherapistService.createTherapist(prepareCreateFormData(values));

      const id = await createTherapist();

      setSuccess();

      if (typeof onCreated === 'function') {
        onCreated({
          id,
          name: values[FormFields.FirstName],
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onCreated ]);

  const validationSchema = useValidationSchema();
  const editableFields = new Set([
    FormFields.FirstName,
    FormFields.LastName,
    FormFields.Organization,
    FormFields.Skills,
    FormFields.PauseBetweenSessions,
    FormFields.CustomAvailability
  ]);

  return (
    <Formik
      initialValues={{
        [FormFields.Id]: null,
        [FormFields.FirstName]: '',
        [FormFields.LastName]: '',
        [FormFields.PauseBetweenSessions]: null,
        [FormFields.Organization]: [initialOrganization ?? null],
        [FormFields.Skills]: [],
        [FormFields.CustomAvailability]: false,
        [FormFields.Availability]: initialAvailability,
      }}
      validationSchema={validationSchema}
      onSubmit={createTherapist}
    >
      <Form
        status={status}
        editableFields={editableFields}
        onCancel={onCancel}
        validationSchema={validationSchema}
        skillsOptions={skillsOptions}
      />
    </Formik>
  );
};

export default TherapistCreateFormContainer;


/* HELPERS */

interface ITherapistCreateFormContainerProps extends ICommonProps {
  onCancel?(): void;
  onCreated?(schoolEntity: IEntity): void;
  schoolDetails?: Nullable<SchoolDetails>;
}
