import { useCallback, useState } from 'react';


export const usePagination = (page = 1, pageSize = 0) => {
  if (page < 1) {
    throw new Error('Page value can\'t be less than 1');
  }

  const [ pagination, setPagination ] = useState<IPagination>({ page, pageSize });

  const changePage = useCallback((page: number, pageSize?: number) => {
    if (page < 1) {
      throw new Error('Page value can\'t be less than 1');
    }

    if (pageSize != null && pageSize < 0) {
      throw new Error('Page size value can\'t be negative');
    }

    setPagination(pagination => ({
      page,
      pageSize: pageSize ?? pagination.pageSize,
    }));
  }, []);

  return {
    pagination,
    changePage,
  };
};