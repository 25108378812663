import React from 'react';


const Toggle = (props: IToggleProps) => {
  // toggle component props
  const { children, tag: Tag = React.Fragment, className, style, show = true, hide } = props;
  const definedProps = Tag === React.Fragment
    ? {}
    : { className, style };

  if (!show || hide) {
    return null;
  }

  return (
    <Tag {...definedProps}>
      { children }
    </Tag>
  );
};

export default Toggle;

/* HELPERS */

interface IToggleProps {
  show?: boolean;
  hide?: boolean;
  style?: Record<string, string>;
  tag?: string | React.ElementType;
  children?: React.ReactNode
  className?: string
}
