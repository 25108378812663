import { useCallback }                   from 'react';
import { useAjaxStatus }                 from 'src/hooks';

import { Formik }                        from 'formik';
import Form                              from '../components/UploadForm';

import { timetable as TimetableService } from 'src/services';

import { prepareUploadFormData }         from '../utils';
import { UploadFormFields }              from '../enums';
import { UploadTimetableFormValues }     from '../types';
import { ApiError }                      from 'src/modules/ErrorContext/models';
import { useUploadValidationSchema }     from 'src/features/Timetable/Forms/hooks';


const UploadTimetableDataFormContainer = (props: IUploadTimetableDataFormContainerProps) => {
  const { setActiveTab, onCreated, onCancel } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const editableFields = new Set([
    UploadFormFields.Name,
    UploadFormFields.File,
  ]);

  const validationSchema = useUploadValidationSchema();

  const uploadTimetable = useCallback(async (values: UploadTimetableFormValues) => {
    setRequest();

    try {
      const [ uploadTimetable ] = TimetableService.uploadTimetable(prepareUploadFormData(values));

      const id = await uploadTimetable();

      setSuccess();

      if (typeof onCreated === 'function') {
        onCreated({
          id,
          name: values[UploadFormFields.Name],
        });
      }
    } catch (err) {
      setFailure(err as ApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onCreated ]);

  return (
    <Formik
      initialValues={{
        [UploadFormFields.Name]: '',
        [UploadFormFields.File]: null,
      }}
      validationSchema={validationSchema}
      onSubmit={uploadTimetable}
    >
      <Form
        status={status}
        editableFields={editableFields}
        setActiveTab={setActiveTab}
        onCancel={onCancel}
      />
    </Formik>
  );
};

export default UploadTimetableDataFormContainer;


/* HELPERS */

interface IUploadTimetableDataFormContainerProps {
  onCancel?(): void;
  onCreated?(entity: any): void;
  setActiveTab?(key: string): void;
}
