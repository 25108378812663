import React from 'react';
import { useMemo } from 'react';
import { useStudentListContext } from './context';
import { useSkillListContext } from 'src/features/Skill/List/containers/context';
import { StudentNeed } from 'src/features/Student/List/models/StudentListElement';
import { AvailabilityListElement } from 'src/features/Availability/List/models/AvailabilityListElement';

import {
  Typography }               from 'antd';
import { Link }              from 'react-router-dom';

import StudentList           from '../components/StudentList';

import type { ColumnsType }  from 'antd/es/table';
import { TableColumns }      from '../enums';

import urls from 'src/urls';


const StudentListContainer = () => {
  const {
    status,
    collection,
    total,
    pagination: { page, pageSize },
    changePage,
  } = useStudentListContext();

  const { collection: skillCollection } = useSkillListContext();
  const skillsMap = useMemo(() => skillCollection.reduce((arr, element) => {
    const { id, name } = element;

    arr.set(Number(id), name);

    return arr;
  }, new Map<number, string>()), [
    skillCollection,
  ]);

  const tableData = useMemo(() => collection.reduce((arr, element) => {
    const { id, name, schoolName, schoolDistrictName, needs, timetableId } = element;

    arr.push({
      key: id,
      [TableColumns.Name]: name,
      [TableColumns.District]: schoolDistrictName ?? null,
      [TableColumns.School]: schoolName ?? null,
      [TableColumns.Needs]: needs ?? [],
      [TableColumns.Schedule]: timetableId,
      [TableColumns.Actions]: id,
    });

    return arr;
  }, [] as IStudentListElementData[]), [
    collection,
  ]);

  const tableColumns: ColumnsType<IStudentListElementData> = useMemo(() => ([
    {
      key: TableColumns.Name,
      title: 'Student Name',
      dataIndex: TableColumns.Name,
      render: (value: string, record: IStudentListElementData) => <Link to={urls.studentDetails(record.key)}>{ value }</Link>
    },
    {
      key: TableColumns.District,
      title: 'District Name',
      dataIndex: TableColumns.District,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.School,
      title: 'School Name',
      dataIndex: TableColumns.School,
      render: (value: Nullable<string>) => <Typography.Text>{ value ?? <>&mdash;</> }</Typography.Text>,
    },
    {
      key: TableColumns.Needs,
      title: 'Needs',
      dataIndex: TableColumns.Needs,
      render: (value: number[]) => {
        const needs = value?.map(id => skillsMap?.get(id) || '');

        return needs.map((name: string) => (
          <div><Typography.Text>{name ?? <>&mdash;</>}</Typography.Text></div>
        ));
      }
    },
    // {
    //   key: TableColumns.Schedule,
    //   title: 'Latest Generated Schedule',
    //   dataIndex: TableColumns.Schedule,
    //   render: (value: string, record: IStudentListElementData) => value ? <Link to={urls.scheduleCalendar(value)} title={value}>View</Link> : <>&mdash;</>
    // },
    {
      key: TableColumns.Actions,
      title: 'Actions',
      dataIndex: TableColumns.Actions,
      render: (value: string, record: IStudentListElementData) => value ? <div>
        <Link to={urls.studentDetails(value)} style={{ marginRight: '14px' }}>View</Link>
        {' '}
        <Link to={urls.studentEdit(value)} title={value}>Edit</Link>
      </div> : <>&mdash;</>
    },
  ].filter(Boolean) as ColumnsType<IStudentListElementData>), [ skillsMap ]);

  const pagination = useMemo(() => ({
    current: page,
    total,
    pageSize,
  }), [ page, total, pageSize ]);

  return (
    <StudentList
      tableColumns={tableColumns}
      tableData={tableData}
      pagination={pagination}
      changePage={changePage}
      loading={status.request}
    />
  );
};

export default StudentListContainer;


export interface IStudentListElementData {
  key: React.Key;
  [TableColumns.Name]: string;
  [TableColumns.District]: Nullable<string>;
  [TableColumns.School]: Nullable<string>;
  [TableColumns.Needs]: StudentNeed[];
  [TableColumns.Schedule]: string;
  [TableColumns.Actions]: string;
  [TableColumns.Availability]?: AvailabilityListElement[];
}
