import { OrganizationType } from '../enums';


export class Organization implements IEntity {
  private constructor(
    readonly id: string,
    readonly name: string,
    readonly type: OrganizationType
  ) {}

  static fromJS(data: { id: string; name: string; type: OrganizationType; }) {
    return new Organization(data.id, data.name, data.type);
  }
}
