import { useState, useRef } from 'react';
import { RecordOf } from 'immutable';


export function useFilters<T extends Record<string, unknown>>(initialState: RecordOf<T>) {
  const { current: initialFilters } = useRef(initialState);
  const [filters, setFilters] = useState<RecordOf<T>>(initialState);

  const { current: changeFilterValue } = useRef((name: keyof T, value: T[keyof T]) => setFilters(filters => filters.set(name, value)));
  const { current: changeFilterValues } = useRef((values: Partial<T>) => setFilters(filters => Object.entries(values).reduce((f, [name, value]) => f.set(name, value), filters)));
  const { current: resetFilters } = useRef(() => setFilters(initialFilters));

  return {
    filters,
    resetFilters,
    changeFilterValue,
    changeFilterValues,
  };
}
