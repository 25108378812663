import React, { useEffect } from 'react';
import { List, RecordOf } from 'immutable';
import { useMemo } from 'react';
import {
  useRequestKey,
  useListState,
  useAjaxStatus,
  useFilters,
  usePagination,
  useDebouncedValue,
  } from 'src/hooks';

import { timetable as TimetableService } from 'src/services';

import { createCriteria, parseTimetableList } from './utils';
import { AjaxStatus } from 'src/constants';
import { TimetableListData } from './types';
import { TimetableListElement } from '../../models/TimetableListElement';
import { TimetableFilters } from '../../constants';
import { ChildrenFunc } from 'src/utils'
import { TimetableListFiltersValues } from 'src/features/Timetable/List/types';


export const TimetableListContext = React.createContext<TimetableListData>({
  status: AjaxStatus.default(),
  filters: new TimetableFilters(),
  pagination: {
    page: 0,
    pageSize: 0,
  },
  total: 0,
  collection: List<TimetableListElement>(),
  changePage: () => void(0),
  resetFilters: () => void(0),
  changeFilterValues: () => void(0),
});


export const TimetableListProvider = (props: ITimetableProviderProps) => {
  const { children, initialFilters = new TimetableFilters() } = props;

  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();

  const {
    filters,
    resetFilters,
    changeFilterValues
  } = useFilters<TimetableListFiltersValues>(initialFilters);

  const { pagination, changePage } = usePagination(1, 10);

  const {
    total,
    collection,
    setCollectionState
  } = useListState<TimetableListElement>();

  const { engage, isCurrentRequest } = useRequestKey();

  const filtersValue = useDebouncedValue(filters, 300);

  useEffect(() => {
    const [ loadList, abort ] = TimetableService.getTimetableList(createCriteria(filtersValue, pagination));
    const key = engage();

    setRequest();

    loadList()
      .then(({ count, items }) => {

        setCollectionState({
          total: count!,
          collection: parseTimetableList(items!),
        });
        setSuccess();
      })
      .catch(err => {
        isCurrentRequest(key) && setFailure(err);
      });

    return () => abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ filtersValue, pagination ]);

  const value = useMemo(() => ({
    status,
    total,
    filters,
    pagination,
    collection,
    changePage,
    resetFilters,
    changeFilterValues,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    status,
    total,
    filters,
    pagination,
    collection,
    changePage,
    resetFilters,
    changeFilterValues,
  ]);

  return (
    <TimetableListContext.Provider value={value}>
      { typeof children === 'function' ? children(value) : children }
    </TimetableListContext.Provider>
  );
};

interface ITimetableProviderProps {
  initialFilters?: RecordOf<TimetableListFiltersValues>;
  children?: React.ReactNode | ChildrenFunc<TimetableListData>;
}
