import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Space,
  Typography } from 'antd';
import urls from 'src/urls';
import { SessionListProvider } from 'src/features/Session/List/containers/context';
import SessionList from 'src/features/Session/List/containers/SessionList';
import { SchoolListProvider } from 'src/features/School/List/containers/context';


const SessionPage = () => {
  const navigate = useNavigate();

  const createButton = <Button onClick={() => navigate(urls.sessionCreate())} type="primary">Create</Button>;

  return (
    <Card
      title={<Typography.Title level={2}>Sessions</Typography.Title>}
      extra={
        <Space size="middle">
          { createButton }
        </Space>
      }
    >
      <div>
        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
          <h2>List of Sessions</h2>

          <SessionListProvider>
          <SchoolListProvider>
            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
              <SessionList />
            </Space>
          </SchoolListProvider>
          </SessionListProvider>
        </Space>
      </div>
    </Card>
  );
};

export default SessionPage;
