import {
  Navigate,
  useParams,
  useNavigate } from 'react-router-dom';
import {
  Card,
  Space,
  Spin,
  Result,
  Typography
} from 'antd';
import { PrescriptionsProvider } from 'src/features/Prescription/containers/DetailsProvider';
import PrescriptionInfo from 'src/features/Prescription/Details/components/PrescriptionInfo';
import Toggle from 'src/components/Toggle';


import urls from 'src/urls';


const PrescriptionDetailsPage = () => {
  const { prescription_id: prescriptionId } = useParams();
  const navigate = useNavigate();

  if (!prescriptionId) {
    return <Navigate to={urls.index()} />;
  }

  return (
    <PrescriptionsProvider id={prescriptionId}>
      {
        ({ status, prescriptions }) => {
          return (
            <Card
              title={<Typography.Title level={2}>Prescription Details</Typography.Title>}
            >
              <Toggle show={status.request} tag="div" style={{ textAlign: 'center' }}>
                <Spin tip="Loading Prescription data..." />
              </Toggle>

              <Toggle show={!!status.failure}>
                <Result
                  status="error"
                  title={(status as AjaxFailureStatus).failure.message}
                />
              </Toggle>

              <Toggle show={status.success}>
                <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                  <PrescriptionInfo
                    details={prescriptions!}
                  />
                </Space>
              </Toggle>
            </Card>
          );
        }
      }
    </PrescriptionsProvider>
  );
};

export default PrescriptionDetailsPage;
