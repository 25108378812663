import { Map }               from 'immutable';
import { AjaxStatusFactory } from 'src/utils/ajax';
import { AvailabilityWeekDay, OrganizationType } from './enums';


export const AjaxStatus = new AjaxStatusFactory();

export const ACTION_CANCELED_STATUS = -999;
export const MANUAL_ERROR_STATUS = 0;

export const OrganizationTypeNames = {
  [OrganizationType.District]: 'District',
  [OrganizationType.School]: 'School',
};

export interface IImmutableMap<T, K, V> extends Map<K, V> {
  get<I extends keyof T>(key: I & K): T[I] & V;
  set<S extends keyof T>(key: S & K, value: T[S] & V): this;
}

export const KB = 1024;
export const MB = 1024 * KB;
export const GB = 1024 * MB;


export const DistrictsList = {
  '341d7d73-6068-4eb1-af4f-b259365cb593': 'District 1',
  '99f3dacf-fde8-4888-aa46-6787ecdb289f': 'Test District 2'
};

export const AvailabilityDays = [
  AvailabilityWeekDay.Monday,
  AvailabilityWeekDay.Tuesday,
  AvailabilityWeekDay.Wednesday,
  AvailabilityWeekDay.Thursday,
  AvailabilityWeekDay.Friday,
  AvailabilityWeekDay.Saturday,
  AvailabilityWeekDay.Sunday,
];
