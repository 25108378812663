import React, { useMemo } from 'react';
import {
  Space,
  Descriptions } from 'antd';
import { Link } from 'react-router-dom';

import { TimetableDetails } from 'src/features/Timetable/models/TimetableDetails';
import TimetableEventsList from 'src/features/Timetable/Details/containers/TimetableEventsList';
import TimetableSchools from 'src/features/Timetable/Details/containers/TimetableSchools';
import TimetableStudents from 'src/features/Timetable/Details/containers/TimetableStudents';
import TimetableTherapists from 'src/features/Timetable/Details/containers/TimetableTherapists';
import urls from 'src/urls';

import './TimetableInfo.scss';


const TimetableInfo = (props: ITimetableInfoProps) => {
  const { details } = props;

  const studentsIds = useMemo(() => {
    if (!details?.students) {
      return [];
    }

    return details.students.map((student) => student.id);
  }, [details]);

  const therapistsIds = useMemo(() => {
    if (!details?.therapists) {
      return [];
    }

    return details.therapists.map((therapist) => therapist.id);
  }, [details]);

  return (
    <Space direction="vertical" size="large" className={'timetableInfo'}>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Name">{details?.name}</Descriptions.Item>
        <Descriptions.Item label="Status">{details?.status}</Descriptions.Item>
        <Descriptions.Item label="Start">{details?.startDate}</Descriptions.Item>
        <Descriptions.Item label="End">{details?.endDate}</Descriptions.Item>
        <Descriptions.Item label="Timetable"><Link to={urls.timetableCalendar(details?.id)}>View</Link></Descriptions.Item>
      </Descriptions>

      <div>
        <h3>Schools</h3>
        <TimetableSchools timetableSchools={details.schools} />
      </div>

      <div>
        <h3>Students</h3>
        <TimetableStudents timetableStudentsIds={studentsIds} />
      </div>

      <div>
        <h3>Therapists</h3>
        <TimetableTherapists timetableTherapistsIds={therapistsIds} />
      </div>

      <div>
        <h3>Timetable</h3>
        <TimetableEventsList details={details} />
      </div>
    </Space>
  );
};

export default TimetableInfo;


/* HELPERS */

interface ITimetableInfoProps extends ICommonProps {
  details: TimetableDetails;
}
