import { List } from 'immutable';

import { AvailabilityListElementDto } from 'src/services/api/availability/availability.dto';
import { AvailabilityListElement } from '../../models/AvailabilityListElement';
import { Interval } from 'src/models/Interval';


export function parseAvailabilityList(items: AvailabilityListElementDto[]): List<AvailabilityListElement> {
  return List(items.map(item => {
    const {
      date,
      subject,
      subjectId,
      intervals,
    } = item;

    const availabilityIntervals = intervals.map(interval => Interval.fromJS(interval));

    return new AvailabilityListElement(
      date,
      subject,
      subjectId,
      availabilityIntervals,
    );
  }));
}
