import { useEffect, useState } from 'react';
import {
  useAjaxStatus,
  useRequestKey,
} from 'src/hooks';

import { student as StudentService} from 'src/services';
import { PrescriptionDetails } from '../models/PrescriptionDetails';
import { ApiError } from 'src/modules/ErrorContext/models';


export const usePrescriptionDetails = (id: string) => {
  const {
    status,
    setRequest,
    setSuccess,
    setFailure
  } = useAjaxStatus();
  const { engage, isCurrentRequest } = useRequestKey();

  const [ prescriptions, setPrescriptions ] = useState<Nullable<PrescriptionDetails[]>>(null);

  useEffect(() => {
    const [ loadPrescriptions, abort ] = StudentService.getStudentPrescriptions(id);
    const key = engage();

    setRequest();

    loadPrescriptions()
      .then(data => {
        if (isCurrentRequest(key)) {
          setPrescriptions(data.map((prescription: any) => PrescriptionDetails.fromJS(prescription)));
          setSuccess();
        }
      })
      .catch((err: ApiError) => {
        if (isCurrentRequest(key)) {
          setFailure(err);
        }
      });


    return () => abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id ]);

  return {
    prescriptions,
    status
  };
};
