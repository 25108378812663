export function intersect(a: string[], b: string[]) {
  const setA = new Set(a);
  const setB = new Set(b);
  const intersection = new Set([...setA].filter(x => setB.has(x)));
  return Array.from(intersection);
}

export function getRandomColor(id: string) {
  const hexLetters = '0123456789abcdef'.split('');

  const hash = id.split('').filter(letter => hexLetters.includes(letter)).join('').slice(0, 6);

  const rgb = hexToRgb(hash);
  const hsl = rgbToHsl(Number(rgb[0]), Number(rgb[1]), Number(rgb[2]));

  const hue = 190 + (60 * (hsl[0] < 1 ? hsl[0] : hsl[0] / 360));
  const saturation = 25 + 70 * (hsl[1] / 100);
  const lightness = 65 + 10 * (hsl[2] / 100);

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}


const rgbToHsl = (r: number, g: number, b: number) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s
    ? l === r
      ? (g - b) / s
      : l === g
        ? 2 + (b - r) / s
        : 4 + (r - g) / s
    : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
};


const hexToRgb = (hex: any) => {
  let alpha = false,
    h = hex.slice(hex.startsWith('#') ? 1 : 0);
  if (h.length === 3) h = [...h].map(x => x + x).join('');
  else if (h.length === 8) alpha = true;
  h = parseInt(h, 16);
  return (
    // 'rgb' +

    // (alpha ? 'a' : '') +
    // ',' +
    (h >>> (alpha ? 24 : 16)) +
    ', ' +
    ((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
    ', ' +
    ((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
    (alpha ? `, ${h & 0x000000ff}` : '') +
    ''
  ).split(',');
};
