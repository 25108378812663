export const enum FormFields {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  Duration = 'duration',
  PreparingTime = 'preparingTime',
  FinishingTime = 'finishingTime',
  Need = 'need',
  Group = 'group',
  Online = 'online',
  SchoolId = 'schoolId',
}
