import { ApiIntent, ApiService } from '../types';
import {
  TimetableDetailsDto,
  TimetableListDto,
  TimetableListElementDto,
  TimetableUpdateDto,
} from './timetable.dto';
import { ITimetableListCriteria } from './types';


export class TimetableApiService extends ApiService {
  getTimetableDetails(id: string): ApiIntent<any> {
    const [ getTimetable, abort ] = this.createGetIntent<TimetableDetailsDto>(`timetables/${id}`);

    return [() => getTimetable().then(({ data: timetableInfo }) => timetableInfo), abort];
  }

  getTimetableStatus(id: string): ApiIntent<any> {
    const [ getTimetable, abort ] = this.createGetIntent<TimetableDetailsDto>(`timetables/${id}/status`);

    return [() => getTimetable().then(({ data: timetableStatusInfo }) => timetableStatusInfo), abort];
  }

  getTimetableList(criteria?: ITimetableListCriteria): ApiIntent<{ count?: number; items?: TimetableListElementDto[]; }> {
    const [ getTimetableList, abort ] = this.createGetIntent<TimetableListDto>('timetables', criteria);

    return [
      () => getTimetableList().then(({ data: { count, items } }) => ({ count, items })),
      abort,
    ];
  }

  createTimetable(payload: any): ApiIntent<string> {
    const [ createTimetable, abort ] = this.createPostIntent<{ id: string; }>('timetables', payload);

    return [
      () => createTimetable().then(({ data: { id } }) => id),
      abort,
    ];
  }

  updateTimetable(id: string, payload: TimetableUpdateDto):ApiIntent<boolean> {
    const [ updateTimetable, abort ] = this.createPutIntent<{}>(`schools/${id}`, payload);

    return [
      () => updateTimetable().then(() => true),
      abort,
    ];
  }

  uploadTimetable(payload: any): ApiIntent<string> {
    const [ uploadTimetable, abort ] = this.createPostIntent<{ id: string; }>('import', payload);

    return [
      () => uploadTimetable().then(({ data: { id } }) => id),
      abort,
    ];
  }
}
