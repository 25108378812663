export class Role {
    constructor(id, name, type) {
        this.id = id;
        this.name = name;
        this.type = type;
    }
    static fromJS(data) {
        return new Role(data.id, data.name, data.type);
    }
}
Role.SuperAdmin = process.env.REACT_APP_ROLE_SUPER_ADMIN;
Role.DistrictAdmin = process.env.REACT_APP_ROLE_DISTRICT_ADMIN;
Role.FacilityAdmin = process.env.REACT_APP_ROLE_FACILITY_ADMIN;
Role.SspAdmin = process.env.REACT_APP_ROLE_SSP_ADMIN;
Role.EmergencyServices = process.env.REACT_APP_ROLE_EMERGENCY_SERVICES;
Role.Member = process.env.REACT_APP_ROLE_MEMBER;
Role.Guardian = process.env.REACT_APP_ROLE_GUARDIAN;
