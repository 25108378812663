import { useMemo } from 'react';
import { format } from 'date-fns';
import {
  Space,
  Descriptions } from 'antd';
import { TherapistDetails } from 'src/features/Therapist/models/TherapistDetails';
import { useSkillListContext } from 'src/features/Skill/List/containers/context';
import Toggle from 'src/components/Toggle';


const TherapistInfo = (props: ITherapistInfoProps) => {
  const { details } = props;

  const { collection: skillCollection } = useSkillListContext();
  const skillsMap = useMemo(() => skillCollection.reduce((arr, element) => {
    const { id, name } = element;

    arr.set(Number(id), name);

    return arr;
  }, new Map<number, string>()), [
    skillCollection,
  ]);

  return (
    <Space direction="vertical" size="large">
      <Descriptions column={1} bordered>
        <Descriptions.Item label="First Name">{details?.firstName}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{details?.lastName}</Descriptions.Item>
        <Descriptions.Item label="Schools">{details?.schools.map(school => school?.name).join(', ')}</Descriptions.Item>
        <Descriptions.Item label="Skills">{details?.skills.map(skillId => skillsMap.get(skillId)).join(', ')}</Descriptions.Item>
        <Descriptions.Item label="Pause Between Sessions">{details?.pauseBetweenSessions}</Descriptions.Item>
      </Descriptions>

      <Toggle show={Boolean(details.availability?.length)}>
        <h2>Availability</h2>
        <Descriptions column={1} bordered>
          {details.availability.map((item) => (
            <Descriptions.Item label={item.day}>{`${format(new Date(item.intervals.start ?? ''), 'h:mm a')} - ${format(new Date(item.intervals.end ?? ''), 'h:mm a')}` }</Descriptions.Item>
          ))}
        </Descriptions>
      </Toggle>
    </Space>
  );
};

export default TherapistInfo;


/* HELPERS */

interface ITherapistInfoProps extends ICommonProps {
  details: TherapistDetails;
}
