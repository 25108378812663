interface PrescriptionDetailsDto {
   days: number,
   sessions: string[],
}

export class PrescriptionDetails {
  private constructor(
    readonly days: number,
    readonly sessions: string[],
  ) {}

  static fromJS(data: PrescriptionDetailsDto) {

    return new PrescriptionDetails(
      data.days,
      data.sessions,
    );
  }
}



