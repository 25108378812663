export const enum FilterFields {
  Name = 'name',
  Organization = 'organization',
  Signed = 'signed',
}

export const enum SortFields {
  Name = 'name',
  ModificationDate = 'modifiedAt',
}

export const enum TableColumns {
  Name = 'name',
  Skills = 'skills',
  Districts = 'districts',
  Schools = 'schools',
  Utilization = 'utilization',
  Schedule = 'schedule',
  Actions = 'actions',
}
