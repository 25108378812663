import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  Typography,
  notification }                  from 'antd';
import CreateForm                 from 'src/features/School/Forms/containers/CreateForm';
import { DistrictListProvider }   from 'src/features/District/List/containers/context';

import urls                       from 'src/urls';



const SchoolCreatePage = () => {
  const navigate = useNavigate();

  const redirectToSchoolList = useCallback((schoolEntity: IEntity) => {
    notification.success({
      message: <Typography.Text strong>School has been created!</Typography.Text>,
      description: (
        <Typography.Text>
          School <Typography.Text strong>{ schoolEntity.name }</Typography.Text> was created
        </Typography.Text>
      ),
      duration: null,
    });

    navigate(urls.school());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card title={<Typography.Title level={2}>Create School</Typography.Title>}>
      <Row>
        <Col xl={12} lg={16} md={24}>
          <DistrictListProvider>
            <CreateForm onCreated={redirectToSchoolList} onCancel={() => navigate(urls.school())} />
          </DistrictListProvider>
        </Col>
      </Row>
    </Card>
  );
};

export default SchoolCreatePage;
