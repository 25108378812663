import { List, OrderedMap }      from 'immutable';
import { useState, useCallback } from 'react';


export function useOrderedMapState<T extends IEntity>(
  initialCollection = OrderedMap<IEntity['id'], T>(),
  initialTotal = 0,
): ICollectionState<OrderedMapCollection<T>> {
  const [state, setState] = useState({
    collection: initialCollection,
    total: initialTotal,
  });

  const addItem = useCallback(
    (id: IEntity['id'], item: T, changeTotal = true) =>
      setState(({ collection, total }) => ({
        collection: collection.set(id, item),
        total: changeTotal ? total + 1 : total,
      })),
    [],
  );

  const removeItem = useCallback(
    (id: IEntity['id'], changeTotal = true) =>
      setState(({ collection, total }) => ({
        collection: collection.delete(id),
        total: changeTotal ? total - 1 : total,
      })),
    [],
  );

  const clear = useCallback(
    () =>
      setState({
        collection: OrderedMap(),
        total: 0,
      }),
    [],
  );

  return {
    collection: state.collection,
    total: state.total,
    addItem,
    removeItem,
    clear,
    setCollectionState: setState,
  };
}

export function useListState<T extends IEntity>(
  initialCollection = List<T>(),
  initialTotal = 0,
): ICollectionState<ListCollection<T>> {
  const [state, setState] = useState({
    collection: initialCollection,
    total: initialTotal,
  });

  const addItem = useCallback(
    (item: T, changeTotal = true) =>
      setState(({ collection, total }) => ({
        collection: collection.push(item),
        total: changeTotal ? total + 1 : total,
      })),
    [],
  );

  const removeItem = useCallback(
    (id: IEntity['id'], changeTotal = true) =>
      setState(({ collection, total }) => ({
        collection: collection.filter(item => item.id !== id),
        total: changeTotal ? total - 1 : total,
      })),
    [],
  );

  const clear = useCallback(
    () =>
      setState({
        collection: List(),
        total: 0,
      }),
    [],
  );

  return {
    collection: state.collection,
    total: state.total,
    addItem,
    removeItem,
    clear,
    setCollectionState: setState,
  };
}

/* HELPERS */

export type ListCollection<T> = List<T>
export type OrderedMapCollection<T> = OrderedMap<IEntity['id'], T>

type CustomCollection<T> = ListCollection<T> | OrderedMapCollection<T>

type extractCollectionType<Type> = Type extends CustomCollection<infer X> ? X : never

interface ICollectionState<CollectionType> {
  collection: CollectionType
  total: number
  addItem:
    | ((
        id: IEntity['id'],
        value: extractCollectionType<CollectionType>,
        changeTotal: boolean,
      ) => void)
    | ((value: extractCollectionType<CollectionType>, changeTotal: boolean) => void)
  removeItem(id: IEntity['id'], changeTotal: boolean): void
  clear(): void
  setCollectionState: React.Dispatch<
    React.SetStateAction<{
      collection: CollectionType
      total: number
    }>
  >
}
